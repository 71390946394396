.inputGroup {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    min-width: 5rem;

    & > .no-wrap {
        position: relative;
        width: calc(100% - #{$grid-gutter * 2.5});
    }

    .form-control {
        border-radius: $border-radius 0 0 $border-radius;
        border-right: 0;
    }

    &__btn {
        margin: 0;
        border-radius: 0 $border-radius $border-radius 0;
        padding: 0;
        width: $grid-gutter * 2.5;
        height: $grid-gutter * 2.5;
        flex-shrink: 0;

        &:hover {
            box-shadow: none;
        }

        & > .icon {
            margin: 0 0.25rem 0 0.25rem;
        }

        &--left {
            border-radius: $border-radius 0 0 $border-radius;
        }
    }

    &--btnLeft {
        flex-direction: row-reverse;

        & > input.form-control {
            border-radius: 0 $border-radius $border-radius 0;
            border-left: 0;
        }
    }
}

