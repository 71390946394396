

//@autor - 0b10011
// https://stackoverflow.com/a/11471224
input[type=color] {
    border: none;
    width: 32px;
    height: 32px;
}

input[type=color]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type=color]::-webkit-color-swatch {
    border: none;
}

.rotate-in-center {
    animation: rotate-in-center 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
//--------------------------------------------

.packetFilter {

    &__nameInput.packetFilter__nameInput {
        min-width: 41ch; //max name lenght is 32chars + paddings i guess
    }

    &--scene {
        flex: 1;
        flex-wrap: nowrap;
        margin-top: -0.5 * $grid-gutter;

    }

    &__card {
        height: calc(100vh - 10 * $grid-gutter);
    }

    //THIS IS VERY IMPORTANT , MANY WOWS
    &Select {
        &__multi-value {
            background-color: $primary-color-30 !important;  //stylelint-disable-line

            &__label {
                font-weight: 600 !important;  //stylelint-disable-line
            }
        }

        &__control {
            border: 2px solid $input-border-color !important;  //stylelint-disable-line
            border-radius: 0.25* $grid-gutter!important;  //stylelint-disable-line
            box-shadow: 0 !important;  //stylelint-disable-line

            &--is-focused {
                border-color: $primary !important;  //stylelint-disable-line
                box-shadow: 0 0 0 0 !important;  //stylelint-disable-line
            }
        }

        &__option {
            height: 50% !important;  //stylelint-disable-line

            &--is-focused {
                background-color: $primary-color-30 !important;  //stylelint-disable-line
            }
        }

        &__menu-list {
            max-height: 11.25 * $grid-gutter !important;  //stylelint-disable-line
        }
    }

    &__serviceInput {
        position: absolute;
        top: 0;
        left: $grid-gutter * 0.5625;
        transition: transform 0.2s ease-out, color 0.2s ease-out;
        cursor: text;
        padding-left: 0.3125 * $grid-gutter;
        padding-right: 0.3125 * $grid-gutter;
        font-size: $input-label-font-size; // =14px (must be bigger for smooth upscaling)
        font-weight: 600;
        transform: translateY(-8px) scale(0.857); // =12px
        background-color: $white;
        color: $input-label-color;
        margin-bottom: 0;
        transform-origin: 0 0;
        line-height: 1;
        letter-spacing: 0.1px;
    }

    &__actionText {
        color: $input-label-color;
    }

    &__switch {
        min-width: unset;
    }

    &__modal {
        margin-top: 5 * $grid-gutter;

        &Button {
            width: 33.3%;
        }

        &Body {
            display: flex;
            justify-content: space-between;

            &Text {
                padding: 0 $grid-gutter;
            }
        }
    }

    &__row {
        cursor: pointer;

        &--color {
            color: $placeholder-color;
            font-weight: 400;
        }
    }

    &__defaultCursor {
        cursor: default;
    }

    &__add {
        &Buttons {
            display: none;
            position: absolute;
            width: 100%;
            margin-top: -10px;
            margin-left: -1px;

            &--button {
                height: 1.5 * $grid-gutter;
                display: inline-block;
                font-size: 12px;
                background-color: $primary-green;
                border-radius: 1.875 * $grid-gutter;
                color: $white;
                text-decoration: none;
                padding: 2px 6px 2px 6px;
                margin-right: 2px;
                margin-top: -10px;
                position: relative;
                z-index: $z-index-5;
                box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);

                &:last-of-type {
                    background-color: $secondary-brown;
                }

                .icon {
                    margin: -0.125 * $grid-gutter 0 0 0;
                }
            }
        }

        &Rule {
            background-color: inherit;
            height: 6px;
            position: relative;
            text-align: center;

            &:hover {
                background-color: $grey-light;

                .packetFilter__addButtons {
                    display: block;
                }
            }
        }

        &:first-of-type {
            background-color: $white;
        }
    }

    &__firstRule {

        &Row {
            height: 15 * $grid-gutter;
        }

        height: 15 * $grid-gutter;
        background-color: $secondary-green-white;
    }


    &__colorInput {
        margin-left: 40%;
    }

    &__time {
        &Text {
            font-size: 0.875 * $grid-gutter;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: 0.28px;
        }
    }

    &__Row {

        &--small {
            width: 5%;
            min-width: 4 * $grid-gutter;
        }

        &--clicked {
            position: relative;

            &::before {
                transform: translate(0, -50%);
                top: 50%;
                left: 50%;
                content: url("data:image/svg+xml; utf-8, <svg fill='rgb(238, 113, 1)' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 56'><path d='M25.6,17.6C20.4,12.3,15.1,7,9.9,1.8c-2.3-2.3-6-2.3-8.3-0.1C-0.6,4-0.6,7.7,1.7,10c4.4,4.4,8.8,8.9,13.3,13.3  c1.4,1.4,1.4,4,0,5.4C10.6,33.1,6.2,37.6,1.8,42c-2.3,2.3-2.3,6-0.1,8.3s6,2.2,8.3-0.1c5.2-5.3,10.5-10.6,15.7-15.8  C31.4,28.6,31.5,23.5,25.6,17.6z' /></svg>");
                width: $grid-gutter;
                height: $grid-gutter * 1.75;
                position: absolute;
            }
        }
    }

    &__divider {
        margin-bottom: 3 * $grid-gutter;
        height: 0.125 * $grid-gutter;
        border-bottom: 2px solid $grey-light;
        text-align: center;
        padding-bottom: 0.7 * $grid-gutter;

        &Text {
            text-align: center;
            background-color: $white-base;
            padding-bottom: 0.7rem;
            color: rgba(88, 74, 60, 0.4);
            font-weight: 600;
        }
    }

    &__newRule {
        padding: $grid-gutter;
        display: flex;
        border: 1px solid $input-border-color;
        border-radius: 0.5 * $grid-gutter;
        font-size: $font-size-base;
        margin-bottom: 1.5 * $grid-gutter;
        cursor: pointer;
        strong {
            display: block;
            color: $primary;
        }

        &--orange {
            color: $primary;
        }

        &.active,&:hover {
            border: 1px solid $primary;
        }
    }

    &__ruleIndex {
        min-width: 1 * $grid-gutter;
        min-height: 1 * $grid-gutter;
        padding: 0.25 * $grid-gutter;
    }

    &__edit {

        &Icon {
            visibility: hidden;
            margin-bottom: 0.2 * $grid-gutter;
        }

        &:hover {
            .packetFilter__editIcon {
                visibility: visible;
            }
        }
    }
    &__log {
        color: $primary-green;
    }

    &__undoButton {
        color: $primary;
        border-width: 0 !important ; //stylelint-disable-line
        height: 0.25 * $grid-gutter;
    }

    &__ruleDetail {
        animation-name: slide-right;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        opacity: 0;
        transform: translateX(100%);
        max-height: calc(100vh - 8rem); //doesnt like $grid-gutter
        height: 100vh;

        &--webProfile {
            animation-name: webProfile-animation;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: backwards;
            animation-delay: 0.25s;


            & + div {
                animation: slide-top 0.5s ease-in-out ;
            }

            & + div ~ div {
                animation: slide-top 0.5s ease-in-out ;
            }

        }

        &Body {
            height: 100%;
            overflow: auto;
        }

        &--match {
            border: 5px solid $primary-green;
            border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%, $primary-green 10%, $primary-green 55%,rgba(255, 255, 255, 0) 35%);
            border-image-slice: 5;
        }

        .form-group {
            margin-bottom: 2* $grid-gutter;
            margin-top: 0;
        }

        &Header {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &Close {
            border-width: 0 !important; //stylelint-disable-line
            margin-left: auto;
        }
    }

    &__delete {
        margin: 0 !important; //stylelint-disable-line

        &--open {
        color: $red !important; //stylelint-disable-line
        }
    }

    &__detailButton {
        display: flex;
        justify-content: flex-end;

        &--hidden {
            display: none;
        }
    }

    &__selectIcon {
        display: flex;
        align-items: center;
    }

    &__selectButton {
        border: 1px solid $input-border-color;
        width: 100%;

        &.active {
            border-color: $primary !important; //stylelint-disable-line
            color: $primary;
        }

        &:hover {
            border-color: $primary;
        }
    }

    &--DatatableHeight {
        min-height: 28 * $grid-gutter;
        transition: all 0.5s linear;
    }

    &__icon {
        float: left;
        display: flex;

        .icon {
            margin: $grid-gutter * 0.125;
            float: left;
        }

        &--log {
            color: $grey-xdark-30;
            position: absolute;
            top: 50%;
            left: calc(#{$grid-gutter} + 35%);
        }

        &--red {
            color: $reject-red;
        }

        &--green {
            color: $primary-green;
        }

        &--yellow {
            color: $yellow;
        }
    }

    &ColumnsSelect {
        width: 13 * $grid-gutter;
        margin: 0;
        height: 2.5 * $grid-gutter !important; //stylelint-disable-line
        label.packetFilterColumnsSelect__label {
            background-color: transparent;
        }
    }

    &__menu {
        min-width: 14.375 * $grid-gutter;
        margin: -5px -9px;

        &Button {
            width: 100%;
            padding: unset;
            cursor: pointer;
            border-width: 0 !important;  //stylelint-disable-line

            &Popup {
                width: 100%;
                border-width: 0 0 1px 0 !important;  //stylelint-disable-line
                border-radius: 0;
                border-color: $grey-xdark-30;
                border-style: solid;
                display: block;
                text-align: left;

                &--copy {
                    border-width: 0 0 0.5px 0 !important;  //stylelint-disable-line
                }

                &:last-child {
                    border-width: 0 !important;  //stylelint-disable-line
                    margin-left: 0;
                }
            }

            &--disable {
                color: $grey-xdark-30;
            }

            &--small {
                height: 26px;
            }
        }

    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
    }
}

@keyframes rotate-in-center {
    0% {
        transform: rotate(360deg);
        opacity: 0;
    }
    100% {
        transform: rotate(0);
        opacity: 1;
    }
}

@keyframes slide-top {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(3%);
    }
}

@include keyframes(webProfile-animation) {
    0% {
        height: 0;
        opacity: 0;
        transform: translateY(-10%);

    }
    100% {
        height: 100%;
        opacity: 1;
        transform: translateY(0);
    }
}

@include keyframes(slide-right) {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


