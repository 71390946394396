.react-tiny-popover-container {
    z-index: $z-index-modal-backdrop;
    max-width: 40 * $grid-gutter;
    overflow: unset !important; //stylelint-disable-line
}

.systemChart {
    margin-top: 2 * $grid-gutter;
}

.systemComponents {

    &__background {
        background-color: $white;
    }

    &--isActive {
        border-color: $primary-green;
    }

    &--isDown {
        border-color: $error-color;
    }

    &--isDisabled {
        border-color: $grey;
    }

    &__update {
        position: absolute !important; //stylelint-disable-line
        right: 2%;
    }

    &__cursor {
        cursor: default;
    }

    &__activityIcon {
        margin-bottom: 0.125 * $grid-gutter;
        margin-right: 0.125 * $grid-gutter;
    }

    &__enabled {
        position: absolute;
        top: 5%;
        left: 2%;
    }

    &__errorIcon {
        position: absolute;
        top: 8%;
        right: 4%;
    }

    &__textFlex {

        .toggleBox__text {
            display: flex;
            margin-top: 1 * $grid-gutter;
        }

        &--maxWidth {
            max-width: 8 * $grid-gutter;
        }
    }

    &__realService {
        color: $brown-dark;
        opacity: 0.5;
        font-size: 9px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.end {
    display: flex;
    justify-content: end;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexAlignCenter {
    display: flex;
    align-items: center;
}

.flexStart {
    display: flex;
    align-items: center;
}

.possibleAddrs {
    margin: 0.5 * $grid-gutter;
    overflow-wrap: normal;
}

.authentication {

    &__footer {
        display: flex;
        justify-content: space-between;
    }
    &__back {
        transform: rotate(180deg);
        cursor: pointer;
        color: $primary;
    }

    &__loginIcons {
        display: flex;
        justify-content: flex-end;
    }
}

.iconPopover {

    &__popover {
        min-width: 1 * $grid-gutter;
        min-height: 1 * $grid-gutter;
        padding: 0.25 * $grid-gutter;
    }

    &__icon {
        float: left;
        display: flex;

        .icon {
            margin: $grid-gutter * 0.125;
            float: left;
        }

        &--log {
            color: $grey-xdark-30;
            position: absolute;
            top: 50%;
            left: calc(#{$grid-gutter} + 35%);
        }

        &--red {
            color: $reject-red;
        }

        &--green {
            color: $primary-green;
        }
    }
}

.ips {
    &__cardTitle {
        display: flex;
    }
}

.h {
    &-2 {
        height: 2 * $grid-gutter;
    }

    &mi-2 {
        min-height: 2 * $grid-gutter;
    }
    &-2h {
        height: 2.5 * $grid-gutter;
    }
}

.MuiInput-underline {
    &::after, &::before {
        display: none;
    }
}

.network {

    &__row {

        &Cell {
            &--rightBorder {
                border-right: 1px solid $border-color;
            }

            &--leftPadding a {
                padding-left: 0.5 * $grid-gutter;
            }

            &--withoutMin {
                min-width: unset !important; //stylelint-disable-line
                width: 4 * $grid-gutter;
            }

            &--deviceName {
                padding: 0 0.8 * $grid-gutter;
                font-size: 90%;
            }

            &--deviceColor {
                margin-top: -0.5 * $grid-gutter;

                &--charts {
                    height: 0.2 * $grid-gutter;
                }
            }

            &--clusterHw {
                padding-left: 0.8 * $grid-gutter;

                &--syncStep {
                    flex-direction: column;
                }
            }
        }

        &New {
            position: relative;

            &AddButton {
                display: none;
                position: absolute;
                left: 40%;
                bottom: 20%;

                &--button {
                    height: 1.5 * $grid-gutter;
                    display: inline-block;
                    font-size: 12px;
                    background-color: $primary-green;
                    border-radius: 1.875 * $grid-gutter;
                    color: $white;
                    text-decoration: none;
                    padding: 2px 6px 2px 6px;
                    margin-right: 2px;
                    margin-top: -10px;
                    position: relative;
                    z-index: $z-index-modal-backdrop;
                    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);

                    .icon {
                        margin: -0.125 * $grid-gutter 0 0 0;
                    }
                }




            }

            &--text {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: $z-index;
                overflow: hidden;
            }

            &:hover {
                background-color: $primary-color-10 !important; //stylelint-disable-line

                .network__rowNewAddButton {
                    display: inline-block;
                }


            }
        }

        &UpdateButton {
            display: none;
            position: absolute;
            right: 45%;

            &--button {
                height: 1.5 * $grid-gutter;
                display: inline-block;
                font-size: 12px;
                background-color: $primary-green;
                border-radius: 1.875 * $grid-gutter;
                color: $white;
                text-decoration: none;
                padding: 2px 6px 2px 6px;
                margin-right: 2px;
                margin-top: -10px;
                position: relative;
                z-index: $z-index-modal-backdrop;
                box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);

                .icon {
                    margin: -0.125 * $grid-gutter 0 0 0;
                }
            }
        }

        &:hover {
            background-color: $primary-color-10 !important; //stylelint-disable-line


            .network__rowUpdateButton {
                display: inline-block;
            }


        }
    }

    &__card {
        min-height: 40vh;
        max-height: 45vh;
    }
}

.service {
    &__overlay {
        padding-top: 13 * $grid-gutter;
        padding-left: 5 * $grid-gutter;
        padding-right: 5 * $grid-gutter;
    }

    &__locked {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
        padding-top: 5 * $grid-gutter;
        padding-left: 5 * $grid-gutter;
        padding-right: 5 * $grid-gutter;
    }
}

.dhcpd {

    &__tableRow {
        margin-left: -2 * $grid-gutter;
        margin-right: -2 * $grid-gutter;
    }

    &__serverName {
        padding: 0;
        margin: 0;
        min-width: 10 * $grid-gutter;
    }

    &__overlay {
        padding: 13 * $grid-gutter 0 13 * $grid-gutter  5 * $grid-gutter;
    }

    &__cellInput {
        font-weight: 400;

        &.form-control--disabled {
            background-color: inherit;
        }
    }

    &__card {
        max-height: unset;
        height: unset;

        &Title {
            padding: 0 0 0.5 * $grid-gutter 0.875 * $grid-gutter;
        }
    }

    &__interface {
        display: flex;
        align-items: center;

        &Content {
            align-items: flex-start;
            padding: 0;
        }

        &Icons {
            display: flex;
            flex-direction: column;
        }
    }

    &__body {
        opacity: 1;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        max-height: 70vh;
        min-height: 25 * $grid-gutter;

        &--hide {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding: 0 (1.5 * $grid-gutter);
        }
    }

    &__inputs {
        opacity: 1;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        max-height: 40vh;

        &--hide {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding: 0 (1.5 * $grid-gutter);
        }
    }
}

.dns {
    &__overlay {
        padding: 13 * $grid-gutter 5 * $grid-gutter;
    }

    &__startButton {
        background-color: $primary-green;
        color: $white;
    }
}

.startButton {
    color: $white;
}

.noData {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-5;
    background: rgba(255,255,255,0.8);
    width: 100%;
    left: 0;


    &__label {
        font-weight: bold; //stylelint-disable-line
    }

}

.apiError {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

    & > p {
        margin: 0;
    }

    &Loader {
        background: rgba(255,255,255,0.8);
        height: calc(100% - #{$grid-gutter} * 3);
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

.textWithTooltip {
    cursor: default;
    color: $grey-xdark;
}


.device {

    &__menu {

        &--padding {
            padding: 0.75 * $grid-gutter 0.5 * $grid-gutter !important; //stylelint-disable-line
        }

        &Icon {
            margin-right: 0.75 * $grid-gutter;
            color: $brown-dark-70;
        }
    }

    &__icons {
        display: flex;
        justify-content: flex-end;
    }

    &__rebootIcon {
        transform: scaleX(-1);
    }
}

.confirmationModal {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dee2e6;
    padding: 0.625 * $grid-gutter $grid-gutter;
    border-radius: 0 0 0.25 * $grid-gutter 0.25 * $grid-gutter;

}
// overriding style from lib
// This override overflow-y from :scroll to :auto so there isn't always scrollbar visible
// but only when we need it
body.overflow-y-scroll {
    overflow-y: auto !important;  //stylelint-disable-line
}

.database {

    &__nerds {
        display: none;
    }

    &__bold {
        font-weight: 600;
    }

    &__stateIcon {

        &.check-circle-outline {
            color: $primary-green;
        }

        &.close-circle {
            color: $red;
        }
    }

    &__reloadIcon {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .information-outline {
            color: $brown-dark;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &Btn {
            display: flex;
            justify-content: flex-end;

            &--text {
                margin-top: 0.7 * $grid-gutter;
                margin-left: 0.5 * $grid-gutter;
                margin-right: 0.5 * $grid-gutter;

            }

        }

        &Header {
            display: flex;
            font-weight: 700;

            &Delete {
                padding-right: 0.6 * $grid-gutter;
                border-right: 1px solid $grey;
                cursor: pointer;

                &--selected {
                    color: $primary;
                }
            }

            &Reini {
                padding-left: 0.6 * $grid-gutter;
                cursor: pointer;

                &--selected {
                    color: $primary;
                }
            }
        }
    }

    &__arrow {
        margin-top: $grid-gutter;
    }
}

.cluster {

    &__masterIcon {
        position: absolute;
        top: -100%;
        left: 35%;
    }

    &__choose {
        position: absolute;
        top: 20%;
        left: 20%;
        padding: $grid-gutter;
        word-wrap: break-word;

        &Card {
            box-shadow: 0px 0px 50px 40px rgba(88, 74, 60, 0.2);
        }

        &Nodes {
            display: flex;
            justify-content: space-around;
            padding: $grid-gutter;

            &--node {
                cursor: pointer;
                padding: 5 * $grid-gutter;
                font-size: 2 * $grid-gutter;
                color: $primary;
                border: 2px solid $border-color;
                border-radius: $border-radius;

                &:hover {
                    border: 2px solid $primary;
                }
            }
        }
    }

    &__create {
        display: flex;
        justify-content: flex-end;

        &--text {
            max-width: 100%;
        }
    }

    &__modal {
        margin-top: 2 * $grid-gutter;
        max-width: 90%;
    }

    &__flexEnd {
        display: flex;
        justify-content: flex-end;
    }

    &__keytab {
        height: unset;
    }

    &__sharedInputs {
        margin-bottom: 5.75 * $grid-gutter;
    }

    &__node {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: $grid-gutter;

        &Title {
            margin-left: $grid-gutter;
        }

        &Text {
            display: flex;
            justify-content: space-between;
        }
    }

    &__small {
        width: 30%;

    }

    &__interface {
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            top: 25px;
            left: 0;
            right: 70%;
            height: 1px;
            box-shadow: inset 0 0 0 1px $border-color;
            transform: rotateZ(-10deg);
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            top: 25px;
            left: 70%;
            right: 0;
            height: 1px;
            box-shadow: inset 0 0 0 1px $border-color;
            transform: rotateZ(10deg);
        }
    }

    &__connection {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: -5%;
        width: 100%;
        height: 1px;
        margin: auto;
        box-shadow: inset 0 0 0 1px $border-color;

        &::after {
            @extend %full-fill;

            content: "";
            width: 1%;
            z-index: $z-index-5;
            box-shadow: 0 0 5px 1px $primary-color;
            margin: 0;
            border-radius: 50%;
            animation: clipMe 4s linear infinite;
        }

        &--second {
            top: 5%;

            &::after {
                animation-delay: 2s;
            }
        }

        &--ipsec {
            top: 70%;
            margin: 0;

            &--second {
                top: 90%;
                margin: 0;

                &::after {
                    animation-delay: 2s;
                }
            }
        }
    }

    &__shared {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        align-items: center;
    }
}

@keyframes clipMe {
    0%, 100% {left: 0%;}
    50% {left: 100%;}
}

%full-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.cwdbIsIn {
    display: flex;
    justify-content: flex-start;
    margin: -0.5 * $grid-gutter;
    flex-wrap: wrap;

    &--category {
        margin: 0.5 * $grid-gutter;
        background: rgba(229,229,229,0.8);
        padding: 2px 6px 2px 6px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 0.875 * $grid-gutter;
    }
}

.divider {

    &__container {
        display: flex;
        align-items: center;
        align-self: normal;

        &--vertical {
            flex-direction: column;
        }
    }

    &__border {
        border-bottom: 1px solid $border-color;
        width: 100%;

        &--vertical {
            border-left: 1px solid $border-color;
            height: 100%;
            width: 1px;
        }
    }

    &__content {
        padding: 0 10px 0 10px;

        &--vertical {
            padding: 10px 0 10px 0;
        }
    }
}


.certificationExpiration {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;

    &__single {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1 * $grid-gutter;
        width: 100%;
        max-width: 16 * $grid-gutter;


        .differs__path {
            padding: 0;
        }

        .differs__error__path {
            font-size: 12px;
        }

    }
    &__time {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: 5px;
        color: $secondary;
        font-style: italic;
        cursor: pointer;
    }

    .modal &__time {
        cursor: default;
    }

    &--warning {
        border-color: $yellow;
    }

    &--warning &__time {
        color: $yellow;
    }

    &--error {
        border-color: $error-color;
    }

    &--error &__time {
        color: $error-color;
    }

    &__file {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: transparent;
        color: $secondary;
    }
}

.graph {

    &__scene {
        .scrollMenu__wrapperItem {
            padding: 0;
            margin: 0;
        }
    }

    &__goTop {

        &Wrapper {
            position: sticky;
            z-index: $z-index-under-overlay;
            height: unset;
            width: calc(100vw - 15.5rem);
            top: 0;
        }
    }

    &LogButton {
        color: $black;
        stroke-width: 0;

        rect {
            stroke-width: 0;
            fill: none;
        }

        &--active {

            rect {
                stroke-width: 0;
                fill: none;
            }

            text {
                color: $primary !important; //stylelint-disable-line
                fill: $primary !important; //stylelint-disable-line
                stroke-width: 0;

            }
        }
    }

    &Time {
        &Filter {
            max-height: 20 * $grid-gutter;
            height: unset;

            &--fast {
                max-height: 6 * $grid-gutter;
                height: unset;
            }

            &__col {
                position: relative;
                padding: 0 0.5 * $grid-gutter;
                max-width: 12 * $grid-gutter;

                &--fast {
                    padding: 0 0.5 * $grid-gutter;

                }
            }

        }


    }

    &Settings {
        position: absolute;
        right: 3%;
        top: 2%;
    }


    &Data {

        &Div {
            display: flex;
        }

        &Time {
            position: absolute;
            top: 4 * $grid-gutter;
            left: 1.2 * $grid-gutter;
        }

        &Magnify {
            position: absolute;
            right: 0.8 * $grid-gutter;
            top: 20%;
        }

        &Shortcut {
            display: flex;
            flex-wrap: wrap;

            &--wrap {
                min-width: 12 * $grid-gutter;
            }
        }

        &Button {
            border: 0;
            padding-left: 0.6 * $grid-gutter;
            padding-right: 0.6 * $grid-gutter;
            height: 1.25 * $grid-gutter;
            text-transform: none;
            flex: 1 0 25%;

            &--active {
                color: $primary !important; //stylelint-disable-line
            }

            &--left {
                padding-left: 0;
            }
            &--icon {
                display: flex;
                align-items: flex-end;
            }
        }

        &Filters {
            display: flex;
            position: absolute;
            align-items: baseline;
            top: $grid-gutter;
            left: $grid-gutter * 2;
            z-index: $z-index-5;
        }

        &Filter {
            padding: 0 $grid-gutter;
            display: flex;
        }

        &Checkbox {
            padding: 0 $grid-gutter;
            margin-bottom: 0;
        }

    }

    &Card {
        height: unset;

        &Body {
            position: relative;
            min-height: 10 * $grid-gutter;
            height: 100%;
            padding: 1.2 * $grid-gutter 1.25 * $grid-gutter;

            &--error {
                min-height: unset;
            }
        }

        &Header {
            background-color: $white;
        }
    }

    &Col {
        padding-left: calc(#{$grid-gutter} + 1px);

        &--right {
            padding-left: 0 ;
        }
    }
}

.mainDashboardCardHeader {
    background-color: $secondary-green-light;
    font-weight: 700;
    color: $secondary-color;
}

.license {

    &__upload {
        height: unset;
        margin-bottom: 0.5 * $grid-gutter;
    }

    &__buttonDiv {
        display: flex;
        justify-content: flex-end;
    }

    &__closeTest {
        position: absolute;
        top: 0.3125 * $grid-gutter;
        right: 0.3125 * $grid-gutter;
    }
}

.colorBasic {
    color: $grey-xdark;
}

.titleAndDesc {
    &__popover {
        max-width: 40vw;
    }
}

.verificationError {

    &--desc {
        margin-left: 3 * $grid-gutter;
    }
}

.displayNone {
    display: none !important; //stylelint-disable-line
}

.relative {
    position: relative;
}

.modal {
    z-index: $z-index-modal;
    overflow: auto;

    &-backdrop {
        z-index: $z-index-modal-backdrop;
    }

    &-fluid {
        width: 80vw;
        max-width: unset;
    }

    &-body {
        font-size: 0.875 * $grid-gutter;
        min-height: 5 * $grid-gutter;
    }

    & .dontShowInModal {
        display: none;
    }
}

.z-index-5 {
    z-index: $z-index-5;
}

.autoRefresher {

    &__wrapper {
        position: relative;
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 0;

    }
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.backup {
    position: relative;
    overflow: hidden;

    &__btn {
        display: flex;
        justify-content: center;
    }

    &__icn {
        float: right;
        margin-top: $grid-gutter;
    }

    &__recoveryMode {
        display: flex;
        justify-content: center;

        &__title {
            color: $secondary-color-40;
        }
    }
}

.networkDatatable {
    .modal & {
        display: none;
    }
}


.dragable {
    .scrollMenu {
        position: relative;
        overflow: visible;

        &__wrapperItem {
            outline: none;
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            display: flex;
            scroll-behavior: smooth;
            padding-top: $grid-gutter * 2;
            margin-top: -$grid-gutter * 2 ;
        }

        &__input {
            display: flex;
            margin-bottom: -0.8 * $grid-gutter;
        }

        &__text {
            width: 100%;
            padding: 0.625 * $grid-gutter 0.75 * $grid-gutter;
            margin: 0;
            font-size: 0.9375 * $grid-gutter;
            font-weight: 600;
            line-height: 1.25;
            letter-spacing: 0.28px;
        }

        &__number {
            position: absolute;
            top: 30%;
            right: 5%;
            border: 1px solid $primary;
            border-radius: 100%;
            width: $grid-gutter;
            height: $grid-gutter;
            font-size: x-small;
            color: $white;
            background: $primary;
            text-align: center;
            padding-left: 1px;
            z-index: $z-index-2;

            &--inactive {
                z-index: $z-index-2;
            }
        }

        &__item {
            padding: 0.5 * $grid-gutter;
            user-select: none;
            cursor: pointer;
            border: none;
            min-width: 12 * $grid-gutter;
            position: relative;

            & :focus {
                outline: none;
            }

            &--selected {
                background-color: $white;
                border-top: 1px solid $input-border-color;
                border-left: 1px solid $input-border-color;
                border-right: 1px solid $input-border-color;
                border-radius: $border-radius $border-radius 0 0;

                &.scrollMenu__item--primary {
                    background-color: $primary-color-10;
                }

                .scrollMenu__menu {
                    display: inline;
                }
            }

            &--disabled {
                background-color: $white;

            }


            &--primary {
                border-color: $primary-color-30;
            }

            &--overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: $z-index-1;
                top: 0;
                left: 0;
            }
        }

        &__arrow {
            &--left {
                position: absolute;
                left: -$grid-gutter;
                top: $grid-gutter;
                padding: 0;
                margin: 0;
            }

            &--right {
                position: absolute;
                right: -$grid-gutter;
                top: $grid-gutter;
                padding: 0;
                margin: 0;
            }
        }

        &__menu {
            position: absolute;
            display: none;
            top: -45%;
            right: 0;
            z-index: $z-index-3;

            &__icons {
                display: flex;
                justify-content: flex-end;
            }
        }

        &:hover {
            .scrollMenu__menu {
                display: inline;
            }
        }

        &--selected {
            .scrollMenu__menu {
                display: inline;
            }
        }

    }
}

.absoluteFullSize {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


.scrollMenu { // TODO: remove after we are sure portaling works 100% AK-1973

    &__wrapperItem {
        outline: none;
    }

    &__item {
        padding: 0.5 * $grid-gutter $grid-gutter;
        user-select: none;
        cursor: pointer;
        border: none;
        min-width: 5 * $grid-gutter;

        & :focus {
            outline: none;
        }

        &--selected {
            background-color: $white;
            border-top: 1px solid $input-border-color;
            border-left: 1px solid $input-border-color;
            border-right: 1px solid $input-border-color;
            border-radius: $border-radius $border-radius 0 0;

            &.scrollMenu__item--primary {
                background-color: $primary-color-10;
            }
        }

        &--primary {
            border-color: $primary-color-30;
        }
    }

    &__arrow {
        &--left {
            margin-left: -1.5 * $grid-gutter;
            cursor: pointer;
            font-size: 1.5 * $grid-gutter;
            padding: 0 0.25 * $grid-gutter;
        }

        &--right {
            margin-right: -1.5 * $grid-gutter;
            cursor: pointer;
            font-size: 1.5 * $grid-gutter;
            padding: 0 0.25 * $grid-gutter;
        }


    }
}

.scroll-menu-arrow--disabled {
    display: none;
}

.dnsRules {
    max-height: 13 * $grid-gutter;
}

.safeSearch {
    animation-name: safeSearch-animation;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: backwards;
    overflow: hidden;

    &--card {
        transition: all 1s linear;
    }

}

@include keyframes(safeSearch-animation) {
    0% {
        height: 0;
        opacity: 0;

    }
    100% {
        height: 100%;
        opacity: 1;
    }
}

.notification-container {

    &--center {
        display: none;
    }

    &--top-center {
        display: none;
    }

    &--bottom-left {
        display: none;
    }

    &--top-right {
        display: none;
    }

    &--top-left {
        display: none;
    }

    &--top-full {
        display: none;
    }

    &--bottom-full {
        display: none;
    }


    &--red {
        background-color: $reject-red;
    }
}

.color {
    &--red {
        color: $red;
    }

    &--green {
        color: $primary-green;
    }

    &--yellow {
        color: $yellow;
    }
}

.handbook {
    padding: 2 * $grid-gutter;

    + div {
        margin-bottom: 2 * $grid-gutter;
    }
}

.namedObjects {
    &__row {
        min-height: 25 * $grid-gutter;
    }
}

.opacity {

    &--01 {
        opacity: 0.1;
    }

    &--02 {
        opacity: 0.2;
    }

    &--03 {
        opacity: 0.3;
    }

    &--04 {
        opacity: 0.4;
    }

    &--05 {
        opacity: 0.5;
    }

    &--06 {
        opacity: 0.6;
    }

    &--07 {
        opacity: 0.7;
    }

    &--08 {
        opacity: 0.8;
    }

    &--09 {
        opacity: 0.9;
    }
}

.clicable {
    cursor: pointer;
}

.vpn {

    &__scene {
        position: relative;
    }

    &__chevron {
        color: $secondary-color-20;
        position: absolute;
        top: 7%;
        right: -1 * $grid-gutter;
    }

    &__serverDiv {
        color: $black;
        border: 2px solid $input-border-color;
        border-radius: $input-border-radius;
        padding: 0.375 * $grid-gutter;
        margin: -0.375 * $grid-gutter;
    }

    &__crlVerify {
        border: 2px solid $input-border-color;
        border-radius: $input-border-radius;

    }

    &__pushToUser {

        &Header {
            display: flex;
            justify-content: space-between;
        }

        &Routes {
            background-color: rgba(#5cc1bc , 0.2);
        }
    }
}


.cardHide {
    &__body {
        opacity: 1;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        padding-top: 0;
        overflow: visible;

        &--hide {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding: 0 (1.5 * $grid-gutter);
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;

        &--noBorderBottom {
            border-bottom: 0;
        }

        &--borderBottom {
            border-bottom: $border-bottom;
        }
    }
}

.adaptiveIsIn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.5 * $grid-gutter 1.25 * $grid-gutter;
}

.adaptiveImIn {

    &__desc {
        font-size: 0.8 * $grid-gutter;
    }

    &__container {
        overflow: auto;
        max-height: 50vh;
    }

    &__showMore {
        color: $primary;
        margin: 0 auto;
        display: table;
    }
}

.float-right {
    float: right;
}

.justify-content-center {
    justify-content: center;
}

.text {

    &--line {
        text-decoration: line-through;
    }

    &--red {
        color: $red-30;
    }

    &--textColor {
        color: $secondary-color-40;
    }
}

.fit-content {
    width: fit-content;
    height: fit-content;
}

.displayInline {
    display: inline-block;
}


.activateConfiguration {
    box-shadow: 0 0 0 0 $primary;
    animation: pulse 2s infinite;
}

.row {
    &--center {
        align-items: center;
    }
}

.pannable {
    overflow: hidden;
    background-color: $secondary-color-03;
    padding: 0.5 * $grid-gutter;
}

.PanAndZoomImage-container {
    overflow: hidden;
    height: 100%;
}

.colorInput {
    width: 100% !important; //stylelint-disable-line
    height: 0.3 * $grid-gutter !important; //stylelint-disable-line
    padding: 0;
}

.margin--1 {
    margin-bottom: -0.5 * $grid-gutter;
}

.treeChart {
    border-radius: $border-radius;

    &__input {
        border: 0 !important; //stylelint-disable-line
        background-color: inherit !important; //stylelint-disable-line
        margin-bottom: 0;
        margin-top: 0;
        min-width: 11 * $grid-gutter;
        min-height: 2.5 * $grid-gutter;
        cursor: text !important; //stylelint-disable-line
    }

    &__number {
        position: absolute;
        top: 0;
        left: 52%;
        border: 1px solid $primary;
        border-radius: 100%;
        width: $grid-gutter;
        height: $grid-gutter;
        font-size: x-small;
        color: $white;
        background: $primary;
    }

    &__card {
        background: $white;
        display: inline-block;
        border-radius: $grid-gutter;
        height: unset;
        max-height: unset;
        position: relative;
        min-width: 16 * $grid-gutter;

        &__title {
            padding: 0;
            margin: 0;
            max-width: 10 * $grid-gutter;
        }

        &:hover {
            .treeChart__AddButtons {
                opacity: 1;
            }
            .treeChart__ActionIcons {
                display: block;
            }
        }
    }

    &__Action {

        &Text {
            font-size: 0.875 * $grid-gutter;
        }

        &Icons {
            display: none;
            position: absolute;
            width: 100%;
        }
    }



    &__Add {
        position: absolute;
        height: 2 * $grid-gutter;
        width: 100%;
        z-index: $z-index-2;

        &Buttons {
            opacity: 0;
            position: absolute;
            width: 100%;
            padding-top: 0.5 * $grid-gutter;

            &--button {
                height: 1.5 * $grid-gutter;
                display: inline-block;
                font-size: 12px;
                background-color: $primary-green;
                border-radius: 1.875 * $grid-gutter;
                color: $white;
                text-decoration: none;
                padding: 2px 6px 2px 6px;
                margin-right: 2px;
                margin-top: -10px;
                position: relative;
                box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
                z-index: $z-index-modal-backdrop;


                .icon {
                    margin: -0.125 * $grid-gutter 0 0 0;
                }

                &--green {
                    background-color: $primary-green;
                }

                &--secondary {
                    background-color: #584a3d;
                }
            }

            &:hover {
                .treeChart__AddButtons {
                    opacity: 1;
                }
            }
        }

        &:hover {
            .treeChart__AddButtons {
                opacity: 1;
            }
        }

    }
}

.zIndex-15 {
    z-index: $z-index-15;
}

@keyframes pulse {
    0% {
        transform: scale(0.97);
        box-shadow: 0 0 0 0 rgba($primary, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($primary, 0);
    }

    100% {
        transform: scale(0.97);
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}

.pointer {
    cursor: pointer !important; //stylelint-disable-line
}

.dragging .nft {
    display: none;
}

.vertical
.nft {
    &--icon {
        position: absolute;
        top: 21%;
        left: 50%;

        &2 {
            position: absolute;
            left: 0;
            top: 18%;
        }

        &Right {
            right: 5%;
        }
    }

    &--div {
        display: flex;
        flex-direction: column;
        align-items: center;
        mix-blend-mode: multiply;
    }

    &--title {
        padding-top: 0;
        padding-bottom: 0;


        & div {
            & div {
                align-self: center;
            }
        }
    }


    &--action {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        padding: 0;

        &Single {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;
        }
    }


}

%animateArrowsDown {
    animation: stripes infinite;
    animation-timing-function: ease-out;
    animation-duration: 10s;
    background-image: linear-gradient(to right, $primary-color-60 50%,  $grey 50%);
    background-position: 100% 0%;
    background-size: 100% 200%;
    color: transparent;
    animation-fill-mode: forwards;


}

%animateArrowsRight {
    animation: stripes infinite;
    animation-timing-function: ease-out;
    animation-duration: 10s;
    background-image: linear-gradient(to right, $primary-color-60 50%,  $grey 50%);
    background-position: 100% 0%;
    background-size: 200% 100%;
    color: transparent;
    animation-fill-mode: forwards;
}


.arrow {
    height: 3px;
    width: 16px;
    margin: 0 auto;
    position: relative;

    &--second {
        animation-delay: 2s;

        &::before, &::after {
            animation-delay: 2.5s;
        }
    }

    &--third {
        animation-delay: 4s;

        &::before, &::after {
            animation-delay: 4.5s;
        }
    }

    &--fourth {
        animation-delay: 6s;

        &::before, &::after {
            animation-delay: 6.5s;
        }
    }

    &--fifth {
        animation-delay: 8s;

        &::before, &::after {
            animation-delay: 8.5s;
        }
    }


    &::before, &::after {
        content: "";
        position: absolute;
        height: 3px;
        width: 12px;

    }

    &--down {
        @extend %animateArrowsDown;

        transform: rotate(90deg);
        background-size: 200% 100%;


        &::before, &::after {
            @extend %animateArrowsDown;

            background-size: 200% 100%;
        }

    }

    &--right {
        @extend %animateArrowsRight;

        &::before, &::after {
            @extend %animateArrowsRight;
        }

    }

    &::before {
        right: -3px;
        bottom: -4px;
        transform: rotate(-45deg);
    }

    &::after {
        right: -3px;
        top: -4px;
        transform: rotate(45deg);
    }
}

@keyframes stripes {
    from {
        background-position: 100% 0%;
        //background-image: linear-gradient(to right, $primary 50%,  #57483c 50%);
    }
    20% {
        background-position: 0% 0%;
    }
    30% {
        background-position: -100% 0%;
    }
    100% {
        background-position: -100% 0%;
    }
}

@keyframes stripesRight {
    from {
        background-position: 0% 100%;
        //background-image: linear-gradient(to right, $primary 50%,  #57483c 50%);
    }
    20% {
        background-position: 0% 0%;
    }
    30% {
        background-position: 0% -100%;
    }
    100% {
        background-position: 0% -100%;
    }
}



.bookmarkRibbon {
    z-index: $z-index-modal;
    float: right;
    font-weight: 700;
    position: sticky;
    top: $grid-gutter;
    cursor: pointer;
    max-width: 2 * $grid-gutter;

    &--toggle {
        background-color: $secondary;
        border-style: solid;
        border-radius: 0.25 * $grid-gutter;
        border-color: $secondary;
        cursor: pointer;
        border-color: $secondary;

        &:hover {
            .bookmarkRibbon--text {
                height: fit-content;
                width: fit-content;
                color: $white-light;
                display: inline-block;

            }

            .bookmarkRibbon--help {
                display: none;
            }
        }
    }

    &--position {
        position: absolute;
        right: 0px;
        top: 10%;
        display: flex;
        flex-direction: column;
    }


    &--text {
        height: 0;
        max-height: fit-content;
        max-width: fit-content;
        width: 0;
        color: transparent;
        writing-mode: vertical-rl;
        padding: 0.2 * $grid-gutter 0;
        vertical-align: middle;
        transition: height 0.5s ease-in-out;
        display: none;
    }

    &--icon {
        margin-left: -0.625 * $grid-gutter;
    }

    &--help {
        color: $white-light;
        writing-mode: vertical-rl;
        padding: 0.2 * $grid-gutter 0;
        vertical-align: middle;
    }

    &--active {

        .bookmarkRibbon--text {
            height: fit-content;
            width: fit-content;
            color: $white-light;
            display: inline-block;

        }

        .bookmarkRibbon--help {
            display: none;
        }
    }

}

.noEffects {
    pointer-events: none;
    cursor: pointer;
}

.min {
    &-1 {
        min-width: $grid-gutter;
    }

    &-2 {
        min-width: 2 * $grid-gutter;
    }

    &-3 {
        min-width: 3 * $grid-gutter;
    }

    &-4 {
        min-width: 4 * $grid-gutter;
    }

    &-5 {
        min-width: 5 * $grid-gutter;
    }
}
//right
.r {
    &-0 {
        right: 0;
    }
}

//left
.l {
    &-unset {
        left: unset;
    }

    &-0 {
        left: 0 !important;  //stylelint-disable-line
    }
}

//width
.w {
    &-0 {
        width: 0;
    }

    &-1p5 {
        width: 1.5 * $grid-gutter !important; //stylelint-disable-line
    }

    &-3p5 {
        width: 3.5 * $grid-gutter !important; //stylelint-disable-line
    }

    &-100 {
        width: 100%;
    }
}

.displayContents {
    display: contents;
}

.closeWithLock {
    &__lock {
        position: absolute;
        right: 2%;
        top: 1.5 * $grid-gutter;
        opacity: 0.9;
        color: $secondary;
        transform: rotate3d(1, 1, -6, 40deg);
    }

    &__close {
        opacity: 0.4;
    }
}

.ipsec {

    &--logo {
        top: 22%;
        position: absolute;
        left: 0;
    }

    &--otherDevice {
        top: 22%;
        position: absolute;
        left: 0;
    }

    &__middleRow {
        align-self: center;
    }
}

.wpad {
    &__title {
        z-index: $z-index-on-overlay;

        &--disabled {
            color: $white;
        }
    }

}

.minHeight {

    &-unset {
        min-height: unset;
    }

    &-10 {
        min-height: 10 * $grid-gutter;
    }
}

.sticky {
    &-right {
        &--00 {
            position: sticky;
            right: 0;
        }

        &--05 {
            position: sticky;
            right: 5%;
        }
    }
}

.font30 {
    font-size: 30px;
}

.fontBold {
    font-weight: 700;
}


[data-tool]::after {
    content: attr(data-tool);
    position: absolute;
    background-color: $black;
    color: $white;
    bottom: 0%;
    left: 25%;
    font-size: 0.875 * $grid-gutter;
    width: max-content;
    padding: 0.1 * $grid-gutter;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: none;
    z-index: $z-index-modal-backdrop;

}

[data-tool]::before {
    content: "";
    opacity: 0;
    display: inline-block;
    position: absolute;
    left: 28%;
    bottom: 39%;
    border: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent $black transparent;
    display: none;
    z-index: $z-index-modal-backdrop;
}

[data-tool]:hover::after {
    opacity: 0.9;
    display: inline;
}

[data-tool]:hover::before {
    opacity: 0.9;
    display: inline;

}
