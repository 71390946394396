.profile {

    &__title {
        border-bottom: 0;
    }

    &__noLog {
        text-decoration: line-through;
        color: $red-30;
    }

    &__headersText {
        color: $secondary-color-70;
        margin-bottom: $grid-gutter;
        display: block;
    }

    &__profile {
        transition: all 0.5s linear;

        &Category {
            display: none;

            &--shown {
                display: block;
            }
        }
    }

    &__detail {

        &Close {
            border-width: 0 !important; //stylelint-disable-line
            margin-left: auto;
            padding: 0.5 * $grid-gutter;

            &Icon {
                margin: 0;
            }
        }

        &Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.75 * $grid-gutter 0.5 * $grid-gutter;
        }


    }

    &__card {
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

        &--big {
            height: 75vh;
        }

        &--medium {
            height: 65vh;
        }
    }

    &__select {
        display: flex;
        align-items: center;

        &--text {
            margin-left: 0.3 * $grid-gutter;
        }
    }

    &__cell {

        &--disabled {
            //background-color: $grey-light-50;
            opacity: 0.5;
        }
    }
    &__log {
        color: $primary-green;
    }
    &__icons {
        display: flex;
        justify-content: flex-end;

        &--rotate {
            transform: rotate(180deg);
        }
    }

    &__pfRules {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        &--rule {
            margin-right: $grid-gutter;
        }
    }

    &__body {
        opacity: 1;
        transition-property: all;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
        overflow: auto;

        &--hide {
            max-height: 0;
            overflow: hidden;
            opacity: 0;
            padding: 0 (1.5 * $grid-gutter);
        }
    }
}
