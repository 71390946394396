.loginContent {
    @include login-layout-inner;

    position: relative;
    height: 100%;

    &__wrap {
        @include login-layout-wrap;

        position: relative;
        height: 100%;
        z-index: $z-index-3;
        transition-property: width, padding-left;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
    }

    &__inner {
        top: 50%;
        transform: translateY(-60%);
        position: absolute;
        width: 50vw;
    }

    &__code {
        font-size: calc(8vw + 6vh + #{$grid-gutter} * 2);
        line-height: 1;
        color: $white-50;
        margin-bottom: $grid-gutter * 2;
    }

    &__title {
        font-size: $grid-gutter * 2;
        color: $white;
        margin-bottom: $grid-gutter * 2;
    }

    &__body {
        color: $white;
        margin-bottom: $grid-gutter * 2;
    }

    &__list.list {
        font-size: $grid-gutter;
        color: $white-light;

        & > li {
            &::before {
                border-color: $white-light;
            }
        }
    }

    &__btnGroup {
        display: flex;
        margin-left: -$grid-gutter;
        margin-right: -$grid-gutter;

        .btn {
            margin: 0 $grid-gutter;
        }
    }
}
