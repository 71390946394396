
.policy {

    &__overlay {
        display: none;

        &--active {
            position: absolute;
            top: 0;
            display: block;
            z-index: $z-index-5;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $white;
            transition-property: transform;
            transition-timing-function: cubic-bezier(1, 0, 0, 1);
            transition-duration: $transition-time;
            opacity: 0.9;
            transform-origin: top left;
        }

        p {
            position: absolute;
            top: 35%;
            font-size: 1.2 * $grid-gutter;
            left: 7%;
        }
    }

    &__selectIcon {
        display: flex;
        align-items: center;
        font-size: 0.875 * $grid-gutter;
        font-weight: 600;

        &--switch {
            justify-content: center;
            margin-left: $grid-gutter;
        }

        &--disabled {
            cursor: not-allowed;
        }
    }

    &__category {
        margin-bottom: 1 * $grid-gutter;

        &Row {
            overflow: hidden;
            height: 100%;


            &Cat {
                max-height: 100%;
            }
        }

        &Div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                padding: 0.5 * $grid-gutter 0.75 * $grid-gutter;
            }
        }

        &Select {
            margin-bottom: 1.5 * $grid-gutter;


            & ~ label div {
                background-color: $primary-color-10 !important; //stylelint-disable-line
                margin: 0 -0.3 * $grid-gutter;
            }
        }

        &Button {
            border-width: 0.0625 * $grid-gutter !important; //stylelint-disable-line
            box-shadow: none !important; //stylelint-disable-line
            border-color: $border-color;


            &--active {
                box-shadow: 0 4px 10px 0 $black-16 inset !important; //stylelint-disable-line
            }
        }



    }

    &__card {
        min-width: 12 * $grid-gutter;
        max-height: 80vh;
        animation-name: slide-right;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        opacity: 0;
        transform: translateX(100%);
    }

    &__div {
        justify-content: flex-start;
    }

    &__supercategory {
        padding: 0.5 * $grid-gutter;
        position: relative;
        cursor: pointer;
        border-bottom: 1px solid $border-color;

        &::before {
            top: $grid-gutter * 1.7;
            transform: translate(0, -50%);
            transition: transform $transition-global;
            content: url("data:image/svg+xml; utf-8, <svg fill='rgba(55, 45, 35, 0.3)' xmlns='http://www.w3.org/2000/svg' style='width:16x;height:28px;' viewBox='0 0 30 52'><path d='M25.6,17.6C20.4,12.3,15.1,7,9.9,1.8c-2.3-2.3-6-2.3-8.3-0.1C-0.6,4-0.6,7.7,1.7,10c4.4,4.4,8.8,8.9,13.3,13.3  c1.4,1.4,1.4,4,0,5.4C10.6,33.1,6.2,37.6,1.8,42c-2.3,2.3-2.3,6-0.1,8.3s6,2.2,8.3-0.1c5.2-5.3,10.5-10.6,15.7-15.8  C31.4,28.6,31.5,23.5,25.6,17.6z' /></svg>");
            width: $grid-gutter;
            height: $grid-gutter * 1.75;
            position: absolute;
            left: -$grid-gutter * 0.75;
            z-index: $z-index-4;
        }

        &--isCategory {
            padding: 0;
        }

        &Select {
            margin-bottom: 0;
            margin-top: 0;

            & ~ label div {
                background-color: $primary-color-10 !important; //stylelint-disable-line
                margin: 0 -0.3 * $grid-gutter;
            }
        }

        &Div {
            overflow-x: hidden;
            background-color: $primary-color-10;
            height: 100%;
            overflow: auto;

            &--selected {
                background-color: $primary-color-10;
                padding-left: 0.6 * $grid-gutter;
            }
        }

        &--selected {
            background-color: $primary-color-10;
            position: relative;

            &::before {
                content: url("data:image/svg+xml; utf-8, <svg fill='rgb(238, 113, 1)' xmlns='http://www.w3.org/2000/svg' style='width:16x;height:28px;' viewBox='0 0 30 52'><path d='M25.6,17.6C20.4,12.3,15.1,7,9.9,1.8c-2.3-2.3-6-2.3-8.3-0.1C-0.6,4-0.6,7.7,1.7,10c4.4,4.4,8.8,8.9,13.3,13.3  c1.4,1.4,1.4,4,0,5.4C10.6,33.1,6.2,37.6,1.8,42c-2.3,2.3-2.3,6-0.1,8.3s6,2.2,8.3-0.1c5.2-5.3,10.5-10.6,15.7-15.8  C31.4,28.6,31.5,23.5,25.6,17.6z' /></svg>");
            }
        }

    }

    &__divider {
        margin-right: -1.3 * $grid-gutter;
        margin-left: -1.3 * $grid-gutter;
        height: $grid-gutter * 0.125;

        &:first-of-type {
            height: $grid-gutter * 0.25;
        }

        &:last-of-type {
            height: $grid-gutter * 0.25;
        }
    }

    &__icon {
        float: left;
        display: flex;

        .icon {
            margin: $grid-gutter * 0.125;
            float: left;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        &--cursor {
            cursor: pointer;
        }
    }

    &__detail {
        max-height: 50 * $grid-gutter;
        overflow: auto;

        &Color {
            background-color: $primary-color-10;
        }

        .form-group {
            margin-bottom: 1* $grid-gutter;
            margin-top: 1* $grid-gutter;
        }


    }

    &__settings {

        .form-group {
            margin-bottom: 1* $grid-gutter;
            margin-top: 1* $grid-gutter;
        }
    }

    &__row {
        margin-right: -1.3 * $grid-gutter;
        margin-left: -1.3 * $grid-gutter;

        &--selected {
            background-color: $primary-color-10;
            margin-right: -3.3 * $grid-gutter;
        }
    }

}

.profiles {

    &__enabled {
        visibility: hidden;
        background-color: inherit;
    }

    &__number {
        position: absolute;
        top: 6%;
        right: -6%;
        border: 1px solid $primary;
        border-radius: 100%;
        width: $grid-gutter;
        height: $grid-gutter;
        font-size: x-small;
        color: $white;
        background: $primary;
        text-align: center;
    }


    &__exceptionsDatatable {
        min-height: 48 * $grid-gutter;
    }

    &__row {

        &--disabled {
            background-color: $grey-light !important; //stylelint-disable-line
            color: $grey-xdark-30 !important; //stylelint-disable-line
        }

        &:hover {

            .profiles__enabled {
                visibility: visible;
            }
        }
    }

    &__text {
        max-width: 58 * $grid-gutter;
    }

    &__title {
        display: flex;
        justify-content: space-between;

        &--noBorderBottom {
            border-bottom: 0;
        }

        &--borderBottom {
            border-bottom: $border-bottom;
        }

        &--sm {
            padding: 0;
        }


        &Actions {
            display: flex;
        }
    }

    &__body {

        &--sm {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5 * $grid-gutter;
        cursor: pointer;
    }


    &__accordingToCategory {
        display: flex;
        align-items: center;
        gap: $grid-gutter;
    }


    &__firstRule {

        &Row {
            height: 15 * $grid-gutter;
        }

        height: 15 * $grid-gutter;
    }


    &__add {
        &Buttons {
            display: none;
            position: absolute;
            width: 100%;
            margin-top: -10px;
            margin-left: -1px;

            &--button {
                height: 1.5 * $grid-gutter;
                display: inline-block;
                font-size: 12px;
                background-color: $primary-green;
                border-radius: 1.875 * $grid-gutter;
                color: $white;
                text-decoration: none;
                padding: 2px 6px 2px 6px;
                margin-right: 2px;
                margin-top: -10px;
                position: relative;
                z-index: $z-index-5;
                box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);

                .icon {
                    margin: -0.125 * $grid-gutter 0 0 0;
                }
            }
        }

        &Rule {
            background-color: inherit;
            height: 6px;
            position: relative;
            text-align: center;

            &:hover {
                background-color: $grey-light;

                .packetFilter__addButtons {
                    display: block;
                }
            }
        }

        &:first-of-type {
            background-color: $white;
        }
    }
}

@include keyframes(slide-right) {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


@keyframes slide-left {
    0% {
        margin-left: -2 * $grid-gutter;

    }
    100% {
        margin-left: 0;
    }
}

