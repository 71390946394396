
.routingTable {

    &__cardTitle {
        display: flex;
        justify-content: space-between;
        padding-right: 2.25 * $grid-gutter;
    }

    &__destination {
        max-width: 9 * $grid-gutter;
        width: 25%;
    }

    &__gateway {
        max-width: 9 * $grid-gutter ;
        width: 60%;

        &Input {
            max-width: 9 * $grid-gutter;
        }
    }

    &__menuButton {
        width: 100%;
        padding: unset;
        cursor: pointer;
        border-width: 0 !important;  //stylelint-disable-line

        &Popup {
            width: 100%;
            border-width: 0 0 1px 0 !important;  //stylelint-disable-line
            border-radius: 0;
            border-color: $grey-xdark-30;
            border-style: solid;

            &--copy {
                border-width: 0 0 0.5px 0 !important;  //stylelint-disable-line
            }

            &:last-child {
                border-width: 0 !important;  //stylelint-disable-line
            }
        }

        &--disable {
            color: $grey-xdark-30;
        }
    }

}