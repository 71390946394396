.loginPage {
    position: relative;

    &__main {
        display: flex;
        width: 100%;
        height: 100%;
        z-index: $z-index-3;
        position: relative;

    }

    // background element with inlined SVG
    &__aside {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: $z-index-1;
        overflow: hidden;
        background-color: $primary-color-dark;
    }

    &__background {
        width: 100%;
        height: 100%;
    }

    &__blur {
        width: 100%;
        position: absolute;
        overflow: hidden;
        z-index: $z-index-2;
        backdrop-filter: blur(5px);
        transition-property: height;
        transition-duration: $transition-time-slow;
        transition-timing-function: ease-in;

        &::after {
            content: "";
            position: absolute;
            width: 110%;
            height: 110%;
            top: -5%;
            left: -5%;
            background-color: $black-50;
            opacity: 0.7;
        }

        &--top {
            height: 215px;
            top: 0;
        }

        &--fullHeight {
            height: 100%;
        }

        &--bottom {
            height: calc(#{$grid-gutter * 8} + 40px);
            bottom: 0;
        }
    }
}

