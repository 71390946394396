@include scrollbar;

html {
    @include bp-max(sm) {
        font-size: 87.5%;
    }
}

body {
    font-family: Encode Sans, sans-serif;
    color: $font-color;

    // TODO: delete after ant-design removal
    height: initial;
    width: initial;
}

.layout {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 100%;
    overflow: auto;
    min-height: 55 * $grid-gutter;

    .loggedIn & {
        min-height: unset;
    }
}


.header {
    height: $header-height;
    width: 100%;
    box-shadow: $z-depth-1;
    position: relative;
    z-index: $z-index-header;
}

.content {
    display: flex;
    width: 100%;
    height: calc(100vh - #{$header-height});

    .recoveryStatusBar ~ & {
        height: calc(100vh -  (6 * $grid-gutter));
    }
}

.main {
    order: 2;
    width: 100%;
    height: calc(100vh - #{$header-height});
    background-color: $default-bg-light;
    box-shadow: $z-depth-1-left;
    position: relative;
    z-index: $z-index-2;
    overflow: auto;

    &:hover + .differs {
        .differs__changes {
            padding-left: 0;
            margin-left: 0;

        }
    }
}

.help {

    &Button {
        position: relative;
    }

    &Link {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
    }

    &Icon {
        margin-bottom: 0.2 * $grid-gutter;
        margin-right: $grid-gutter;
        margin-left: -$grid-gutter;
    }

    &__Card {
        overflow: hidden;
        box-shadow: 0 0 0 !important; //stylelint-disable-line
        max-height: 93vh !important; //stylelint-disable-line

        &Body {
            overflow: auto;
        }
    }

    &Drop {
        margin-right: -2 * $grid-gutter;
        margin-left: 0.5 * $grid-gutter;
        border-left: 1px solid $grey-xdark-30;
    }

    &Div {
        order: 2;
        height: 100%;
        overflow: auto;
        flex-shrink: 0;
        background-color: $white;
        z-index: $z-index-3;
        width: 31.25 * $grid-gutter;

        &__icon {
            padding: 0 0 0 0.5 * $grid-gutter;
            background: inherit;
            border: 0;
            outline-width: 0 !important; //stylelint-disable-line
            color: $navbar-dark-color;;
        }

        &__menu {
            background-color: $white;
        }
    }

    &__li {
        border-radius: 0.5 * $grid-gutter;

        &--primary {
            color: $primary-green;
        }

        &__zoom {

            &__0 {
                font-size: $grid-gutter * 0.75;
            }

            &__1 {
                font-size: $grid-gutter * 0.85;
            }

            &__2 {
                font-size: $grid-gutter * 0.95;
            }

            &__3 {
                font-size: $grid-gutter * 1.05;
            }
        }
    }
}

.aside {
    @include scrollbarDark;

    order: 1;
    height: 100%;
    position: relative;
    overflow: auto;
    flex-shrink: 0;
    direction: rtl;
    background-color: $default-color-dark;
    z-index: $z-index-aside; //one more of a overlay

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $grid-gutter;
        background-color: $default-color-dark;
        z-index: $z-index-3;
    }
}

.page {
    height: 100%;
    width: 100%;
    min-height: 640px;
    overflow: hidden;

    &.loadingPage {
        background-color: $brown-dark;
    }

    &--replaceFE {
        position: relative;
    }
}

.scene {
    height: calc(100vh - 4rem - 3rem);
    width: 100%;
    overflow: hidden;
    min-height: 800px;
}

// TODO: delete all ant-design styles below after ant-design removal
.ant-layout-content {
    flex: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 $grid-gutter;
}

.ant-modal {
    max-width: 80vw;
}

.anticon {
    margin: 0.2 * $grid-gutter;
    vertical-align: initial !important; //stylelint-disable-line
}

::-moz-color-swatch {
    border-color: transparent;
}
