.listWidget {
    height: 100%;
    overflow: hidden;
    perspective: $grid-gutter * 19;
    margin-left: auto;
    max-height: $grid-gutter * 16;
    min-height: $grid-gutter * 16;


    @include bp-min(lg) {
        max-height: $grid-gutter * 27.5;
        min-height: $grid-gutter * 27.5;
    }
    &__resolve {
        color: $grey-xdark-30;
    }

    &__list {
        overflow: auto;
        overflow-x: hidden;
    }

    &Item {
        font-size: $grid-gutter * 0.75;
        font-weight: 500;
        padding: $grid-gutter 0 $grid-gutter $grid-gutter ;
        display: flex;
        flex-direction: row;
        align-items: center;
        transition: background-color $transition-time linear;
        overflow: hidden;
        position: relative;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &__label {
            line-height: $grid-gutter  * 1.2;
            margin-left: $grid-gutter * 0.5 ;
            width: auto;
        }

        &__icon {
            border: 0;
            text-align: right;
            font-size: $grid-gutter * 2;
            margin-left: auto;
            margin-right: $grid-gutter* 0.5;
            font-weight: 500;
            padding-left: $grid-gutter;
            visibility: hidden ;

            &--active {
                visibility: visible ;
            }
        }

        .Ripple-outline {
            background-color: rgba(238,113,1, 0.1);
        }

        &--active {
            background-color: $secondary-green-light;
            transition: background-color $transition-time linear;
        }
        &Wrap--active + li {
            animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.5s;
        }
        &Wrap--active + li + li {
            animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.7s;
        }
        &Wrap--active + li + li + li {
            animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.9s;
        }
        &Wrap--active + li + li + li ~ li {
            animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 1.1s;
        }

    }
    .list-group {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .listWidgetItemWrap {
            border-bottom: 1px solid $secondary-color-10;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            .list-group-item {
                border-top: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .list-group-item:last-child {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    &__footer {
        background-color: $white;

        @include bp-max(lg) {
            display: none;
        }
    }

    &__loader {
        background: rgba(255,255,255,0.8);
        height: calc(100% - #{$grid-gutter} * 3);
        width: 100%;
        bottom: 0;
        left: 0;
        padding: $grid-gutter * 1.5;
    }
}

@keyframes slide-top {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(var(--heightOfItem));
    }
}
