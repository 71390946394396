.switch {
    display: flex;
    border: 2px solid $input-border-color;
    justify-content: space-between;
    align-items: center;
    font-size: $grid-gutter * 0.75;
    color: $secondary-color-70;
    font-family: Encode Sans;
    font-weight: 600;
    min-height: $grid-gutter * 2.5;
    min-width: $grid-gutter * 8.125 ;
    border-radius: $border-radius;
    flex-wrap: wrap;

    &--noticeMe {
        box-shadow: 0 0 0 0 $primary;
        animation: switchpulse 2s infinite;
        height: 0;
    }

    &--withoutFlexWrap {
        text-wrap: nowrap;
        flex-wrap: nowrap;
    }

    &--tooltip {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &--disabled {
        opacity: 0.4;
        pointer-events: none;
        touch-action: none;
    }

    &__input {
        display: none;

        // active (checked) state of switch
        &--checked + .switch__on {
            // add color to text
            color: $primary-color;

            // rail
            &::before {
                background-color: $primary-color-10;
                box-shadow: inset 0px 0px 6px -2px $primary-color-30;
            }

            // knob
            &::after {
                left: calc(50% + #{$grid-gutter * 0.75});
                background-color: $primary-color;
            }
        }

        &--checked + .switch__on--mini {
            // add color to text
            color: $primary-color;

            // rail
            &::before {
                background-color: $primary-color-10;
                box-shadow: inset 0px 0px 6px -2px $primary-color-30;
            }

            // knob
            &::after {
                left: calc(50% + #{$grid-gutter * 0.75});
                background-color: $primary-color;
            }
        }
    }

    &__name {
        padding: $grid-gutter * 0.5 $grid-gutter * 0.75;
        font-size: $font-size-tiny;

        &--likeLabel {
            position: absolute;
            top: 0;
            transform: translateY(-14px) scale(0.857);
            background: #ffffff;
            padding: 0 5px;
            font-size: 0.9375 * $grid-gutter;
            color: $secondary-color;
        }

        &--white {
            color: $white;
        }
    }

    &__label {
        display: flex;
        margin: 8px;
        justify-content: space-between;
        align-items: center;
        font-family: Encode Sans;
        font-size: $font-size-tiny;
        font-weight: 600;
        color: $placeholder-color;
        // min-width: $grid-gutter * 8.5;
        position: relative;
        padding: $grid-gutter * 0.5 $grid-gutter * 0.75;
        cursor: pointer;
        transform: none;

        & > span {
            padding: $grid-gutter * 0.125;

            &.switch__off {
                padding-right: 2 * $grid-gutter;
            }

            &.switch__on {
                padding-left: 2 * $grid-gutter;
            }

        }

        &--WithoutMargins {
            margin: 0;
            padding: 0;
        }

        &--likeInput {
            width: 100%;
            padding: 0;

        }
    }



    &__off {
        padding-left: $grid-gutter;

        &--active {
            color: $primary-color;
        }
    }

    &__on {
        transition: color $transition-global;

        // rail default position
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: $grid-gutter * 2.5;
            height: $grid-gutter * 0.875;
            border-radius: $grid-gutter * 1.25;
            box-shadow: inset 0px 0px 6px -2px $secondary-color-20;
            background-color: $grey-xdark-10;
            transition-property: background-color;
            transition-duration: $transition-time;
            cursor: pointer;
        }

        // knob default position
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: calc(50% - #{$grid-gutter * 0.75});
            transform: translate(-50%, -50%);
            width: $grid-gutter * 1.5;
            height: $grid-gutter * 1.5;
            border-radius: $grid-gutter * 0.75;
            box-shadow: 0px 1px 7px -3px $secondary-color-50;
            background-color: $white;
            transition-property: left, background-color;
            transition-duration: $transition-time;
            transition-timing-function: cubic-bezier(1, 0, 0, 1);
        }

        &--mini {
            transition: color $transition-global;
            // rail mini position
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: $grid-gutter * 2;
                height: $grid-gutter * 0.875;
                border-radius: $grid-gutter * 1.25;
                box-shadow: inset 0px 0px 6px -2px $secondary-color-20;
                background-color: $grey-xdark-10;
                transition-property: background-color;
                transition-duration: $transition-time;
                cursor: pointer;
            }

            // knob mini position
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: calc(50% - #{$grid-gutter * 0.75});
                transform: translate(-50%, -50%);
                width: $grid-gutter * 1;
                height: $grid-gutter * 1;
                border-radius: $grid-gutter * 0.75;
                box-shadow: 0px 1px 7px -3px $secondary-color-50;
                background-color: $white;
                transition-property: left, background-color;
                transition-duration: $transition-time;
                transition-timing-function: cubic-bezier(1, 0, 0, 1);
            }
        }


    }

    &--bothActive {

        .switch__on {

            &::after {
                background-color: $primary;
            }

            &::before {
                background-color: $primary-color-10;
            }
        }

        .switch__on--mini {

            &::after {
                background-color: $primary;
            }

            &::before {
                background-color: $primary-color-10;
            }
        }
    }

    // right aligned switch
    &--right {
        text-align: right;
        justify-content: flex-end;
    }

    &--center {
        text-align: center;
        justify-content: center;
    }

    &--left {
        justify-content: flex-start;
    }

    &--withoutMinWidhtHeight {
        min-width: unset;
        min-height: unset;
    }

    &--border {
        border: 0;
    }

    &--spaceBetween {
        justify-content: space-between;
    }

    &--start {
        align-items: flex-start;
        flex-direction: column;
    }
}

@keyframes switchpulse {
    0% {
        transform: scale(0.97);
        box-shadow: 0 0 0 0 rgba($primary, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 71px 23px rgba($primary, 0);
    }

    100% {
        transform: scale(0.97);
        box-shadow: 0 0 71px 23px rgba($primary, 0);
    }
}
