input.changes-type-toggler {
    display: none;

    & + label {
        padding: 0 $grid-gutter * 0.5;
        cursor: pointer;
        position: relative;

        &:first-of-type {
            padding-left: 0;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.1em;
            background-color: $primary;
            opacity: 1;
            transition: opacity 300ms, transform 300ms;
            transform: scale(0);
            transform-origin: center;
        }

        &:hover::after {
            transform: scale(1);
        }

        & .toggles {

            &--show {
                display: inline;
            }

            &--hide {
                display: none;
            }
        }

    }

    & ~ div div.toggleable { display: none; }
    &:checked {
        & + label {
            color: $primary;

            & .toggles {

                &--show {
                    display: none;
                }

                &--hide {
                    display: inline;
                }
            }
        }
        &.minor-toggle ~ div div.minor { display: block; }
        &.new-toggle ~ div div.new { display: block; }
        &.improvement-toggle ~ div div.improvement { display: block; }
        &.bugfixes-toggle ~ div div.bugfixes { display: block; }
        &.warnings-toggle ~ div div.warnings { display: block; }
    }
}

.changes {
    padding: 2 * $grid-gutter;
    max-width: 70 * $grid-gutter;

    &__toggles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: $grid-gutter 0;
    }

    &__icon {
        margin-right: 2px;
    }

    &__headline {
        font-size: 2 * $grid-gutter;


        &--main {
            font-size: 2.5 * $grid-gutter;
        }
    }
}

.BoldVersion {
    font-weight: 700;
}

.ChangedVersionArrow {
    margin-bottom: 2px;
}
