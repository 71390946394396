.input-range__label {
    color: $input-range-label-color;
    font-family: $input-range-font-family;
    font-size: $input-range-label-font-size;
    transform: translateZ(0);
    white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
    bottom: $input-range-label-position-bottom;
    position: absolute;
}

.input-range__label--min {
    left: 0;
}

.input-range__label--max {
    right: 0;
}

.input-range__label--value {
    position: absolute;
    top: $input-range-label-value-position-top;
}
