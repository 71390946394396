@import "input-range/input-range-variables";
@import "input-range/input-range-slider";
@import "input-range/input-range-label";
@import "input-range/input-range-label-container";
@import "input-range/input-range-track";

/**
 * Styles taken from react-input-range library that is not in development anymore but has deprecated scss styles.
 * This folder should consist of everything that react-input-range exports but fixed based on dependencies.

 @error
  [1] DEPRECATION WARNING: Using / for division is deprecated and will be removed in Dart Sass 2.0.0.
  [1] Recommendation: math.div($input-range-slider-width, -2)

  Library react-input-range I consider as dead ( no commits for 5 years ) so there is no test to check if they
  fixed it in sourece code.
 */

.input-range {
    height: $input-range-slider-height;
    position: relative;
    width: 100%;
}
