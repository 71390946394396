.list {
    list-style: none;
    padding-left: 0;
    margin-bottom: $grid-gutter * 3;

    & > li {
        padding-left: $grid-gutter * 2;
        margin-bottom: $grid-gutter;
        position: relative;

        &::before {
            content: "";
            width: $grid-gutter;
            height: $grid-gutter;
            border: $border-width solid $grey-light;
            border-radius: $grid-gutter * 0.5;
            position: absolute;
            top: $grid-gutter * 0.25;
            left: 0;
        }
    }
}
