@use "sass:math";

.form-group {

    .select {
        cursor: text;
        min-width: $grid-gutter * 8;
        padding: 0;
        height: unset;
        z-index: $z-index-1;
        overflow: visible;
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: space-between;
        min-height: 2.375 * $grid-gutter;
        position: relative;
        transition: all 100ms ease 0s;
        outline: 0px;

        .dataTableWidget__cell--sm &,
        &.dataTableWidget__RowInput--sm {
            min-height: unset;
            height: 1.5 * $grid-gutter;

            &.select__control--is-focused {
                height: unset;
            }

            .select__value-container {
                flex-wrap: nowrap;
            }
        }

        &.selectColumns {
            min-height: 2.5 * $grid-gutter;
        }

        &--paste {
            margin: 0.4 * $grid-gutter;
            margin-right: 0.6 * $grid-gutter;
            height: unset;
            width: unset;
        }

        &--tooltip {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        &.form-control--singleValue {
            & > div {
                flex-wrap: nowrap;
            }
        }

        &--compact {
            min-height: unset;

            .select__value-container {
                padding: 0;
            }
        }

        & label {
            @include label;

            transform: translateY(0.625 * $grid-gutter);
        }


        &__placeholder {
            color: $placeholder-color;
            margin-left: 0.3125 * $grid-gutter;
        }

        &.form-control--disabled {
            & ~ label.active {
                transform: translateY(-100%) scale(0.857);
            }
        }

        &.form-control--focused {
            &:not(.form-control--invalid):not(.form-control--warning) .select__indicator {
                color: $primary-color;
            }

            .select__dropdown-indicator {
                transform: scaleY(-1);
            }
        }

        &__value-container {
            flex-grow: 1;
            display: flex;
            align-items: center;
            position: relative;
            flex-wrap: wrap;
            box-sizing: border-box;
            padding: 0 (2 * $grid-gutter) 0 (0.5 * $grid-gutter);
            //overflow: hidden;
        }

        &__control--justIcon .select__value-container {
            padding: 0 (0.5 * $grid-gutter) 0 (0.5 * $grid-gutter);
        }

        &.form-control--negated {
            .select__value-container {
                padding: 0 (2 * $grid-gutter) 0 ($grid-gutter);
            }
        }



        &__indicator {
            display: flex;
            color: $input-border-color;
            padding: $grid-gutter * 0.375;
            cursor: pointer;
        }

        &__indicator-separator {
            background-color: $input-border-color;
        }

        &__dropdown-indicator {
            transform: scaleY(1);
            transition-property: color, transform;
            transition-duration: $transition-time;
            transition-timing-function: ease-in-out;
        }

        &__multi-value {

            &.not-removable {
                padding-right: 3px;
            }
            &__label {
                font-weight: 600;
                font-size: 95%;
            }

            &--is-disabled {

                .select__multi-value__label {
                    padding-right: 0.375 * $grid-gutter;
                }
            }

        }

        &__control {
            border: 2px solid $input-border-color ;
            border-radius: 0.25* $grid-gutter;
            box-shadow: 0;

            &--mini {
                min-width: $grid-gutter * 5;
            }

            &--normal {
                min-width: $grid-gutter * 9;
                margin-left: -0.5 * $grid-gutter;
            }

            &--is-focused {
                border-color: $primary ;
                box-shadow: 0 0 0 0 ;
                background-color: $white !important; //stylelint-disable-line

                &.dataTableWidget__RowInput--sm .select__value-container,
                .dataTableWidget__cell--sm & .select__value-container {
                    flex-wrap: wrap;
                }
            }

            &--justIcon {
                width: 2.5 * $grid-gutter;
                min-width: 0;
                border: 0;
                margin-left: 0;
                background: unset;
            }

            .overlay--active & {
                background-color: $white;
            }
        }

        .dataTable__RowInput > .select__control {
            background-color: $white;
            border: 0 solid $input-border-color ;
        }
    }

    &--row {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.select { // PORTAL OUTSIDE FORM
    &__menu {
        opacity: 1;
        padding: 2px 0;
        width: calc(100% - #{$border-width * 2} - #{$border-radius});
        overflow: auto;
        background-color: $white;
        border: 1px solid $input-border-color;
        border-top: 0;
        box-shadow: $z-depth-2;
    }

    &--createNetwork {
        width: 15 * $grid-gutter;
        margin-left: 2 * $grid-gutter;

        &Icon {
            position: absolute;
            top: 32%;
            left: 2%;
        }
    }

    &--createNetwork + .select__menu .select__option--is-focused {
        background-color: $warning-color-30;
    }

    &__option {
        width: 100%;
        padding: $input-padding-y ($input-padding-x - math.div($border-radius, 2));
        cursor: pointer;
        min-height: $input-height;
        height: 100%;
        font-size: $font-size-small;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 0.28px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &--is-focused {
            background-color: $primary-color-10;
        }


        &--is-selected {
            background-color: $primary-color-30;
            cursor: default;
            font-weight: 600;
        }

        &--is-disabled {
            color: $placeholder-color;
            cursor: default;
            pointer-events: none;
            touch-action: none;
        }
    }

    &__menu-list {
        max-height: 15 * $grid-gutter;
    }

    &--row {

        .select__menu-list {
            max-height: 13.25 * $grid-gutter;
        }

        .select .select__placeholder {
            font-weight: 400;
        }
    }
}

.selectError {

    &__multi-value {
        background-color: $primary-color-30 !important;  //stylelint-disable-line

        &__label {
            font-weight: 600 !important;  //stylelint-disable-line
        }

    }

    &__control {
        border: 2px solid $error-color !important;  //stylelint-disable-line
        border-radius: 0.25* $grid-gutter!important;  //stylelint-disable-line
        box-shadow: 0 0 0 1px $error-color !important;  //stylelint-disable-line

        &--is-focused {
            border-color: $error-color !important;  //stylelint-disable-line
            box-shadow: 0 0 0 1px $error-color !important;  //stylelint-disable-line
        }
    }

    &__option {
        height: 50% !important;  //stylelint-disable-line
        margin-left: -0.5 * $grid-gutter;

        &--is-focused {
            background-color: $primary-color-30 !important;  //stylelint-disable-line
        }
    }

    &__menu-list {
        max-height: 11.25 * $grid-gutter !important;  //stylelint-disable-line
    }
}

.form-control--dark {

    .select {
        background-color: $input-dark-bg-color;
        border-color: $input-dark-border-color;

        & ~ label {
            color: $input-dark-label-color;
            cursor: pointer;

            // TODO?: important has to overide default bootstrap style
            transform: none !important; // stylelint-disable-line
            transition: color $transition-global;
            top: initial;
            bottom: 100%;
            font-size: $input-label-font-size * 0.857;
            font-weight: 400;
            line-height: 1;
            margin-bottom: $grid-gutter * 0.25;
            background-color: transparent;

            &.active {
                transform: none;
                font-weight: 400;
            }
        }
    }

    &.form-control--disabled {
        .select {
            border: solid;
            border-width: 2px;
            border-color: $grey-light;
            background-color: $grey-light;
            opacity: 0.35;
            pointer-events: none;
            touch-action: none;

            & ~ label {
                pointer-events: none;
                touch-action: none;
            }
        }
    }
}

.select__box {
    border-radius: 2px;
    overflow: hidden;
    padding: 0.1875 * $grid-gutter;
    padding-left: 0.375 * $grid-gutter;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 85%;
}

.select__value-container {
    div[class$=-Input] {
        margin: 0;
    }
}

.select__input {
    display: block !important; // stylelint-disable-line

    &-container {
        flex-grow: 0 !important; //stylelint-disable-line
    }
    input {
        position: absolute;

        &:focus {
            position: relative;
        }
    }
}

.select__multi-value__label {
    border-radius: 2px;
    display: flex;
    padding: 2px;
    min-width: 0;
    box-sizing: border-box;
}

.select2.select2--row {
    margin-bottom: 0;
    margin-top: 0;
    & .form-control__label {
        display: none;
    }
    &.select2--force-label .form-control__label {
        display: unset;
    }
    &.select2--wrap {
        .select {
            &__value-container {
                flex-wrap: wrap;
            }
        }
    }
    .select {
        &__control {
            border: 0;
            background-color: inherit;
            cursor: text;
            &--is-focused .select__value-container {
                flex-wrap: wrap;
            }
        }
        &__value-container {
            flex-wrap: nowrap;
        }
    }
}

.select2.select2--single-val {
    .select {
        &__control {
            &--is-focused .select__value-container {
                flex-wrap: nowrap !important; //stylelint-disable-line
            }
        }
    }
}

/* I Hate SCSS (because regular CSS became so good) */
.select2.select2--row.select2--sm, .dataTableWidget__cell--sm .select2.select2--row {
    min-height: unset;
    .select {
        min-height: unset;
        height: 1.5 * $grid-gutter;

        &.select__control--is-focused {
            height: unset;
        }
    }
}

.select2.select2--justIcon .select {
    &__control {
        padding: 0 0 0 0;
        width: 2.5 * $grid-gutter;
        min-width: 0;
        border: 0;
        margin-left: 0;
        background: unset;
    }
    &__input-container {
        caret-color: rgba(0,0,0,0);
    }
}

.select2 .select {
    &.select {
        width: unset;
        height: 100%;
    }
    &__indicators {
        display: flex;
        gap: 2px;

        /*
            Z index must be larger than default because otherwise
            the select tooltip "covers" the icons and they are not clickable
        */
        z-index: 1;
    }
    &__value-container {
        padding: 0 0 0 0;
        flex-grow: 1;
        display: flex;
        align-items: center;
        position: relative;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    &__control {
        padding: 0 (0.5 * $grid-gutter) 0 (0.5 * $grid-gutter);

        // warning state for focused input
        &.form-control--warning {
            border-color: $input-warning-color;
            box-shadow: $input-box-shadow-values $input-warning-color;
            caret-color: $input-warning-color;

            & ~ label.active,
            & label.active,
            & svg.icon {
                color: $input-warning-color;
            }
        }

        // success state for focused input
        &.form-control--invalid {
            border-color: $input-error-color;
            box-shadow: $input-box-shadow-values $input-error-color;
            caret-color: $input-error-color;

            & ~ label.active,
            & label.active,
            & svg.icon {
                color: $input-error-color;
            }
        }
    }

    &__input-container {
        visibility: visible;
        flex: 1 1 auto;
        display: inline-grid;
        grid-area: 1/1/2/3;
        grid-template-columns: 0 min-content;
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        color: hsl(0, 0%, 20%);
        box-sizing: border-box;
        transform: translateZ(0);

        &::after {
            content: attr(data-value) "";
            visibility: hidden;
            white-space: pre;
            grid-area: 1/2;
            font: inherit;
            min-width: 0px;
            border: 0;
            margin: 0;
            outline: 0;
            padding: 0;
        }
        &[data-value=""] { //stylelint-disable-line
            /*
                This rule prevents awkward wrapping with new line being produced in select that has 0 values/input,
                which happens because empty input element has width+margin and it wraps to the new line
            */
            margin: 0px;
            width: 0px;
        }

    }

    &__multi-value {
        display: flex;
        min-width: 0;
        border-radius: 2px;
        margin: 2px;
        box-sizing: border-box;
        z-index: 101;
        cursor: pointer;
        background-color: $primary-color-30;
        &.not-removable {
            padding-right: 3px;
            cursor: default;
        }
        & .textWithTooltip {
            cursor: inherit;
        }
        &__label {
            border-radius: 2px;
            display: flex;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: hsl(0, 0%, 20%);
            padding: 3px;
            padding-left: 6px;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 95%;
        }
        &__remove {
            align-items: center;
            display: flex;
            border-radius: 2px;
            padding-left: 4px;
            padding-right: 4px;
            box-sizing: border-box;

            &.highlight {
                background-color: #ffbdad;
                color: #de350b;
            }

            & svg {
                display: inline-block;
                fill: currentColor;
                line-height: 1;
                stroke: currentColor;
                stroke-width: 0;
            }
        }
    }
}
// vim: sw=4 ts=4 et
