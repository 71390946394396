// ===========================================================================
// BOOTSTRAP variables
// ===========================================================================

// import Kernun colors
@import "colors";

$grid-gutter: 1rem;
$grid-gutter-width: $grid-gutter * 2;


$grid-breakpoints: (zero: 0, xs: 360px, sm: 576px, md: 768px, lg: 992px, xl: 1440px, xxl: 1600px, fullhd: 1920px);

// dropdown carret
$caret-width: $grid-gutter * 0.45;
$caret-spacing: auto;
$caret-vertical-align: initial;

$dropdown-padding-y: 0;
$dropdown-border-color: $black-07;
$dropdown-border-radius: 0;
$dropdown-inner-border-radius: 0;
$dropdown-link-active-bg: $white;
$dropdown-bg: transparent;

// navbars
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-brand-padding-y: 0;
$navbar-dark-active-color: $secondary-color-70;

// links
$link-color: $primary-color;
//spacers
$spacer: $grid-gutter !default;
$spacers: (4: ($spacer * 2));
// colors
$primary: $primary-color;
$secondary: $secondary-color;
