.form-control.inputTime {
    min-width: 9 * $grid-gutter;
    overflow: visible;

    > input {
        padding: 0;
        border: 0;
        outline: none;
        max-height: 2.2 * $grid-gutter;
    }
}

// REACT-DATETIME styles

/* eslint-disable */
.nice-dates-navigation {
    align-items: center;
    color: $secondary-color-40;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}

.nice-dates-navigation_current {
    flex-grow: 1;
    font-size: 16px;
}

.nice-dates-navigation_previous, .nice-dates-navigation_next {
    cursor: pointer;
    height: 0;
    padding-bottom: calc(100% / 7);
    position: relative;
    width: calc(100% / 7);
}

.nice-dates-navigation_previous::before, .nice-dates-navigation_next::before {
    border-right: 2px solid $grey-xdark-30;
    border-top: 2px solid $grey-xdark-30;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 14px;
    left: 50%;
    margin-left: -7px;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    transition: 200ms border-color;
    width: 14px;
}

.nice-dates-navigation_previous:hover::before, .nice-dates-navigation_next:hover::before {
    border-color: $secondary-color-40;
}

.nice-dates-navigation_previous.-disabled, .nice-dates-navigation_next.-disabled {
    cursor: default;
    pointer-events: none;
}

.nice-dates-navigation_previous.-disabled::before, .nice-dates-navigation_next.-disabled::before {
    border-color: $grey-medium;
}

.nice-dates-navigation_previous::before {
    transform: rotate(-135deg);
}

.nice-dates-week-header {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    display: flex;
    padding: 8px 0;
}

.nice-dates-week-header_day {
    color: $font-color;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    text-transform: capitalize;
    width: calc(100% / 7);
}

.nice-dates-grid {
    box-sizing: content-box;
    overflow: hidden;
    position: relative;
    user-select: none;
}

.nice-dates-grid_container {
    backface-visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    left: 0;
    position: absolute;
    right: 0;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-property: none;
    will-change: transform;
}

.nice-dates-grid_container.-moving .nice-dates-day_date {
    transition: 300ms color;
}

.nice-dates-grid_container.-origin-bottom {
    top: auto;
    bottom: 0;
}

.nice-dates-grid_container.-origin-top {
    top: 0;
    bottom: auto;
}

.nice-dates-grid_container.-transition {
    transition-property: transform;
}

.nice-dates-day {
    border-top: 1px solid transparent;
    box-sizing: border-box;
    color: $font-color;
    cursor: pointer;
    font-size: 14px;
    position: relative;
    text-align: center;
    width: calc(100% / 7);
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.nice-dates-day_date {
    transition: 150ms color;
    will-change: color;
    z-index: $z-index-3;
}

.nice-dates-day_month {
    color: $font-color;
    font-size: 12px;
    left: 0;
    line-height: 1;
    position: absolute;
    right: 0;
    text-transform: capitalize;
    top: 10%;
    z-index: $z-index-3;
}

.nice-dates-day::before, .nice-dates-day::after {
    border-radius: 999px;
    bottom: 0;
    box-sizing: border-box;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.nice-dates-day::before {
    background-color: $primary-color-30;
    z-index: $z-index-1;
}

.nice-dates-day::after {
    border: 2px solid $secondary-color-30;
    transform: scale(0.95);
    transition-duration: 150ms;
    transition-property: transform, opacity;
    z-index: $z-index-2;
}

.nice-dates-day:not(.-disabled):hover::after {
    opacity: 1;
    transform: scale(1);
}

@media (hover: none) {
    .nice-dates-day::after {
        content: none;
    }
    .nice-dates-day.-selected * {
        color: $font-color;
    }
}

.nice-dates-day.-selected::before {
    opacity: 1;
}

.nice-dates-day.-selected:hover::after {
    background-color: $white;
}

.nice-dates-day.-selected:not(:hover) *, .nice-dates-day.-selected.-disabled * {
    color: $font-color;

}

.nice-dates-day.-selected.-selected-start::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 0;
}

.nice-dates-day.-selected.-selected-end::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
}

.nice-dates-day.-selected.-selected-middle::before {
    border-radius: 0;
    left: 0;
    right: 0;
}

.nice-dates-day.-selected.-selected-middle:nth-child(7n+1)::before {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n)::before {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.nice-dates-day.-today {
    font-weight: 600;
}

.nice-dates-day.-wide::before, .nice-dates-day.-wide::after {
    left: 12.5%;
    right: 12.5%;
}

.nice-dates-day.-outside {
    color: $grey-xdark-30;
}

.nice-dates-day.-disabled {
    cursor: default;
    pointer-events: none;
    color: $grey-medium;
}

.nice-dates-popover {
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    margin: 8px 0;
    max-width: 600px;
    position: absolute;
    transform-origin: top;
    transform: perspective(1000px);
    transition-property: transform, opacity;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
    width: 100%;
    will-change: transform, opacity;
    z-index: $z-index-5;
}

.nice-dates-popover:not(.-open) {
    opacity: 0;
    pointer-events: none;
    transform: perspective(1000px) rotateX(-15deg);
}


//------------------------------


.rdt {
    position: relative;
}

.rdtPicker {
    display: none;
    position: absolute;
    min-width: 250px;
    padding: 4px;
    margin-top: 1px;
    background: $white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border: 1px solid #f9f9f9;
}

.rdtOpen .rdtPicker {
    display: block;
}

.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}

.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}

.rdtPicker td {
    cursor: pointer;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}

.rdtPicker td.rdtToday {
    position: relative;

    &::before {
        content: "";
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid $primary;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
    }
}

.rdtPicker td.rdtActive {

    &:hover {
        background-color: $primary;
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
}

.rdtPicker td.rdtActive.rdtToday {

    &::before {
        border-bottom-color: $white;
    }
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}

.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}

.rdtPicker th.rdtSwitch {
    width: 100px;
}

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker thead tr:first-of-type th {
    cursor: pointer;
}

.rdtPicker thead tr:first-of-type th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}

.rdtPicker button:hover {
    background-color: #eeeeee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eeeeee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    user-select: none;
}

.rdtCounter .rdtBtn:hover {
    background: #eeeeee;
}

.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}

/* eslint-enable */
