.upgrade {

    &Change {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        background-color: $white;

        &__row {
            width: 30%;
            z-index: $z-index-2;
            background-color: $grey-xdark;
            box-shadow: 0 0 100px 100px $primary;
            display: flex;
            flex-direction: column;
            padding: 4 * $grid-gutter;
        }

        &__wave {
            position: absolute;
            top: 0;
            animation-name: moveSlideshow;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            background-color: $white;
            background-color: $primary-color-10;
            height: 100%;
            box-shadow: 0px 0px 100px 100px $primary-color-10;
            width: 10%;
            margin-left: $grid-gutter;
        }

        &__logo {
            padding-left: 3.5 * $grid-gutter;
            padding-top: 0.5 * $grid-gutter;
        }

        &__window {
            min-height: 20 * $grid-gutter;
            overflow: auto;
            background-color: $white;
            position: absolute;
            top: 30%;
            left: 70%;
            z-index: $z-index-2;
            max-height: 35vh;
            width: 30 * $grid-gutter;
            padding: 2 * $grid-gutter;

        }
    }

    &__cardBody {
        height: 25 * $grid-gutter;
        overflow: auto;

        &--small {
            height: auto;
            max-height: 20 * $grid-gutter;
        }
    }

    &__lastChecked {
        position: absolute;
        left: 0;
    }
    &__monitoring {
        display: flex;
        flex-direction: column;

        &Link {
            width: fit-content;
        }
    }

    &__toggleBox {

        &Label {
            background-color: $white !important; //stylelint-disable-line
            left: 2%;

        }

        &Text {
            width: 80%;
        }
    }

    &__arrow {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &Position {
            position: absolute;
            left: 30%;
        }
    }

    &__loaderIcon {
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &__changesButton {
        outline: 5px auto;
        margin-right: 0.5 * $grid-gutter;
    }

    &__settings {

        &Header {
            padding: $grid-gutter 1.5 * $grid-gutter ;
        }

        .form-group {
            margin-bottom: 2* $grid-gutter;
            margin-top: 0;
        }

        &Left {
            margin-left: -0.5 * $grid-gutter;
        }

        &PingMaxWidth {
            max-width: 15 * $grid-gutter;
        }
    }

}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}


@keyframes moveSlideshow {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(1000%);
    }
}

