// ===========================================================================
// KERNUN Colors
// ===========================================================================

// Green visual
// default white color
$white: #ffffff;
// is used for widgets with boolean: true, success and notice me elements, VERY occasionally for styling (logo and icons)
$primary-green: #6c801d;
// is used for styling and primary for MUI widgets
$primary-green-dark: #273527;
// default black color
$black: #000000;
// is used as styling color with green elements
$primary-black: #1c1c1a;
// is used as neutral action color and styling color
$secondary-brown: #5c4732;
// is used as decorative color just for styling
$secondary-green-light: #e0e3d3;
// is used as background color for scenes and tables
$secondary-green-white: #f6f7f3;
// is used for styling
$secondary-green-dark: #d3ded3;


$white-85: rgba($white, 0.85);
$white-70: rgba($white, 0.7);
$white-50: rgba($white, 0.5);
$white-light: $white-70;

$black-light: #1a1a18;
$black-50: rgba($black, 0.5);
$black-10: rgba($black, 0.1);
$black-20: rgba($black, 0.2);
$black-30: rgba($black, 0.3);
$black-16: rgba($black, 0.16);
$black-07: rgba($black, 0.07);
$black-03: rgba($black, 0.03);

$grey-xdark: #372d23;
$grey-xdark-05: rgba($grey-xdark, 0.05);
$grey-xdark-10: rgba($grey-xdark, 0.1);
$grey-xdark-15: rgba($grey-xdark, 0.15);
$grey-xdark-30: rgba($grey-xdark, 0.3);
$grey-xdark-50: rgba($grey-xdark, 0.5);
$grey: #e7ded5;
$grey-off: #9e9e9e;
$grey-off-light: #d3d3d3;
$grey-70: rgba($grey, 0.70);
$grey-category: #e5e2de;
$grey-category-70: rgba($grey-category, 0.7);

// $grey-medium: #dadce0;
$grey-medium: #dedbd8; // =$secondary-color-20
$grey-light: #efebe7;
$grey-light-50: rgba($grey-light, 0.5);
$grey-light-50-in-rgb: rgb(247,245,243);

$brown-dark: #584a3c;
$brown-xdark: #221203;
$brown-dark-85: rgba($brown-dark, 0.85);
$brown-dark-70: rgba($brown-dark, 0.7);
$brown-dark-50: rgba($brown-dark, 0.5);
$brown-dark-20: rgba($brown-dark, 0.2);
$brown-dark-10: rgba($brown-dark, 0.1);

$green-45: rgba($primary-green, 0.45);
$green-15: rgba($primary-green, 0.15);

$accept-green: #24d168;


$reject-red: #cb0000;

$dark-olive-green: rgb(85, 107, 47);

$red: #d42020;
$red-15: rgba($red, 0.15);
$red-30: rgba($red, 0.3);
$red-45: rgba($red, 0.45);



$dark-red: rgb(139, 0, 0);

$yellow: #ebc000;
$yellow-dark: #c5a204;
$yellow-70: rgba($yellow, 0.7);
$yellow-15: rgba($yellow, 0.15);

$gold: #ffd700;

$skeleton-color: #dddbdd;
$skeleton-shine-color: rgba(229,229,229,0.8);

$light-blue: #add8e6;

// MDB Colors
// ===========================================================================

// Material colors
$primary-color: $primary-green;
$primary-color-10: rgba($primary-color, 0.1);
$primary-color-30: rgba($primary-color, 0.3);
$primary-color-60: rgba($primary-color, 0.6);
$primary-color-dark: $primary-green-dark;
$secondary-color: $secondary-brown;
$secondary-color-dark: $black-light;
$default-color: $grey-xdark;
$default-color-dark: $primary-black;

// $info-color:           #33b5e5;
// $info-color-dark:      #0099cc;
$success-color: $primary-green;

// $success-color-dark:   #007e33;
$warning-color: $yellow;
$warning-color-30: rgba($yellow, 0.3);;


// $warning-color-dark:   #ff8800;
$danger-color: $red;

// $danger-color-dark:    #cc0000;
// $elegant-color:        #2e2e2e;
// $elegant-color-dark:   #212121;
// $stylish-color:        #4b515d;
// $stylish-color-dark:   #3e4551;
// $unique-color:         #3f729b;
// $unique-color-dark:    #1c2331;
// $special-color:        #37474f;
// $special-color-dark:   #263238;
$error-color: $red;

$white-base: $white-light;
$black-base: $black-light;

// Kernun system design color variables

$secondary-color-70: rgba($secondary-color, 0.7);
$secondary-color-50: rgba($secondary-color, 0.5);
$secondary-color-40: rgba($secondary-color, 0.4);
$secondary-color-30: rgba($secondary-color, 0.3);
$secondary-color-20: rgba($secondary-color, 0.2);
$secondary-color-10: rgba($secondary-color, 0.1);
$secondary-color-07: rgba($secondary-color, 0.07);
$secondary-color-03: rgba($secondary-color, 0.03);

$box-shadow-color: $black-20;
$box-shadow-color-hover: $brown-dark-20;

$default-dark: $brown-dark;
$default-bg-light: $secondary-green-white;

$font-color: $grey-xdark;

$placeholder-color: $secondary-color-50;

$gradient-primary: linear-gradient(to left, rgba($primary-green, 0) 0%, rgba($primary-green, 1) 100%);
$gradient-shadow-right: linear-gradient(to right, rgba($black-light, 0) 0%, rgba($black-light, 0.1) 100%);

// BOOTSTRAP COLORS
