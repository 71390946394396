.table {
    width: 100%;
    flex: 1;
    // overflow: auto;
}

.tableHead__row {
    border-bottom: $border-bottom;
    display: flex;
    justify-content: space-between;
    height: $grid-gutter * 3.5;
}

.tableHead__activities {
    display: flex;
    padding: $grid-gutter * 1.125 $grid-gutter $grid-gutter * 0 $grid-gutter * 1.5;
}

.tableHead__heading {
    color: $secondary-color-70;
    margin-bottom: 0;
    font-size: $grid-gutter * 1.125;

}

.tableHead__time {
    display: flex;
    padding: $grid-gutter * 1.125 $grid-gutter $grid-gutter * 0.875 $grid-gutter * 1.5;
}

.tableHead__sorting {
    display: flex;
    flex-direction: column;
    font-size: $grid-gutter * 0.75;
    margin-left: $grid-gutter * 0.75;

    & button {
        background-color: $white;
        border: none;
        height: $grid-gutter * 3;
        padding: 0;
        margin: $grid-gutter * 0.125;
    }

    .tableHead__triangleUp {
        width: 0;
        height: 0;
        border-left: $grid-gutter * 0.5 solid transparent;
        border-right: $grid-gutter * 0.5 solid transparent;
        border-bottom: $grid-gutter * 0.675 solid $grey-xdark-15;
    }

    .tableHead__triangleDown {
        width: 0;
        height: 0;
        border-left: $grid-gutter * 0.5 solid transparent;;
        border-right: $grid-gutter * 0.5 solid transparent;;
        border-top: $grid-gutter * 0.675 solid $grey-xdark-15;
    }
}

.tableBody__container {
    padding: 0 $grid-gutter * 1.5;
}

.tableBody__noData {
    text-align: center;
}

.tableBody__tableItem {
    display: flex;
    justify-content: space-between;
    padding: 0 $grid-gutter * 0.675;
}

.tableBody__cell {
    display: flex;

    &--noData {
        width: 100%;
    }
}

.tableBody__cellIcon {
    margin: 0 $grid-gutter * 0.75;
}

