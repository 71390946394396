.numberWidget {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    .number {
        width: calc(50% - #{$grid-gutter});
        text-align: left;
        &--ver {
            width: auto ;
        }
    }

    &__loader {
        background: rgba(255,255,255,0.8);
        height: calc(100% - #{$grid-gutter} * 3);
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

.numberWidgetMaxHight {
    max-height: $grid-gutter * 16;
    min-height: $grid-gutter * 16;

}
