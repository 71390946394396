h1, h2, h3, h4, h5, h6 {
    color: $secondary-color;

    &.title--light {
        color: $grey-light-50;
        font-weight: 400;
    }

    @include bp-min(xl) {
        max-width: $grid-gutter * 50;
    }
}

.button {

    &--withoutBorder {
        border-width: 0 !important; //stylelint-disable-line
    }
}

p {
    margin-bottom: $grid-gutter * 2;

    &.italic {
        font-style: italic;
    }

    @include bp-min(xl) {
        max-width: $grid-gutter * 50;
    }

    a {
        color: $primary-color;
    }
}

span {

    &.error {
        color: $error-color;
    }

    &.success {
        color: $success-color;
    }

    &.warning {
        color: $warning-color;
    }

    &.italic {
        font-style: italic;
    }
}

h1 {
    font-size: $grid-gutter * 2; // =32px
    line-height: 1.25; // =40px
    font-weight: 600;

    & + p {
        font-size: $grid-gutter; // =16px
        line-height: 1.75; // =28px
    }

    .handbook & + p {
        font-size: $grid-gutter;
        line-height: 1.6667;
    }
}

h2 {
    font-size: $grid-gutter * 1.5; // =24px
    line-height: 1.25; // =30px
    font-weight: 600;

    & + p {
        font-size: $grid-gutter * 0.875; // =14px
        line-height: 1.642; // =23px
    }

    .handbook & + p {
        font-size: $grid-gutter;
        line-height: 1.6667;
    }
}

h3 {
    font-size: $grid-gutter * 1.125; // =18px
    line-height: 1.3333; // =24px
    font-weight: 600;

    & + p {
        font-size: $grid-gutter * 1; // =16px
        line-height: 1.6667; // =20px
    }

    .handbook & + p {
        font-size: $grid-gutter;
        line-height: 1.6667;
    }


}

h4 {
    font-size: $grid-gutter; // =16px
    line-height: 1.25; // =24px
    font-weight: 500;

    & + p {
        font-size: $grid-gutter * 0.6875; // =11px
        line-height: 1.636; // =18px
    }

    .handbook & {
        font-weight: 600;

        & + p {
            font-size: $grid-gutter;
            line-height: 1.6667;
        }
    }
}

h5 {
    font-size: $grid-gutter * 0.875; // =14px
    line-height: 1.286; // =18px
    font-weight: 500;

    & + p {
        font-size: $grid-gutter * 0.6875; // =11px
        line-height: 1.636; // =18px
    }
}

h6 {
    font-size: $grid-gutter * 0.75; // =12px
    line-height: 1.3333; // =16px
    font-weight: 500;

    & + p {
        font-size: $grid-gutter * 0.625; // =10px
        line-height: 1.5; // =15px
    }
}

pre {
    background-color: $grey-light;
    font-family: IBM Plex Mono, monospace;
    font-size: $grid-gutter * 0.75;
    line-height: $grid-gutter * 0.9375;
    border-radius: $border-radius;
    max-height: 30vh;
    counter-reset: line;
    padding: ($grid-gutter * 0.75) $grid-gutter;

    & > span {
        display: block;

        &::before {
            counter-increment: line;
            content: counter(line);
            padding-right: $grid-gutter * 2;
        }
    }
}

// number list

.text-page ol {
    margin-left: $grid-gutter * 0.625;
    list-style-type: none;
    counter-reset: number;
    font-size: $grid-gutter * 0.875;
    margin-bottom: $grid-gutter * 1.125;

    & > li {
        padding-left: $grid-gutter * 0.625;
        margin-bottom: $grid-gutter * 0.875;
        position: relative;
        line-height: $grid-gutter * 1.4375;
        font-size: $grid-gutter * 0.875;
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            left: -$grid-gutter * 1.25;
            top: $grid-gutter * 0.125;
            width: $grid-gutter * 1.25;
            height: $grid-gutter * 1.25;
            border-radius: 50%;
            border: ($grid-gutter * 0.0625) solid $secondary-color;
        }

        &::after {
            position: absolute;
            top: $grid-gutter * 0.0625;
            left: -$grid-gutter * 0.8125;
            counter-increment: number;
            content: counter(number);
            font-size: $grid-gutter * 0.875;
        }
    }
}

h3 + ol {
    margin-left: $grid-gutter * 0.625;
    list-style-type: none;
    counter-reset: number;
    font-size: $grid-gutter * 0.75;
    line-height: $grid-gutter * 1.875;
    margin-bottom: $grid-gutter;

    & > li {
        padding-left: $grid-gutter * 0.625;
        margin-bottom: $grid-gutter * 0.75;
        position: relative;
        font-size: $grid-gutter * 0.75;
        line-height: $grid-gutter * 1.25;
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            left: -$grid-gutter * 1.25;
            top: 0;
            width: $grid-gutter * 1.125;
            height: $grid-gutter * 1.125;
            border-radius: 50%;
            border: ($grid-gutter * 0.0625) solid $secondary-color;
        }

        &::after {
            position: absolute;
            top: 0;
            left: -$grid-gutter * 0.875;
            counter-increment: number;
            content: counter(number);
            font-size: $grid-gutter * 0.75;
        }
    }
}

// bullet list

h2 + ul {
    margin-left: $grid-gutter * 0.625;
    list-style-type: none;
    counter-reset: number;
    font-size: $grid-gutter * 0.875;
    line-height: $grid-gutter * 1.875;
    margin-bottom: $grid-gutter * 1.125;

    & > li {
        padding-left: $grid-gutter * 0.625;
        margin-bottom: $grid-gutter * 0.875;
        position: relative;
        font-size: $grid-gutter * 0.875;
        line-height: $grid-gutter * 1.4375;
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            left: -$grid-gutter;
            top: $grid-gutter * 0.25;
            width: $grid-gutter * 0.875;
            height: $grid-gutter * 0.875;
            border-radius: 50%;
            border: ($grid-gutter * 0.125) solid $grey-xdark-50;
        }
    }
}

h3 + ul,
.list--small {
    padding-left: $grid-gutter;
    margin-left: $grid-gutter * 0.625;
    list-style-type: none;
    counter-reset: number;
    font-size: $grid-gutter * 1.125;
    margin-bottom: $grid-gutter;

    & > li {
        padding-left: $grid-gutter * 0.625;
        margin-bottom: $grid-gutter * 0.75;
        position: relative;
        font-size: $grid-gutter * 0.75;
        line-height: $grid-gutter * 1.25;
        font-weight: 500;

        &::before {
            content: "";
            position: absolute;
            left: -$grid-gutter * 0.875;
            top: $grid-gutter * 0.25;
            width: $grid-gutter * 0.625;
            height: $grid-gutter * 0.625;
            border-radius: 50%;
            border: ($grid-gutter * 0.125) solid $grey-xdark-50;
        }
    }
}

// zanořený seznam
ol ul > li {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: -$grid-gutter;
        top: $grid-gutter * 0.25;
        width: $grid-gutter * 0.875;
        height: $grid-gutter * 0.875;
        border-radius: 50%;
        border: ($grid-gutter * 0.125) solid $grey-xdark-50;
        background-color: $white;
    }
}

ul ol > li {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: -$grid-gutter * 1.25;
        top: 0;
        width: $grid-gutter * 1.125;
        height: $grid-gutter * 1.125;
        border-radius: 50%;
        border: ($grid-gutter * 0.0625) solid $secondary-color;
        background-color: $white;
    }

    &::after {
        position: absolute;
        top: 0;
        left: -$grid-gutter * 0.875;
        counter-increment: number;
        content: counter(number);
        font-size: $grid-gutter * 0.75;
    }
}

.infobox {
    font-size: $grid-gutter * 0.75;
    border-radius: $border-radius;
    padding: $grid-gutter $grid-gutter $grid-gutter ($grid-gutter * 2.5);
    align-items: center;

    & > span.headerInfobox {
        font-size: $grid-gutter * 1;
        font-weight: 400;
        line-height: 1.25;

        &::after {
            content: "\a";
            white-space: pre;
        }
    }

    &--info {
        background-color: $secondary-color-10;
        border: ($grid-gutter * 0.0625) solid $secondary-color;
        position: relative;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23584a3c;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M9.2,7.6h1.6V6H9.2M10,16.4A6.4,6.4,0,1,1,16.4,10,6.408,6.408,0,0,1,10,16.4M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8M9.2,14h1.6V9.2H9.2Z' transform='translate(-2 -2)'/%3E%3C/svg%3E");
            position: absolute;
            left: $grid-gutter * 0.8125;
            top: $grid-gutter * 1.2;
            width: $grid-gutter;
            height: $grid-gutter;
        }
    }

    &--primary {
        background-color: $secondary-green-white;
        border: ($grid-gutter * 0.0625) solid $primary-green;
        position: relative;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%236C801D;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M9.2,7.6h1.6V6H9.2M10,16.4A6.4,6.4,0,1,1,16.4,10,6.408,6.408,0,0,1,10,16.4M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8M9.2,14h1.6V9.2H9.2Z' transform='translate(-2 -2)'/%3E%3C/svg%3E");
            left: 13px;
            position: absolute;
            top: $grid-gutter * 1.2;
            left: $grid-gutter * 0.8125;
            width: $grid-gutter;
            height: $grid-gutter;
        }
    }
    &--warning {
        background-color: $warning-color-30;
        border: ($grid-gutter * 0.0625) solid $warning-color;
        position: relative;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23ebc000;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M9.2,7.6h1.6V6H9.2M10,16.4A6.4,6.4,0,1,1,16.4,10,6.408,6.408,0,0,1,10,16.4M10,2a8,8,0,1,0,8,8,8,8,0,0,0-8-8M9.2,14h1.6V9.2H9.2Z' transform='translate(-2 -2)'/%3E%3C/svg%3E");
            left: 13px;
            position: absolute;
            top: $grid-gutter * 1.2;
            left: $grid-gutter * 0.8125;
            width: $grid-gutter;
            height: $grid-gutter;
        }
    }

    &--success {
        background-color: $green-15;
        border: ($grid-gutter * 0.0625) solid $primary-green;
        position: relative;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.5' height='13.41' viewBox='0 0 17.5 13.41'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%236C801D;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M21,7,9,19,3.5,13.5l1.41-1.41L9,16.17,19.59,5.59Z' transform='translate(-3.5 -5.59)'/%3E%3C/svg%3E");
            position: absolute;
            top: $grid-gutter * 1.2;
            left: $grid-gutter * 0.8125;
            width: $grid-gutter;
            height: $grid-gutter;
        }
    }

    &--alert {
        background-color: $red-15;
        border: ($grid-gutter * 0.0625) solid $red;
        position: relative;

        &::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='19' viewBox='0 0 22 19'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23d42020;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M12,2,1,21H23M12,6l7.53,13H4.47M11,10v4h2V10m-2,6v2h2V16' transform='translate(-1 -2)'/%3E%3C/svg%3E");
            top: 11px;
            position: absolute;
            top: $grid-gutter * 1.2;
            left: $grid-gutter * 0.625;
            width: $grid-gutter;
            height: $grid-gutter;
        }
    }

    &--keepSpace {
        white-space: pre;
        overflow: auto;
    }
}

.bold {
    font-weight: 700;
}
