.categories {

    &__zone {
        padding: 0;
    }

    &__superCategory {
        padding: 3px;
    }

    &__red {
        background-color: $red-45;

        &--header {
            border-top: 4px $red solid;
        }
    }

    &__gray {
        background-color: $grey-category-70;

        &--header {
            border-top: 4px $grey-category solid;
        }
    }

    &__green {
        background-color: $green-45;

        &--header {
            border-top: 4px $primary-green solid;
        }
    }

    &__Title {
        font-size: 13px;
        font-weight: 300;
        line-height: 30px;
        box-shadow: 0 5px 4px -2px $black-30;
    }

    &__Category {
        background-color: $white;
        border-radius: 2px;
        margin-top: 4px;
        margin-bottom: 4px;
        line-height: 30px;
    }

    &__More {
        display: flex;
        justify-content: center;
        background-color: $secondary-green-light;

        &__background {
            background-color: $white !important; //stylelint-disable-line
        }
    }

    &__Radio {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;

        .CategoriesSwitch {
            display: flex;
            width: 28px;
            margin: 0;
            flex-direction: unset;
            min-height: unset;
            padding-right: 3px;
        }
    }

    &--unset {
        min-width: unset;
        height: unset;
        border-radius: unset;
    }

}