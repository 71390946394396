.form {
    &-inline {
        .md-form,
        .form-group {
            margin-bottom: 0;
        }
    }

    &__column {
        min-width: 11 * $grid-gutter;
    }
}

.form-group {
    // margin-bottom: $grid-gutter * 1.75;
    // margin-top: $grid-gutter * 2;
    margin-bottom: $grid-gutter * 1.75;
    margin-top: $grid-gutter * 0.8;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    min-height: $input-height;
    justify-content: flex-end;

    &--withoutMargin {
        margin-bottom: 0;
        margin-top: 0;
    }

    &--dark {
        margin-top: 0;
        margin-bottom: $grid-gutter * 3;
    }

    &--radio {
        margin-top: 0;
    }

    i + i {
        margin-top: 2 * $grid-gutter;
    }
}
