//IMPORTED STYLES FROM NOTIFICATION

.notifications-component {
    position: fixed;
    z-index: $z-index-notification;
    pointer-events: none;
    width: 100%;
    height: 100%;
}

.notification {

    &__item {
        display: flex;
        position: relative;
        border-radius: 0.1875 * $grid-gutter;
        margin-bottom: $grid-gutter;
        box-shadow: 0.0625 * $grid-gutter 0.1875 * $grid-gutter 0.25 * $grid-gutter rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    &__timer {
        width: 100%;
        height: 0.1875 * $grid-gutter;
        margin-top: 0.625 * $grid-gutter;
        border-radius: 0.3125 * $grid-gutter;

        &-filler {
            height: 0.1875 * $grid-gutter;
            border-radius: 0.3125 * $grid-gutter;
        }
    }

    &__title {
        color: $white;
        font-weight: 700;
        font-size: 0.875 * $grid-gutter;
        margin-top: 0.3125 * $grid-gutter;
        margin-bottom: 0.3125 * $grid-gutter;
    }


    &__message {
        color: $white;
        max-width: calc(100% - $grid-gutter);
        font-size: 0.875 * $grid-gutter;
        line-height: 150%;
        word-wrap: break-word;
        margin-bottom: 0;
        margin-top: 0;
    }

    &__content {
        padding: 0.5 * $grid-gutter $grid-gutter;
        display: inline-block;
        width: 100%;
    }

    &__close {
        width: $grid-gutter;
        height: $grid-gutter;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        right: 0.625 * $grid-gutter;
        top: 0.625 * $grid-gutter;

        &::after {
            content: "\D7";
            position: absolute;
            transform: translate(-50%, -50%);
            color: $white;
            font-size: 0.75 * $grid-gutter;;
            left: 50%;
            top: 50%;
        }
    }
}

.notification-container--top-full .notification__item,
.notification-container--bottom-full .notification__item {
    margin-bottom: 0;
    border-radius: 0;
}

.notification-container--mobile-top .notification__item,
.notification-container--mobile-bottom .notification__item,
.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
    max-width: 100%;
    width: 100%;
}

.notification-container--top-right .notification,
.notification-container--bottom-right .notification {
    margin-left: auto;
}

.notification-container--top-left .notification,
.notification-container--bottom-left .notification {
    margin-right: auto;
}

.notification-container--mobile-top .notification,
.notification-container--mobile-bottom .notification {
    margin-left: auto;
    margin-right: auto;
}


.notification__item--default {
    background-color: $primary;
    border-left: 0.5 * $grid-gutter solid $primary-color-dark;

    .notification__timer {
        background-color: $primary;
    }
    .notification__timer-filler {
        background-color: $reject-red;
    }
    .notification__close {
        background-color: $white;
    }
}

.notification__item--success {
    background-color: $primary-green;
    border-left: 0.5 * $grid-gutter solid $secondary-brown;

    .notification__timer {
        background-color: $primary-green;
    }
    .notification__timer-filler {
        background-color: $white;
    }
    .notification__close {
        background-color: $accept-green;
    }
}

.notification__item--danger {
    background-color: $red;
    border-left: 0.5 * $grid-gutter solid $dark-red;

    .notification__timer {
        background-color: $red;
    }
    .notification__timer-filler {
        background-color: $white;
    }
    .notification__close {
        background-color: $red;
    }
}

.notification__item--info {
    background-color: $grey-xdark;
    border-left: 0.5 * $grid-gutter solid $brown-xdark;

    .notification__timer {
        background-color: $grey-xdark;
    }
    .notification__timer-filler {
        background-color: $white;
    }
    .notification__close {
        background-color: $black;
    }
}

.notification__item--warning {
    background-color: $yellow;
    border-left: 0.5 * $grid-gutter solid $yellow-dark;

    .notification__timer {
        background-color: $yellow;
    }
    .notification__timer-filler {
        background-color: $white;
    }
    .notification__close {
        background-color: $warning-color;
    }
}

.notification-container--top-center,
.notification-container--top-left,
.notification-container--top-right,
.notification-container--bottom-center,
.notification-container--bottom-left,
.notification-container--bottom-right,
.notification-container--center,
.notification-container--top-full,
.notification-container--bottom-full {
    min-width: 20.3125 * $grid-gutter;;
    position: absolute;
    pointer-events: all;
}


.notification-container--bottom-right {
    bottom: 20px;
    right: 20px;
}

.notification-container--mobile-top,
.notification-container--mobile-bottom {
    pointer-events: all;
    position: absolute;
}

.notification-container--mobile-top {
    right: 20px;
    left: 20px;
    top: 20px;
}

.notification-container--mobile-bottom {
    right: 20px;
    left: 20px;
    bottom: 20px;
    margin-bottom: -15px;
}

@keyframes timer {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
