.slider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 $grid-gutter;

    &__icon {
        margin-top: $grid-gutter;
        cursor: pointer;

        &--color {
            color: $grey-xdark-50;
        }

        &--up {
            transform: rotate(180deg);
        }
    }

    &__div {
        box-shadow: $box-shadow-card;
        padding: $grid-gutter;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        display: none;
        position: absolute;
        top: 85%;
        left: 20%;
        z-index: $z-index-5;
        background-color: $white;

        &--display {
            display: block;
        }
    }

    & label {
        @include label;

        &.active {
            transform: translateY(-20px) scale(0.857);
        }
    }

    .input-range__slider {
        background: $secondary-color;
        border: 1px solid $secondary-color;
    }

    .input-range__track--active {
        background: $secondary-color;
    }
}
