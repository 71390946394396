


.skeleton {
    &--input {
        height: $input-height * 0.7;
        width: 100%;
        background-color: $skeleton-color;
        border-radius: $input-border-radius;
        margin: 0.5 * $grid-gutter 0;
        position: relative;
        overflow: hidden;

        &::after {
            @include background-gradient;
        }

        &--area {
            height: 5 * $grid-gutter;
        }
    }

    &--table {
        height: 100%;
        max-height: 85vh;
        min-height: 40vh;
        width: 100%;
        background-color: $skeleton-color;
        border-radius: $input-border-radius;

        &::after {
            @include background-gradient;
        }
    }

    &--text {
        min-width: 5 * $grid-gutter;
        height: $grid-gutter;
        width: 100%;
        background-color: $skeleton-color;
        border-radius: $input-border-radius;
        position: relative;
        overflow: hidden;

        &::after {
            @include background-gradient;
        }

        &--10 {
            width: 10%;
        }

        &--30 {
            width: 30%;
        }

        &--60 {
            width: 60%;
        }

    }



    &--avatar {
        width: $radio-size * 2.5;
        height: $radio-size * 2.5;
        background-color: $skeleton-color;
        border-radius: 50%;
        position: relative;
        overflow: hidden;

        &::after {
            @include background-gradient;
        }

        &--10 {
            width: 10%;
        }

        &--30 {
            width: 30%;
        }

        &--60 {
            width: 60%;
        }
    }
}

.display--flex {
    display: flex;
}