.withOverlay {
    color: $skeleton-color;

    .form-group {
        .form-control {
            color: $skeleton-color;

            &::placeholder {
                color: transparent;
            }

            & ~ label {
                color: $skeleton-color;
            }

            & ~ label.active {
                transform: translateY(13px);
                color: $skeleton-color;
                z-index: $z-index;
            }

            &__eye {
                display: none;
            }
        }

        .select__placeholder {
            color: transparent;
        }
    }

    .switch__label {
        display: none;
    }

    .switch__name {
        transform: unset;
        padding: 0.5 * $grid-gutter 0.75 * $grid-gutter;
        background: unset;
        color: $skeleton-color;
    }

    .icon {
        color: $skeleton-color;

        &::after {
            @include background-gradient;
        }

        &--locked {
            color: $grey-xdark;
        }
    }

    .card-title {
        color: $skeleton-color;

    }

    span {
        color: $skeleton-color;
    }

    h2 {
        color: $skeleton-color;
    }

    h3 {
        color: $skeleton-color;
    }

    .MuiTableCell-head {
        color: $skeleton-color;
    }

    .dataTableWidget__RowFirstRuleRow {
        color: $skeleton-color;
    }

    .dataTableWidget__RowAddButtons--button {
        background-color: $skeleton-color;
    }

    .infobox {
        border-color: $skeleton-color;

        &--info::before {
            display: none;
        }
    }

    .select__multi-value {
        display: none;
    }

    .select__single-value {
        display: none;
    }

    .cluster__connection {
        display: none;
    }

    .userNavigation {
        display: none;
    }

    div .overlay--active {
        transform: scaleY(0);
    }
}


.overlay {
    position: fixed;
    top: $header-height;
    left: $leftMenu-width;
    height: 100%;
    width: calc(100% - $leftMenu-width);
    background-color: $brown-dark-85;
    transition-property: transform;
    transition-timing-function: cubic-bezier(1, 0, 0, 1);
    transition-duration: $transition-time;
    transform: scaleY(0);
    transform-origin: top left;
    overflow: auto;
    z-index: $z-index-3;
    transition: left $transition-global, width $transition-global;

    &--active {
        transform: scaleY(1);
        z-index: $z-index-overlay;
    }

    .overlay--active {
        transform: scaleY(0);

    }

    &--absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    h1 {
        color: $white;
    }

    h2 {
        color: $white;
    }

    h3 {
        color: $white;
    }

    h4 {
        color: $white;
    }

    p {
        color: $white;
    }

    &--div {
        position: relative;
        margin-bottom: 2 * $grid-gutter;
        min-height: 25 * $grid-gutter;
    }

    &--card {
        border-radius: $border-radius;
    }

    &__text {
        padding: 13 * $grid-gutter 5 * $grid-gutter;
    }

    &--secondary {
        background-color: $secondary-color-50;
    }

    &--secondary-40 {
        background-color: $secondary-color-40;
    }

    &--closedMenu {
        left: $leftMenu-width-collapsed;
        width: calc(100% - $leftMenu-width-collapsed);
    }

    &--differ {

        .dhcpd__overlay {
            max-width: calc(100% - 25 * $grid-gutter ); //differ
        }
    }

    &--help {

        .dhcpd__overlay {
            max-width: calc(100% - 31.5 * $grid-gutter ); //help
        }

        &--differ {
            .dhcpd__overlay {
                max-width: calc(100% - 25 * $grid-gutter - 31.5 * $grid-gutter); //differ and help
            }
        }
    }
}

