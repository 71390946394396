.syslogTable {
    table-layout: fixed;
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.syslogTableHeader {
    height: 50px;
}

.syslogHeader {
    position: sticky;
    top: 0;
    background-color: $white;
    padding: 8px 12px 8px 12px;
    font-size: 1 * $grid-gutter;;
    font-weight: 500;
    line-height: 1.5;
    z-index: 10;

    &--sm {
        width: 70px;
    }

    &--md {
        width: 150px;
    }

    &--lg {
        width: 200px;
    }
}

.syslogWrapper {
    overflow-y: scroll;

    &Stable {
        scrollbar-gutter: stable;
        overflow-anchor: none;
        min-height: 0;
        height: 100%;
    }
}

.syslogCardBody {
    display: grid;
    grid-template-rows: max-content;
}

.syslogRow {
    min-height: 0;
}

.syslogCol {
    height: 100%;
}

.syslogTableCell {

    &__background {
        text-align: center;
        &--red {
            background-color: $red;
        }
        
        &--yellow {
            background-color: $yellow;
        }
        
        &--grey-off {
            background-color: $grey-off-light;
        }
        
        &--secondary-green-dark {
            background-color: $secondary-green-dark;
        }
        
        &--white {
            background-color: $white;
        }

        &--light-blue {
            background-color: $light-blue;
        }
    }
}

.loadingCenter {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    height: 40px;
}

.systemMessage {
    
    &--hidden {
        white-space: nowrap;
        overflow-x: hidden;
    }

    &--scroll {
        white-space: nowrap;
        overflow-x: scroll;
    }
}

.messageInput {
    padding-right: (7.5 * $grid-gutter)!important; // stylelint-disable-line
}

.timeInputs {
    min-width: 12 * $grid-gutter;
}

.sysLogCard {
    overflow: hidden;
    max-height: 1020px;
}

.logFilter {

    &--formGroupMargins {
        margin-top: 0.8 * $grid-gutter;
        display: flex;
        justify-content: space-between;
    }

}

.userFilterBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #c5aab7;
    padding: 5px;
    border-radius: 8px;
    margin: 0.25 * $grid-gutter;
    height: 2 * $grid-gutter;

    &--default {
        background-color: $light-blue;
    }
}

.chips {
    border: 2px solid rgba(88, 74, 60, 0.2);
    border-radius: 0.25 * $grid-gutter;
    display: flex;
    justify-content: space-around;
    min-height: 2.375 * $grid-gutter;
    margin-top: 0.8 * $grid-gutter;
    align-items: center;

    &--icon {
        height: 100%;
    }
}