@use "sass:math";

.input-range__slider {
    appearance: none;
    background: $input-range-slider-background;
    border: $input-range-slider-border;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: $input-range-slider-height;
    //changed based on deprecation warning, more info in index.scss
    margin-left: math.div($input-range-slider-width, -2);
    //changed based on deprecation warning, more info in index.scss
    margin-top: math.div($input-range-slider-height, -2) + math.div($input-range-track-height, -2);
    outline: none;
    position: absolute;
    top: 50%;
    transition: $input-range-slider-transition;
    width: $input-range-slider-width;

    &:active {
        transform: $input-range-slider-active-transform;
    }

    &:focus {
        box-shadow: 0 0 0 $input-range-slider-focus-box-shadow-radius $input-range-slider-focus-box-shadow-color;
    }

    .input-range--disabled & {
        background: $input-range-slider-disabled-background;
        border: $input-range-slider-disabled-border;
        box-shadow: none;
        transform: none;
    }
}

.input-range__slider-container {
    transition: $input-range-slider-container-transition;
}
