
.differs {
    order: 2;
    height: 100%;
    background-color: $default-bg-light;
    z-index: $z-index-3;
    width: 25rem;
    transition: all 5000ms linear;
    flex: 0.00001;
    animation: flexGrow 5000ms ease forwards;

    &__activate {
        width: 22 * $grid-gutter;

        .modal & {
            display: none;
        }

        &Buttons {
            display: flex;
            padding-top: $grid-gutter;
        }
    }


    &__Card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        box-shadow: 0 3px 6px rgb(88 74 60 / 10%);

        &Headers.scrollMenu__item {
            min-width: 11 * $grid-gutter;
            display: flex;
            align-items: center;
            padding: 0.5 * $grid-gutter 0.7 * $grid-gutter;
        }

        &Headers.scrollMenu__number {
            top: 12%;
        }
    }

    &__path {
        padding: 0 $grid-gutter $grid-gutter $grid-gutter;
    }

    &__error {
        box-shadow: 0 3px 6px rgb(88 74 60 / 10%);
        border-radius: 0.25rem;
        box-sizing: border-box;
        transition-property: box-shadow, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in;
        height: 100%;
        max-height: 85vh;
        background-color: $white;
        margin-bottom: 0.4 * $grid-gutter;
        overflow-wrap: anywhere;

        &__title {
            font-size: 1 * $grid-gutter;
            padding: 0.5* $grid-gutter;
            color: $secondary-color-70;
            margin: 0;
            border-bottom: 0.0625rem solid rgba(88, 74, 60, 0.1);
            max-width: initial;
        }

        &__desc {
            padding: $grid-gutter;
        }

        &__path {
            display: flex;
            flex-direction: row;
            justify-content: start;
            flex-wrap: wrap;
            font-size: 0.875 * $grid-gutter;
            margin-top: 0.5 * $grid-gutter;

            .differs__differ & {
                font-size: 0.75 * $grid-gutter;
            }

            &:first-of-type {
                margin-top: 0;
            }

            &Item {
                margin-left: 0.25 * $grid-gutter;
                margin-right: 0.25 * $grid-gutter;

                &--name {
                    font-weight: 500;
                    &--active {
                        color: $primary;
                        cursor: pointer;
                    }
                }

                &--slash {
                    color: $secondary-color-30;
                }

                &--default {
                    font-style: italic;
                }
            }
        }
    }

    &__differ {
        box-shadow: 0 3px 6px rgb(88 74 60 / 10%);
        border-radius: 0.25rem;
        box-sizing: border-box;
        transition-property: box-shadow, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in;
        height: 100%;
        background-color: $white;
        margin-bottom: 0.4 * $grid-gutter;
        overflow-wrap: anywhere;
        position: relative;

        &__title {
            font-size: 1 * $grid-gutter;
            padding: 0.5* $grid-gutter;
            color: $secondary-color-70;
            margin: 0;
            border-bottom: 0.0625rem solid rgba(88, 74, 60, 0.1);
            max-width: initial;
        }

        &__desc {
            padding: 0.5 * $grid-gutter $grid-gutter;
        }

        &__icon {
            color: $secondary-color-20;
            transform: rotate(-90deg);
            position: absolute;
            top: -1.3 * $grid-gutter;
            left: -2%;
        }

        &__typeIcon {
            color: $secondary-color-10;
            position: absolute;
            top: 50%;
            right: 0;
        }

        &__new {
            display: flex;
            align-items: baseline;
        }

        &__name {
            position: absolute;
            left: -8%;
            display: none;
            bottom: 0;

            &--text {
                writing-mode: vertical-lr;
                transform: rotate(-180deg);
                font-size: 1.125 * $grid-gutter;
                color: $primary;
            }
        }

        &:hover {
            box-shadow: 0 3px 6px $primary-color-30;

            .differs__differ__name {
                display: inline;
            }
        }
    }

    &__without {
        text-align: center;
        font-size: 1.25 * $grid-gutter;
        margin-top: 2 * $grid-gutter;
        color: $secondary-color;
    }

    &__header {
        padding-top: 0.2 * $grid-gutter;
        padding-bottom: 0.2 * $grid-gutter;
        cursor: pointer;
        background-color: $secondary-color-07;
    }


    &__changes {
        overflow: auto;
        max-height: calc(100vh - 14.5 * $grid-gutter);

        .modal & {
            max-height: calc(100vh - 22.5 * $grid-gutter);
            min-height: 36 * $grid-gutter;
        }

        //keep in sync with orderOfScene
        & > .differs__interfaces {
            display: block;
            position: relative;
        }

        & > .differs__interfaces ~ .differs__interfaces {
            display: none;
        }

        & > .differs__network {
            display: block;
            position: relative;
        }

        & > .differs__network ~ .differs__network {
            display: none;
        }

        & > .differs__system {
            display: block;
            position: relative;
        }

        & > .differs__system ~ .differs__system {
            display: none;
        }

        & > .differs__cluster {
            display: block;
            position: relative;
        }

        & > .differs__cluster ~ .differs__cluster {
            display: none;
        }

        & > .differs__upgrade {
            display: block;
            position: relative;
        }

        & > .differs__upgrade ~ .differs__upgrade {
            display: none;
        }

        & > .differs__dashboards {
            display: block;
            position: relative;
        }

        & > .differs__dashboards ~ .differs__dashboards {
            display: none;
        }

        & > .differs__services {
            display: block;
            position: relative;
        }

        & > .differs__services ~ .differs__services {
            display: none;
        }

        & > .differs__namedObjects {
            display: block;
            position: relative;
        }

        & > .differs__namedObjects ~ .differs__namedObjects {
            display: none;
        }

        & > .differs__protection {
            display: block;
            position: relative;
        }

        & > .differs__protection ~ .differs__protection {
            display: none;
        }

        &__number {
            position: absolute;
            border: 1px solid $primary;
            border-radius: 100%;
            width: $grid-gutter;
            height: $grid-gutter;
            font-size: x-small;
            color: $white;
            background: $primary;
            text-align: center;
            margin-left: 0.2 * $grid-gutter;
        }

    }
}

@keyframes flexGrow {
    to {
        flex: 1;
    }
}
