$radio-size: $grid-gutter !default; // size of small circle

.radio {
    display: inline-block;

    & > input[type=radio] {
        display: none;

        & + label {
            position: relative;
            border: 0;
            cursor: pointer;
            padding-left: $radio-size * 2;
            padding-top: $grid-gutter * 0.25;
            margin-bottom: 0;
            font-size: $font-size-tiny;
            font-weight: 600;
            color: $input-label-color;
            line-height: 1.4;
            min-height: $radio-size * 1.5;
            transform: none;

            // version for white background
            .md-outline & {
                color: $input-label-color;
            }

            // small circle - just bg color
            &::before {
                content: "";
                width: $radio-size;
                height: $radio-size;
                background-color: $primary-green;
                position: absolute;
                top: $radio-size * 0.25;
                left: $radio-size * 0.25;
                border-radius: $radio-size * 0.5;
            }

            // big circle must overlay small circle
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: $radio-size * 1.5;
                height: $radio-size * 1.5;
                border: 2px solid $input-border-color;
                border-radius: $radio-size * 0.75;
                background-color: transparent;
                box-shadow: inset 0 0 0 ($radio-size * 0.75) $white;
                transition-property: box-shadow, background-color, border-color;
                transition-duration: $transition-time;
                transition-timing-function: ease-in-out;
            }
        }

        // checked state of input
        &:checked + label::after,
        &:checked:disabled + label::after {
            box-shadow: inset 0 0 0 ($radio-size * 0.25) $white;
        }

        // disabled state of radio
        &:disabled {
            & + label {
                // color: rgba($font-color, $form-disabled-opacity);

                & span {
                    opacity: $input-disabled-opacity;
                }

                &::before {
                    opacity: $input-disabled-opacity;
                    background-color: $grey-light;

                }

                // big background circle
                &::after {
                    box-shadow: inset 0 0 0 $radio-size $grey-light;
                    border: 2px solid $grey-light;
                    background-color: $grey-light;
                    opacity: $input-disabled-opacity;
                }
            }
        }
    }

    // list of radios
    &List {
        flex-direction: column;

        &--horizontal {
            flex-direction: row;
            justify-content: flex-start;

            .radio {
                margin: 0 0.5 * $grid-gutter;
            }
        }

        .radio {
            margin-bottom: $grid-gutter;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
