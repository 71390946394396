//helpers
.cursor {
    &--auto {
        cursor: auto;
    }

    &--pointer {
        cursor: pointer;
    }

    &--grab {
        cursor: grab;
    }
}
