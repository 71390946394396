$checkbox-size: $grid-gutter * 1.5 !default; // size of square element

.checkbox {
    display: inline-block;

    & > input[type=checkbox] {
        display: none;

        &:checked + label {
            &::after {
                box-shadow: inset 0 0 0 0 $white;
            }
        }
    }

    // label right after input
    & > input[type=checkbox] + label {
        position: relative;
        cursor: pointer;
        padding-left: $grid-gutter * 2;
        padding-top: $grid-gutter * 0.25;
        margin-bottom: 0;
        font-size: $font-size-tiny;
        font-weight: 600;
        color: $input-label-color;
        line-height: 1.4;
        min-height: $checkbox-size;
        transform: none;

        // check icon inside
        &::before {
            content: url("data:image/svg+xml; utf-8, <svg fill='rgb(108 128 29)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;' viewBox='0 0 24 24'><path d='M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z' /></svg>");
            position: absolute;
            top: 0;
            left: 0;
            width: $checkbox-size;
            height: $checkbox-size;
        }

        // square input element
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            height: $checkbox-size;
            width: $checkbox-size;
            border: ($grid-gutter * 0.125) solid $input-border-color;
            border-radius: $border-radius;
            box-shadow: inset (-$checkbox-size) 0 0 0 $white;
            transition-property: box-shadow, border-color;
            transition-duration: $transition-time;
            transition-timing-function: ease-in-out;
        }
    }

    &--disabled {
        opacity: $input-disabled-opacity;
        pointer-events: none;
        touch-action: none;

        // label right after input
        & > input[type=checkbox] + label {
            // square input element
            &::after {
                box-shadow: inset (-$checkbox-size) 0 0 0 $grey-medium;
            }
        }
    }

    &--labelColor {
        width: 100%;
        height: 0.2 * $grid-gutter;
    }
}

.form-group--checkbox {
    margin-bottom: $grid-gutter * 0.75 !important; //stylelint-disable-line
}
