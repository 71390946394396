.form-group {
    .form-control {
        border: 2px solid $input-border-color;
        border-radius: $border-radius;
        height: $input-height;
        width: 100%;
        padding: $input-padding;
        margin: 0;
        font-size: $font-size-small;
        font-weight: 600;
        line-height: 1.25;
        letter-spacing: 0.28px;

        // background-color: $input-bg-color;
        box-shadow: none;
        transition-property: border-color, background-color, height, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        box-sizing: border-box;
        caret-color: $primary-color;
        font-variant: normal;
        text-transform: none;
        background-color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;

        // firefox version 123.0 -> ticket AK-3442
        min-height: 0px;

        // input has filled value
        &--filled {
            // disabled state if value is filled (no placeholder shown)
            &:disabled {
                background-color: transparent;
                border-color: transparent;
                opacity: 1;

                & ~ label,
                & label {
                    color: $secondary-color;
                }
            }

            // label styles if input is not empty nor focused
            &:not(.form-control--focused) ~ label.active,
            &:not(.form-control--focused) label.active {
                color: $secondary-color;
            }

            // label styles if input is not empty nor focused but valid
            &.form-control--valid:not(.form-control--focused) ~ label.active,
            &.form-control--valid:not(.form-control--focused) label.active {
                color: $input-success-color;
            }

            // label styles if input is not empty nor focused but invalid
            &.form-control--invalid:not(.form-control--focused) ~ label.active,
            &.form-control--invalid:not(.form-control--focused) label.active {
                color: $input-error-color;
            }
        }

        // required state (only if no filled data here, or no focus, not disabled)
        &--required:not(.form-control--filled):not(.form-control--invalid):not(.form-control--focused):not(.form-control--disabled) {
            border-color: $secondary-color;

            & ~ label,
            & label {
                color: $secondary-color;

                .active {
                    color: $secondary-color;
                }
            }
        }

        // valid input (success state)
        &--valid {
            border-color: $input-success-color;
            box-shadow: $input-box-shadow-values $input-success-color;

            & ~ label,
            & label {
                color: $input-success-color;

                &.active {
                    color: $input-success-color;
                }
            }

            & ~ .form-control__message,
            & ~ .form-control__messageWrap,
            & ~ .form-control__icons {
                color: $input-success-color;
                z-index: $z-index-5;
            }
        }

        // warning state for input
        &--warning {
            border-color: $input-warning-color;
            box-shadow: $input-box-shadow-values $input-warning-color;

            & ~ label,
            & label {
                color: $input-warning-color;

                &.active {
                    color: $input-warning-color;
                }
            }

            & ~ .form-control__message,
            & ~ .form-control__messageWrap,
            & ~ .form-control__icons {
                color: $input-warning-color;
                z-index: $z-index-5;
            }
        }

        // invalid input (error state)
        &--invalid {
            border-color: $input-error-color;
            box-shadow: $input-box-shadow-values $input-error-color;

            & ~ label,
            & label {
                color: $input-error-color;

                &.active {
                    color: $input-error-color;
                }
            }

            & ~ .form-control__message,
            & ~ .form-control__messageWrap,
            & ~ .form-control__icons {
                color: $input-error-color;
                z-index: $z-index-5;
            }
        }

        &--file {
            display: none;
        }

        // input type password
        &--password {
            padding-right: 2 * $grid-gutter;
        }

        // Focused input style
        &--focused {
            border-width: 2px;
            border-color: $input-md-focus-color;
            background-color: $white;
            box-shadow: none;
            outline: 0;

            // Focused label style
            & ~ label,
            & label {
                color: $input-md-focus-color;
            }

            // placeholder state for focused input
            &::placeholder {
                opacity: 0;
            }

            // error state for focused input
            &.form-control--valid {
                border-color: $input-success-color;
                box-shadow: $input-box-shadow-values $input-success-color;
                caret-color: $input-success-color;

                & ~ label.active,
                & label.active {
                    color: $input-success-color;
                }
            }

            // warning state for focused input
            &.form-control--warning {
                border-color: $input-warning-color;
                box-shadow: $input-box-shadow-values $input-warning-color;
                caret-color: $input-warning-color;

                & ~ label.active,
                & label.active {
                    color: $input-warning-color;
                }
            }

            // success state for focused input
            &.form-control--invalid {
                border-color: $input-error-color;
                box-shadow: $input-box-shadow-values $input-error-color;
                caret-color: $input-error-color;

                & ~ label.active,
                & label.active {
                    color: $input-error-color;
                }
            }
        }

        &--borderless {
            border: 0;
        }

        &--withoutPaddingLeft {
            padding-left: 0;
        }

        // disabled state of input
        &--disabled {
            background-color: $secondary-color-10;
            touch-action: none;
            resize: none;

            .select__multi-value__label {
                opacity: $input-disabled-opacity;
            }

            & ~ label,
            & label {
                pointer-events: none;
                touch-action: none;
            }

            & ~ label {
                background-color: transparent;
                opacity: $input-disabled-opacity;
            }

            // disabled with placeholder shown
            &:placeholder-shown {
                & ~ label,
                & label {
                    // active state of label (on input focus)
                    // scale is from font-size 14px converts to 12px accurately
                    &.active {
                        transform: translateY(-100%) scale(0.857);
                    }
                }
            }
        }

        &--fake {
            opacity: 1;
            background-color: $secondary-color-10;
            touch-action: none;
            resize: none;

            & ~ label,
            & label {
                background-color: transparent;
                pointer-events: none;
                touch-action: none;
                opacity: 1;
            }
            // disabled with placeholder shown
            &:placeholder-shown {
                & ~ label,
                & label {
                    // active state of label (on input focus)
                    // scale is from font-size 14px converts to 12px accurately
                    &.active {
                        transform: translateY(-100%) scale(0.857);
                    }
                }
            }

        }

        // INPUT ELEMENTS (inside form-control container)
        // default label style
        & ~ label {
            @include label;
        }

        // input placeholder
        &::placeholder {
            color: $placeholder-color;
            font-size: $font-size-small;
            font-weight: 500;
            letter-spacing: initial;
            opacity: 1;
            transition-property: opacity;
            transition-duration: 0.2s;
            transition-timing-function: ease-in-out;
        }

        // container for display icons (tootlip, error, succes, warning)
        &__icons {
            position: absolute;
            bottom: calc(100% + #{$border-width});
            right: 0;
            color: $secondary-color-20;
            display: flex;
            align-items: center;
        }

        // loader for input
        &__loader {
            height: $grid-gutter * 1.25;
            width: $grid-gutter * 1.25;
            position: static;

            .loader__svg {
                width: 100%;
                height: 100%;
            }
        }

        // tooltip (info icon with help) for input
        &__tooltip {
            @include tooltip(right, top);

            height: $grid-gutter * 1.5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $input-border-color;
        }

        // switch for input type password (eye)
        &__eye {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;
            height: $input-height;
            width: $input-height;
            cursor: pointer;
            z-index: $z-index-3;
            transition: color $transition-global;
            color: $input-border-color;

            &:hover {
                color: $secondary-color;
            }
            &--visible {
                color: $secondary-color;
            }

            &:focus,
            &:active {
                color: $primary-color;
            }

            &--copy {
                width: unset;
            }

            &--grepIgnoreCase {
                right: 5rem;
                margin-top: 0;
            }

            &--grepInverseMatch {
                right: 2.5rem;
                margin-top: 0;
            }

            &--grepAbsoluteMatch {
                margin-top: 0;
            }
        }
        &__exclamation {
            margin-top: 0;
            display: flex;
            z-index: $z-index-3;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            height: $input-height;
            width: $input-height - 1 * $grid-gutter;
            cursor: pointer;
            transition: color $transition-global;
            color: $input-border-color;

            &--select2 {
                position: unset;
                height: unset;
                width: 10px;
            }

            &--row {
                width: $input-height - 2 * $grid-gutter;
            }


            &:hover {
                color: $secondary-color;
            }

            &:focus,
            &:active {
                color: $primary-color;
            }

            &--number {
                top: 25%;
                color: inherit;
            }


            &--copy {
                width: unset;
            }
        }





        // INPUT MESSAGES component (for errors, warnings etc.)
        // messages not children under list and list itself
        :not(.form-control__messageList) > &__message,
        &__messageWrap {
            position: absolute;
            z-index: $z-index-1;
            top: calc(100% + 1px);
            font-size: 0.6875rem;
            font-weight: 600;
            width: calc(100% - #{$input-padding-y} - #{$border-width});
            left: calc(#{$input-padding-y} + #{$border-width});

            &--smaller {
                top: calc(70% + 1px);
            }
        }

        &__messageList {
            padding: 0;
            top: 0;
            position: absolute;
            width: calc(100% - #{$grid-gutter * 1.5});
            list-style: none;

            // message inside list (multiple messages)
            .form-control__message {
                @include noscrollbar;

                background-color: transparent;
                position: absolute;
                left: 0;
                width: 100%;
                cursor: pointer;
                color: currentColor;
                max-height: $grid-gutter * 2;
                overflow: auto;
                opacity: 0;
                transition-property: opacity, color;
                transition-duration: $transition-time;
                transition-timing-function: ease-in-out;

                &:hover {
                    color: $secondary-color;
                }

                &--active {
                    opacity: 1;
                    z-index: $z-index-1;
                }
            }
        }

        &__message {
            position: absolute;
        }

        &__counter {
            @include tooltip(right, bottom);

            position: absolute;
            top: $grid-gutter * 0.125;
            right: 0;
            width: $grid-gutter * 1.125;
            height: $grid-gutter * 1.125;
            border-radius: $grid-gutter * 1.125 * 0.5;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: $grid-gutter * 0.625;
            font-weight: 500;
            letter-spacing: 0.25px;
            background-color: currentColor;
            cursor: pointer;
            user-select: none;
            transition: background-color $transition-global;
            z-index: $z-index-2;

            // counter number (must be solo selector, does not break bg dependency on color)
            & > span {
                color: $white;
            }

            &:hover {
                background-color: $secondary-color;
            }
        }
    }

    &.dataTableWidget__RowInput {
        & .form-control__label, & .switch__name--likeLabel {
            display: none;
        }

        &--sm {
            .form-control__exclamation {
                height: 1.5 * $grid-gutter;
            }

            .form-control__eye {
                height: 1 * $grid-gutter;
            }
        }

        .form-control--disabled {
            background-color: transparent;
        }
    }

    &--file {
        width: 100%;
        height: 100%;
        min-height: 10 * $grid-gutter;
        border: 2px solid $input-border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &Error {
            border: 2px solid $error-color;
        }

    }

    &--dragEnter {
        border: 2px solid $primary;

        & svg.arrow-collapse-down {
            animation: jump 1.2s infinite;
        }
    }

    textarea.form-control {
        height: auto;
        min-height: $input-height * 2;
        line-height: 1.5;
        border-radius: $border-radius;
        padding-right: $grid-gutter * 2.5;
        background-position: top ($grid-gutter * 0.125) right $grid-gutter;
        margin: 0;
        transition-property: border-color, background-color, box-shadow;
        overflow: auto;
        // state for loading on backend
        &--loading {
            padding-right: $grid-gutter * 2.5;
            background-position: top ($grid-gutter * 0.3125) right $grid-gutter;
        }
    }

    textarea.form-control.form-control--withoutPaddingLeft {
        padding-right: 0;
        min-height: unset;
    }

    textarea.form-control.form-control--withoutResize {
        resize: none;
    }

    // input type password styles
    input[type=password]:not(:placeholder-shown) {
        font-family: sans-serif;
        letter-spacing: 1px;
        font-size: 1.25rem;
    }
}

// dark version of inputs (for dark bg)
.form-group--dark {
    .form-control {
        background-color: $input-dark-bg-color;
        border-color: $input-dark-border-color;

        // dark version of label
        & ~ label,
        & label {
            background-color: transparent;

            &.active {
                transform: translateY(-#{$grid-gutter}) scale(0.857);
            }

            // exception for switch label
            &.switch__label {
                margin-bottom: 0;
                position: relative;
                font-weight: 600;
            }
        }

        // input has filled value (dark version)
        &--filled {
            &:disabled {
                background-color: $input-dark-bg-color;
                border-color: $input-dark-border-color;
                opacity: $input-dark-disabled-opacity;

                & ~ label,
                & label {
                    opacity: $input-disabled-opacity;
                }
            }

            // label styles if input is not empty nor focused
            &:not(:focus) ~ label.active,
            &:not(:focus) label.active {
                color: $input-dark-label-color;
            }

        }

        // disabled dark input
        &--disabled {
            background-color: $input-dark-bg-color;
            border-color: $input-dark-border-color;
            opacity: $input-dark-disabled-opacity;

            & ~ label,
            & label {
                opacity: $input-disabled-opacity;
            }
        }
    }
}

.inputTrimmedWithDots {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

input:-internal-autofill-selected {
    background-color: $white-light;
}

.inputFileClose {
    position: absolute;
    top: -10%;
    left: calc(50% + #{$grid-gutter});
}


@keyframes jump {
    0% { transform: translate(0%, 0%) scale(1.25, 0.75); }
    50% { transform: translate(0%, -50%) scale(1, 1); }
    100% { transform: translate(0%,0%) scale(1.25, 0.75); }
}
