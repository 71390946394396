
.dataTable {
    position: relative;
    background: $white;
    max-height: 100%;
    font-family: $font-family !important; //stylelint-disable-line

    &__loader {
        background: rgba(255,255,255,0.8);
        height: calc(100% - #{$grid-gutter} * 3);
        width: 100%;
        bottom: 0;
        left: 0;
        padding: $grid-gutter * 1.5;
    }

    &--error {
        padding-top: 0;
    }
}

.QUICKFIX { // Need to add mdbCard and some other things, for now just this.
    max-height: 26 * $grid-gutter;
}

.drag {
    overflow: auto;
    height: 100%;
}


.dataTableWidget {
    height: 100%;
    color: $secondary-color;
    font-size: $grid-gutter * 0.75;
    padding-bottom: 0.5 * $grid-gutter;

    &--headerAlignRight {

        [class^=MUIDataTableHeadCell-toolButton-] {
            float: right;
        }
    }

    &Header {
        //Default is 100, we need to override this to 49 under our overlay
        z-index: $z-index-under-overlay !important; //stylelint-disable-line

        &--input {
            padding: 0 0.5 * $grid-gutter !important;  //stylelint-disable-line
            z-index: $z-index-15 !important; //stylelint-disable-line
        }

        &--empty {
            z-index: $z-index-15 !important; //stylelint-disable-line
        }
    }


    &__menu {
        padding: 0.25 * $grid-gutter;
        border-radius: $grid-gutter;
        min-width: 14.375 * $grid-gutter;
        border: 1px solid $border-color;

        &Button {
            width: 100%;
            padding: unset;
            cursor: pointer;
            border-width: 0 !important;  //stylelint-disable-line

            &Popup {
                width: 100%;
                border-width: 0 0 1px 0 !important;  //stylelint-disable-line
                border-radius: 0;
                border-color: $grey-xdark-30;
                border-style: solid;
                display: block;
                text-align: left;

                &--copy {
                    border-width: 0 0 0.5px 0 !important;  //stylelint-disable-line
                }

                &:last-child {
                    border-width: 0 !important;  //stylelint-disable-line
                }
            }
        }
    }

    &__maxHeight {
        max-height: 499px;
    }

    &--sortable {
        height: 100%;
        overflow: auto;
    }

    &CardTitle {
        display: flex;
        justify-content: space-between;
        border-bottom: 0;
        max-width: 100%;
    }

    &HeaderCell:first-child {
        padding-left: 0;
    }

    &__title {
        padding-left: $grid-gutter* 0.75;
    }

    &__cell {
        font-size: $grid-gutter * 0.75;
        padding: $grid-gutter * 0.5 $grid-gutter * 0.75;
        position: relative;

        &--stickyMenu {
            position: sticky;
            right: 0;
            z-index: $z-index-15;
            background-color: $white;

            >div {
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: flex-end;
            }

            &--off {
                margin-top: 1.1 * $grid-gutter !important; //stylelint-disable-line
            }

            &--cog {
                right: 4.7 * $grid-gutter;
                padding-right: 2px !important; //stylelint-disable-line
            }
        }

        &--stickyName {
            position: sticky;
            left: 0;
            z-index: $z-index-15;

            &--label {
                transition: none !important; //stylelint-disable-line
            }

            &--whiteBg {
                background-color: $white;
            }
        }

        &--icon {
            width: 1%;
        }

        &--textAsIcon {
            text-align: center;
        }

        &--match {
            border-left: 4px solid $primary-green;
        }

        &--error {
            border-left: 4px solid $red;
        }

        &--clicable {
            cursor: pointer;
        }

        &--disabled {
            color: $secondary-color-10;
            cursor: not-allowed;
        }

        &--md {
            padding: 2px;
            height: 1.5 * $grid-gutter;
        }

        &--displayNone {
            display: none;
        }



        .close:hover {
            color: $dark-red !important; //stylelint-disable-line
        }

        &--borderLeft {
            border-left: 1px solid $secondary-color-10;
        }

        &--clicked {
            width: 15%;
        }

        &--sticky {
            position: absolute;
            top: auto;
            z-index: $z-index-15;
            background-color: $white;
        }

        &--borderRight {
            border-right: 1px solid $secondary-color-10;
        }

        &--alignRight {
            white-space: nowrap;
            text-align: right;

            &Small {
                width: 10%;
            }
        }

        &--withIcon {
            padding: $grid-gutter * 0.5 ;

            .icon {
                margin-right: $grid-gutter * 0.75;
            }
        }

        &--asHeader {
            color: rgba(0, 0, 0, 0.54);
            padding: 2px;
            font-size: 0.9 * $grid-gutter;
            font-weight: 500;
        }

        &:first-child {
            padding-left: $grid-gutter * 0.375;
        }

        &--sm, .dataTableWidget__cell--sm & {
            padding: 0;
            height: 1.5 * $grid-gutter;

            &:first-child {
                padding-left: 0;
            }

        }

        &:last-child {
            padding-right: $grid-gutter * 0.75;
        }
        &--edit {
            display: none;
            padding: $grid-gutter * 0.2 !important; //stylelint-disable-line
            color: $black-base;
        }

        &Flex {
            position: relative;
            display: flex;

            &--clicked {
                margin-left: -$grid-gutter * 4;
            }

            &--Button {
                border: 0;
                background-color: $primary-color-10;
                margin: 0;
                color: $primary;
                padding: 0 !important; //stylelint-disable-line
                width: 2.5 * $grid-gutter;
                height: 2.5 * $grid-gutter !important; //stylelint-disable-line
                flex-shrink: 0;
            }
        }
    }

    &__icon {
        display: flex;

        &--title {
            padding-left: $grid-gutter * 0.5;
        }
    }

    &__delete {
        margin: 0 !important; //stylelint-disable-line

        &--open {
            color: $primary !important; //stylelint-disable-line
        }
    }

    &__show {
        visibility: hidden;
    }

    &__Row {
        position: relative;

        &--closed {
            display: none;
        }

        &--match {
            background-color: $green-15;

            .dataTableWidget__cell--stickyMenu {
                background-color: rgb(228,244,222) !important; //stylelint-disable-line
            }

            .dataTableWidget__cell--stickyName {
                background-color: rgb(228,244,222) !important; //stylelint-disable-line
            }
        }

        &--noticeMe {
            box-shadow: 0 0 0 0 $primary;
            animation: switchpulse 2s infinite;
            height: 0;
        }

        &--warning {
            background-color: $warning-color-30;
        }

        &--upgrade {
            background-color: $secondary-green-light;
        }

        &--error {
            background-color: rgba($error-color , 0.3);

            .dataTableWidget__cell--stickyMenu {
                background-color: rgb(242,187,187) !important; //stylelint-disable-line
            }

            .dataTableWidget__cell--stickyName {
                background-color: rgb(242,187,187) !important; //stylelint-disable-line
            }
        }

        &--unknown {
            background-color: rgba($error-color , 0.1);
        }

        &Input {
            border: 0 !important; //stylelint-disable-line
            background-color: inherit !important; //stylelint-disable-line
            margin-bottom: 0;
            margin-top: 0;
            min-width: 7 * $grid-gutter;
            min-height: $input-height-sm;
            cursor: text !important; //stylelint-disable-line

            &--md {
                min-width: unset;

                &.form-group--isFocused {
                    height: unset;
                }
            }

            &--sm, .dataTableWidget__cell--sm & {
                height: 1.5 * $grid-gutter;
                min-height: unset;
                min-width: unset;

                &.form-group--isFocused {
                    height: unset;
                }
            }
        }

        &--disable {
            background-color: $grey-light !important; //stylelint-disable-line
            color: $grey-xdark-30 !important; //stylelint-disable-line

            .dataTableWidget__cell--stickyMenu {
                background-color: $grey-light !important; //stylelint-disable-line
            }

            .dataTableWidget__cell--stickyName {
                background-color: $grey-light !important; //stylelint-disable-line
            }
        }

        &--important {
            font-weight: 600;
            cursor: grab ;
            outline: unset;
        }

        &--clicked {
            background-color: $primary-color-10 !important; //stylelint-disable-line
        }

        &:hover {
            background-color: $grey-light-50 !important; //stylelint-disable-line
            .dataTableWidget__cell--edit {
                display: unset;
                position: absolute;
                top: 0;
                right: 0;
                background-color: $white-base;
            }

            .dataTableWidget__show {
                visibility: visible;
            }

            .dataTableWidget__cell--stickyMenu {
                background-color: $grey-light-50-in-rgb !important; //stylelint-disable-line
            }

            .dataTableWidget__cell--stickyName {
                background-color: $grey-light-50-in-rgb !important; //stylelint-disable-line
            }
        }

        &FirstRule {

            &Row {
                height: 15 * $grid-gutter;
            }

            height: 15 * $grid-gutter;
            background-color: $secondary-green-white;
        }


        &Add {
            &Buttons {
                display: none;
                position: sticky;
                width: 100%;
                left: 0;
                height: 6px;
                z-index: $z-index-modal;
                margin-top: -10px;
                margin-left: -1px;

                &--button {
                    height: 1.5 * $grid-gutter;
                    display: inline-block;
                    font-size: 12px;
                    background-color: $primary-green;
                    border-radius: 1.875 * $grid-gutter;
                    color: $white;
                    text-decoration: none;
                    padding: 2px 6px 2px 6px;
                    margin-right: 2px;
                    margin-top: -10px;
                    position: relative;
                    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);


                    .icon {
                        margin: -0.125 * $grid-gutter 0 0 0;
                    }

                    &--green {
                        background-color: $primary-green;
                    }

                    &--secondary {
                        background-color: #584a3d;
                    }

                    .dataTableWidget__RowAdd & {
                        z-index: $z-index-15;
                    }

                    .dataTableWidget__RowAddEnd & {
                        z-index: $z-index-15;
                    }

                }
            }

            &Rule {
                background-color: inherit;
                height: 6px;
                position: relative;
                text-align: center;

                &:hover {
                    background-color: $grey-light;

                    .dataTableWidget__RowAddButtons {
                        display: block;
                    }
                }
            }

            &End {
                display: none;
                height: 6px;

                &:last-of-type {
                    display: table-row;
                }
            }

            background-color: $secondary-green-white;

            &:first-of-type {
                background-color: $white;
            }

            &PFButtons {
                position: sticky;
                width: 60vw;
                left: 250px;
                height: 6px;
                z-index: $z-index-modal;
            }
        }
    }

    &FooterRow {
        padding: $grid-gutter * 0.75 $grid-gutter * 1.5 $grid-gutter * 0.75 $grid-gutter * 1.2;
        font-size: $grid-gutter * 0.75;
        border-top: 1px solid $secondary-color-10;
        box-shadow: $box-shadow-card;
        border-radius: $border-radius;
        box-sizing: border-box;
        justify-content: space-between;
        display: flex;
        letter-spacing: 0;
        text-transform: uppercase;

        &__custom {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $white;
            width: 100%;
            text-transform: lowercase;
        }

        &__state {
            display: flex;
            justify-content: space-evenly;

            &Item {
                margin-left: $grid-gutter * 1.5;

                &--green {
                    color: $primary-green;
                }

                &--red {
                    color: $red;
                }

                &:first-child {
                    margin-left: 0;
                }
            }

            &:first-child {
                margin-left: 0;
            }
        }

        &__link {
            color: $grey-xdark;

            &:hover {
                color: $grey-xdark;
            }
        }

        &__paging {
            margin-right: calc(10% - #{$grid-gutter} * 2);
        }
    }
}

// https://css-tricks.com/html5-progress-element/

.progress-materializecss {
    margin: 5px 0;
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: $primary-color-30;
    border-radius: 2px;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;

    .indeterminate {
        background-color: $primary;

        &::before {
            content: "";
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }

        &::after {
            content: "";
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 1.15s;
        }
    }
}


@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminate-short {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }

    100% {
        left: 107%;
        right: -8%;
    }
}

