// Buttons
.btn {
    @include button-size($btn-padding-y-basic, $btn-padding-x-basic, $btn-font-size-basic, $btn-height);

    align-items: center;
    cursor: pointer;
    border-width: 0;
    border-style: solid;
    border-radius: $border-radius-base;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: 1.1;
    margin: $btn-margin-basic;
    text-transform: uppercase;
    transition: $btn-transition;
    white-space: normal;
    word-wrap: break-word;
    transition: font-size 1s;

    &--justIcon {
        font-size: 0;

    }


    & > span {
        display: inline-flex;
        align-items: center;
    }

    &.btn-lg {
        @include button-size($btn-padding-y-large, $btn-padding-x-large, $btn-font-size-large, $btn-height-large);
    }

    &.btn-md {
        @include button-size($btn-padding-y-medium, $btn-padding-x-medium, $btn-font-size-medium, $btn-height-medium);
    }

    &.btn-sm {
        @include button-size($btn-padding-y-small, $btn-padding-x-small, $btn-font-size-small, $btn-height-small);
    }

    &.btn-xsm {
        @include button-size($btn-padding-y-small, $btn-padding-x-xsmall, $btn-font-size-small, $btn-height-xsmall);
    }

    &.btn-tb {
        padding: $btn-tb-padding-y $btn-tb-padding-x;
    }

    &.btn-block {
        margin: inherit;
    }

    &.btn-transparent {
        border: 0;
        background-color: transparent;
        box-shadow: none;
    }

    &.noticeMe {
        box-shadow: 0 0 0 0 $primary;
        animation: switchpulse 2s infinite;
    }

    &.btn-close {
        padding: $grid-gutter * 0.5;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        right: 0;

        &:not([disabled]):not(.disabled):hover {
            color: $white;
        }

        &:not([disabled]):not(.disabled):active {
            box-shadow: none;
            color: $default-dark;
        }

        & > .icon {
            margin: 0;
        }
    }

    &.btn-full {
        width: 100%;
    }

    &.btn-marginless {
        margin: 0;
    }

    &.btn-link {
        text-transform: none;
        box-shadow: none;
        border: 0;
        background: none;
        padding: $grid-gutter * 0.5;
        margin: 0 - $grid-gutter * 0.5;

        &:not([disabled]):not(.disabled):hover,
        &:not([disabled]):not(.disabled):focus,
        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active,
        &:not([disabled]):not(.disabled).active:focus {
            box-shadow: none;
            background: none;
        }

        &.dark:hover {
            color: $secondary-color-70;
        }

        &[disabled],
        &:disabled {
            background-color: transparent;
        }

        .Ripple {
            display: none;
        }

        &.btn-primary {
            color: $primary-green;

            &:hover,
            &:focus {
                color: $white-light;
            }

            &:active {
                color: $white;
            }
        }
    }

    &.nav-link {
        font-size: $grid-gutter;
        border-radius: 0;
        background-color: transparent;
        box-shadow: none;
        justify-content: flex-start;
        margin: 0;
        border: 0;
        text-transform: none;

        &:not([disabled]):not(.disabled):hover,
        &:not([disabled]):not(.disabled):focus,
        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active,
        &:not([disabled]):not(.disabled).active:focus {
            box-shadow: none;
            background: none;
        }

        .icon {
            margin-left: 0;
        }
    }

    &[class*=btn-outline-] {
        box-shadow: none;
        border-width: $btn-border-width;
        padding-top: $btn-outline-padding-y-basic;
        padding-bottom: $btn-outline-padding-y-basic;

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active {
            box-shadow: none;
        }

        &.btn-lg {
            padding-top: $btn-outline-padding-y-large;
            padding-bottom: $btn-outline-padding-y-large;
        }

        &.btn-md {
            padding-top: $btn-outline-padding-y-medium;
            padding-bottom: $btn-outline-padding-y-medium;
        }

        &.btn-sm {
            padding-top: $btn-outline-padding-y-small;
            padding-bottom: $btn-outline-padding-y-small;
        }
    }
}

.btn + .btn {
    margin-left: 0.5 * $grid-gutter;
}

.react-tiny-popover-container {
    .btn + .btn {
        margin-left: 0;
    }

    .btn-default[disabled] {
        color: $secondary-brown;
        background-color: $white;
    }
}

.btn-group {
    .btn {
        margin: 0;
        box-shadow: 0 2px 5px 0 $black-16 inset;
    }
}

@each $btn_name, $color_value in $mdb-colors {
    @include make-button($btn_name, $color_value);
    @include make-outline-button($btn_name, $color_value);
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
    color: $white-base;
}

// Gradient buttons
@each $name, $val in $gradients {
    @include make-gradient-button($name, $val);
}

.btn-flex-40 {
    flex: 40%;
}