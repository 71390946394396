.graph {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    line-height: $grid-gutter;


    &__loader {
        background: rgba(255,255,255,0.8);
        height: calc(100% - #{$grid-gutter} * 3);
        width: 100%;
        bottom: 0;
        left: 0;
    }

    &Drop {
        &Menu {
            background-color: $white;
        }
    }
}

.reporterDashboard {

    &--error {
        padding: $grid-gutter;
        background: $white;
    }
}

.highcharts-container {
    margin-bottom: $grid-gutter * -1.27;
}
