$userList-width: 20 * $grid-gutter !default;

.usersListScene {
    height: calc(100vh - #{$header-height} - #{$breadcrumb-height});
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    min-height: 800px;
}

.userDetailForm,
.userDetailLog {
    width: calc(100% - #{$userList-width} - #{$grid-gutter * 2});
    height: auto;
    margin-top: $grid-gutter;
    margin-bottom: $grid-gutter;
}

.userDetailForm {
    z-index: $z-index-on-overlay;

    .card__form {
        padding: 1.5 * $grid-gutter;
    }

    .form-group {
        margin-bottom: 2 * $grid-gutter;

        textarea.form-control {
            height: 8 * $grid-gutter;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: $border-bottom;
    }

    &__footer {
        margin-top: $grid-gutter * 2;
    }

    &__editBtn {
        margin: $grid-gutter * 0.5 $grid-gutter * 1.5;
        height: $grid-gutter * 2;
    }

    &__userDescription.form-group {
        margin-bottom: -5rem;
    }
}

// Activity Table
.userDetailLog {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: $grid-gutter;
}

.userList {
    overflow: auto;
    width: $userList-width;
    padding: $grid-gutter;
    height: 100%;

    // border-right: $border-right;
}

.userItem {
    display: flex;
    margin-bottom: $grid-gutter * 0.125;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    height: $grid-gutter * 3.5;
    transition-property: box-shadow, transform;
    transition-duration: $transition-time;
    transition-timing-function: ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translateX(-$grid-gutter * 0.1875);
        box-shadow: $box-shadow-hover;
        position: relative;
    }

    &--active {
        background-color: $secondary-green-light;
    }

    &--deactivated {
        color: $brown-dark-20;
        background-color: $grey;
    }

    &__cardItem {
        min-width: $grid-gutter * 15;
        display: flex;
        justify-content: space-between;
    }

    &__name {
        position: relative;
        font-size: $grid-gutter * 0.875;
        margin: 0;
        padding: $grid-gutter * 0.75 $grid-gutter * 0.75 $grid-gutter * 0 $grid-gutter * 2;
        width: $grid-gutter * 12;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--online::before {
            content: "";
            position: absolute;
            top: $grid-gutter * 1.125;
            left: $grid-gutter * 1.0625;
            width: $grid-gutter * 0.5;
            height: $grid-gutter * 0.5;
            background-color: $primary-green;
            border-radius: 50%;
        }

        &--offline::before {
            content: "";
            position: absolute;
            top: $grid-gutter * 1.125;
            left: $grid-gutter * 1.0625;
            width: $grid-gutter * 0.5;
            height: $grid-gutter * 0.5;
            border: 1px solid $red;
            border-radius: 50%;
        }

        &--deactivated {
            color: $brown-dark-20;

            &::before, &::after {
                position: absolute;
                left: $grid-gutter * 1.25;
                top: $grid-gutter;
                content: "";
                height: $grid-gutter * 0.7;
                width: 1px;
                background-color: $red;
            }

            &::before {
                transform: rotate(45deg);
            }

            &::after {
                transform: rotate(-45deg);
            }
        }
    }

    &__role {
        font-size: $grid-gutter * 0.625;
        margin: 0;
        padding: ($grid-gutter * 0) ($grid-gutter * 0.75) ($grid-gutter * 0) ($grid-gutter * 2);
    }

    &__username {
        margin-left: $grid-gutter * 0.25;
    }
}

.userStatus {
    &__time {
        font-size: $grid-gutter * 0.625;
        margin: 0;
        padding: ($grid-gutter * 0.75) ($grid-gutter * 0.75) ($grid-gutter * 0) ($grid-gutter * 0.75);
    }

    &__icon {
        color: $grey-xdark-15;
        position: absolute;
        right: $grid-gutter * 0.375;
    }
}

// TODO refactor for future use as a standalone component with more buttons od search bar. Also rename to maybe userTools
.userNavigation {
    position: absolute;
    top: 0;
    right: 0;
    padding: ($grid-gutter * 0.5) $grid-gutter;
    z-index: $z-index-on-overlay;
    display: flex;
}

.userFilter {
    min-height: $grid-gutter * 4.5;
    padding-top: $grid-gutter;
    display: flex;
    justify-content: space-between;
    border-bottom: $border-bottom;
    font-size: $grid-gutter * 0.6875;
    color: $secondary-color;

    &__role {
        display: flex;
        flex-direction: column;
        text-align: left;
        min-width: $grid-gutter * 8.4375;
        padding-bottom: $grid-gutter * 0.5;
    }

    &__select {
        margin-bottom: 0;
        min-width: 8 * $grid-gutter;

        input[type=text].select-dropdown {
            background-color: $white;

            & ~ label {
                color: $input-label-color;
                font-weight: 600;
                left: $grid-gutter * 0.5625;

                &.active {
                    font-weight: 600;
                }
            }
        }
    }

    &__switch {
        margin-top: -1.75 * $grid-gutter;
        margin-bottom: 0;

        .switch__name {
            padding-bottom: 0.25 * $grid-gutter;
        }
    }

    & .switch {
        border: 0;
        width: $grid-gutter * 9.05;
        margin-right: -0.75 * $grid-gutter;
    }
}

.passwordChange {
    z-index: $z-index-on-overlay;

    p {
        font-size: $font-size-tiny;
    }

    &__loader {
        background-color: $white-85;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
}
