// ===========================================================================
// KERNUN Variables
// ===========================================================================

// import Kernun colors
@import "colors";

// font
$font-family: Encode Sans, sans-serif;
$font-size-large: 1.25rem;
$font-size-small: 0.9375rem;
$font-size-tiny: 0.75rem;

// borders
$border-radius: $grid-gutter * 0.25;
$border-width: $grid-gutter * 0.125;
$border-color: $secondary-color-20;
$border-line: 1px solid $border-color;

// scrollbar
$scrollbar-width: 16px; // px units has better rendering result

// forms
$input-label-font-size: $font-size-small;
$input-label-color: $secondary-color;
$input-border-color: $border-color;
$input-bg-color: $white;
$input-height: $grid-gutter * 2.5;
$input-height-sm: $grid-gutter * 1.5;
$input-padding-x: $grid-gutter * 0.75;
$input-padding-y: $grid-gutter * 0.625;
$input-padding: $input-padding-y $input-padding-x;
$input-box-shadow-values: 0 0 0 1px;
$input-disabled-opacity: 0.4;

$input-dark-label-color: $grey-light-50;
$input-dark-label-font-size: $font-size-tiny;
$input-dark-bg-color: $grey-medium;
$input-dark-border-color: $input-dark-bg-color;
$input-dark-disabled-opacity: 0.15;

$input-error-color: $error-color;
$input-success-color: $success-color;
$input-warning-color: $warning-color;

// btns
$btn-height: $input-height;
$btn-border-width: $border-width;
$btn-height-xsmall: $grid-gutter * 1.5;
$btn-height-small: $grid-gutter * 2;
$btn-height-medium: $grid-gutter * 2;
$btn-height-large: $grid-gutter * 3;

// transition
$transition-time: 0.2s;
$transition-time-slow: 0.35s;
$transition-global: $transition-time ease-in-out;

// shadows
$box-shadow: 0 1px 3px $box-shadow-color;
$box-shadow-hover: 0 3px 6px $box-shadow-color-hover;
$box-shadow-card: 0 3px 6px $brown-dark-10;
$border-bottom: ($grid-gutter * 0.0625) solid $brown-dark-10;
$border-top: ($grid-gutter * 0.0625) solid $brown-dark-10;
$border-right: ($grid-gutter * 0.0625) solid $brown-dark-10;

// layout
$loginForm-minWidth: 400px;
$loginForm-minWidth-md: 320px;
$loginForm-minWidth-xs: 320px;
$loginForm-maxWidth-lg: 540px;
$loginForm-maxWidth: 300px;
$loginForm-maxWidth-md: 280px;

// Loader
$loader-text-color: $white;
$loader-position-r: $grid-gutter * 4;
$loader-margin-r: $grid-gutter;
$loader-margin-b: $grid-gutter * 0.5;

// Header
$header-height: $grid-gutter * 4;
$breadcrumb-height: $grid-gutter * 3;
$userFilter-height: $grid-gutter * 4;

// Menu
$leftMenu-width: $grid-gutter * 12.5;
$leftMenu-width-collapsed: $grid-gutter * 3.75;
$leftMenu-link-height: $grid-gutter * 3;

// Cards
$card-title-height: $grid-gutter * 3.5;
$card-footer-height: $grid-gutter * 4.5;
$card-padding: $grid-gutter * 1.5;

//basic z indexes, use as needed
$z-index: 0;
$z-index-1: 1;
$z-index-2: 2;
$z-index-3: 3;
$z-index-4: 4;
$z-index-5: 5;
$z-index-15: 15;

//overlays - UserPasswordEdit, UserPasswordReset, CardsOverlay, etc
$z-index-under-overlay: 49;
$z-index-overlay: 50;
$z-index-aside: 51;
$z-index-on-overlay: 52;


$z-index-header: 90;
$z-index-status-bar: 98;
$z-index-modal-backdrop: 99;
$z-index-modal: 100;
$z-index-modal-1: 101;
$z-index-notification: 1000;

// ===========================================================================
// MDB variables
// ===========================================================================
//
// Table of Contents:
//
//  1. Fonts
//  2. Shadows
//  3. Transitions
//  4. Borders
//  5. Globals
//  6. Masks
//  7. Images
//  8. Buttons
//  9. Cards
//  10. Navbars
//  11. Pagination
//  12. Badges
//  13. Modals
//  14. Carousels
//  15. Forms
//  16. Miscellaneous
//  17. Footers
//  18. List groups
//  19. Tables
//  20. Steppers

// 1. Fonts
// ===========================================================================

// Basic font properties
// $roboto-font-path:                            '../client/fonts/';
$roboto-font-family: $font-family;
$mdb-font-family: $roboto-font-family;

// Links
$link-color: $primary;
$link-hover-color: darken($link-color, 15%);

// 2. Shadows
// ===========================================================================

// Shadows
// $z-depth-1: 0 2px 5px 0 rgba($black-light, 0.16), 0 2px 8px 0 rgba($black-light, 0.12);
$z-depth-1: $box-shadow;
$z-depth-1-left: 1px 0 5px 0 rgba($secondary-color, 0.16), -1px 0 8px 0 rgba($secondary-color, 0.12);
$z-depth-1-half: 0 1px 5px 0 rgba($secondary-color, 0.6);
$z-depth-2: 0 8px 17px 0 rgba($secondary-color, 0.2), 0 6px 20px 0 rgba($secondary-color, 0.19);
$z-depth-3: 0 12px 15px 0 rgba($secondary-color, 0.24), 0 17px 50px 0 rgba($secondary-color, 0.19);
$z-depth-4: 0 16px 28px 0 rgba($secondary-color, 0.22), 0 25px 55px 0 rgba($secondary-color, 0.21);
$z-depth-5: 0 27px 24px 0 rgba($secondary-color, 0.2), 0 40px 77px 0 rgba($secondary-color, 0.22);

// 3. Transitions
// ===========================================================================

// Transitions
$transition-basic: all 0.2s ease-in-out;
$transition-hoverable: all 0.55s ease-in-out;

// 4. Borders
// ===========================================================================

// Border radius
$border-radius-base: $border-radius;
$border-radius-circle: 50%;

$border-10: 1px solid $brown-dark-10;

// 5. Globals
// ===========================================================================

// Media Query Ranges
$small-screen-up: 601px;
$medium-screen-up: 993px;
$large-screen-up: 1201px;
$small-screen: 600px;
$medium-screen: 992px;
$large-screen: 1200px;
$sidenav-breakpoint: 1440px;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";
$hide-sidenav: "only screen and (max-width : #{$sidenav-breakpoint})";

// Divider
$divider-margin-y: 2.8rem;
$divider-margin-x: 0.5rem;
$divider-height: 2px;

// Dividers colors
$hr-light: $grey-light;
$hr-dark: $default-dark;

// 7. Images
// ===========================================================================

// Images
$image-path: "../img";
$avatar-img-max-width: 100px;

// 8. Buttons
// ===========================================================================

// Buttons
// $btn-color-basic                             : $white-base;
$btn-margin-basic: 0;

// $btn-margin-basic: 0 0 ($grid-gutter * 2);

// $btn-padding-y-basic                         : $grid-gutter * 0.6875;
$btn-padding-y-basic: 0;
$btn-padding-x-basic: $grid-gutter * 1.25;
$btn-font-size-basic: $grid-gutter * 0.875;

// $btn-padding-y-large                         : $grid-gutter;
$btn-padding-y-large: 0;
$btn-padding-x-large: $btn-padding-x-basic;
$btn-font-size-large: $grid-gutter;

// $btn-padding-y-medium                        : .7rem;
// $btn-padding-x-medium                        : 1.6rem;
// $btn-font-size-medium                        : .7rem;

// $btn-padding-y-small                         : $grid-gutter * 0.4375;
$btn-padding-y-small: 0;
$btn-padding-x-small: $grid-gutter * 1;
$btn-padding-x-xsmall: 0;
$btn-font-size-small: $grid-gutter * 0.75;

$btn-outline-padding-y-basic: $btn-padding-y-basic;
$btn-outline-padding-y-large: $btn-padding-y-large;

// $btn-outline-padding-y-medium                : .58rem;
$btn-outline-padding-y-small: $btn-padding-y-small;

// $btn-tb-padding-y                            : 0.3rem;
// $btn-tb-padding-x                            : 1rem;

// 9. Cards
// ===========================================================================

// Cards
$md-card-border-radius: $border-radius;
$md-card-link-transition: $transition-global;
$md-card-font-size: $grid-gutter * 0.875;
$md-card-text-color: $black-base;

// 10. Navbars
// ===========================================================================

// Navbars
$navbar-font-weight: 400;
$navbar-double-font-size: $grid-gutter;

// $navbar-light-toggler-icon                   : url('data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E') !default;
// $navbar-light-bg-active-color                : rgba($black-base, 0.1) !default;
// $navbar-light-hover-color                    : rgba($black-base, 0.75) !default;
// $navbar-light-disabled-color                 : rgba(0, 0, 0, 0.5) !default;

// $navbar-dark-toggler-icon                    : url('data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E') !default;
$navbar-dark-bg-active-color: $white;
$navbar-dark-hover-color: $white;
$navbar-dark-disabled-color: rgba($white-base, 0.5);

// $navbar-scrolling-transition                 : background 0.5s ease-in-out, padding 0.5s ease-in-out !default;
// $navbar-scrolling-transition-duration        : 1s !default;
// $navbar-scrolling-padding                    : 12px !default;
// $navbar-top-collapse-padding                 : 5px !default;
$navbar-nav-transition: all $transition-global;

// $navbar-dropdown-font-size                   : 0.9375rem !default;
// $navbar-dropdown-menu-padding                : 10px !default;
// $navbar-flex-icons-padding-md                : 6px !default;
// $navbar-flex-icons-padding-lg                : 3px !default;
// $navbar-form-input-mr                        : 5px !default;
// $navbar-form-input-mb                        : 1px !default;
// $navbar-form-input-ml                        : 8px !default;
// $navbar-form-input-height                    : 1rem !default;
// $navbar-breadcrumb-padding-top               : 0.3rem !default;
// $navbar-breadcrumb-padding-left              : 1rem !default;
// $navbar-breadcrumb-color                     : rgba(255,255,255,0.65) !default;

// 15. Forms
// ===========================================================================

// Forms

// // $label-font-size                             : $label-font-size;
// $input-transition: all 0.2s;

// // $input-disabled-color                        : rgba(0,0,0, .46);
// $input-md-focus-color: $primary-color;
// $input-error-color: $error-color;
// $input-success-color: $success-color;
// $input-warning-color: $warning-color;
// $input-label-after-top: 65px;
// $input-label-after-transition: 0.2s opacity ease-out, 0.2s color ease-out;
// $input-label-transition: 0.2s ease-out;
// $input-label-top: 0;
// $input-label-active-transform: none;

// // $input-prefix-transition                     : color .2s;

// $input-md-form-margin-top: 0;
// $input-md-form-margin-bottom: $input-md-form-margin-top;
// $input-label-active-font-size: $label-font-size;

// // $input-prefix-top                            : .25rem;
// // $input-prefix-font-size                      : 1.75rem;
// // $input-prefix-margin-left                    : 2.5rem;
// // $input-prefix-width                          : calc(100% - 2.5rem);
// // $input-group-addon-font-size                 : 1.4rem;
// // $input-form-text-ml                          : 2.6rem;
// // $input-validate-mb                           : 2.5rem;
// // $input-label-valid-top                       : 4.1rem;
// // $input-label-invalid-top                     : 4rem;

// // $input-label-font-size-lg                    : 1.25rem;
// // $input-label-active-font-size-lg             : 1.15rem;
// // $input-prefix-top-lg                         : .4rem;
// // $input-prefix-font-size-lg                   : 2rem;
// // $input-prefix-margin-left-lg                 : 3rem;
// // $input-prefix-width-lg                       : calc(100% - 3rem);
// // $input-group-addon-font-size-lg              : 1.65rem;
// // $input-form-text-ml-lg                       : 3.1rem;
// // $input-validate-mb-lg                        : 2.8rem;
// // $input-label-valid-top-lg                    : 4.6rem;
// // $input-label-invalid-top-lg                  : 4.6rem;

// // $input-label-font-size-sm                    : .875rem;
// // $input-label-active-font-size-sm             : .95rem;
// // $input-prefix-top-sm                         : .35rem;
// // $input-prefix-font-size-sm                   : 1.5rem;
// // $input-prefix-margin-left-sm                 : 2rem;
// // $input-prefix-width-sm                       : calc(100% - 2rem);
// // $input-group-addon-font-size-sm              : 1.15rem;
// // $input-form-text-ml-sm                       : 2rem;
// // $input-validate-mb-sm                        : 2.3rem;
// // $input-label-valid-top-sm                    : 3.7rem;
// // $input-label-invalid-top-sm                  : 3.6rem;

// // $textarea-padding                            : 1.5rem;

// // $input-form-control-margin-bottom            : .5rem;
// $input-form-control-padding-top: $grid-gutter * 0.875;
// $input-form-control-padding-bottom: $grid-gutter * 0.875;

// // $input-disabled-solid-color                  : #BDBDBD;

// // // Input group
// // $input-group-text-bgc                        : #e0e0e0;
// $input-group-form-control-px: $grid-gutter * 0.875;
// $input-group-form-control-py: $grid-gutter * 0.625;

// Dropdown (needed for material Select also)
$dropdown-bg-color: $white !default;
$dropdown-hover-bg-color: $grey-light;
$dropdown-color: $primary-color;
$dropdown-item-height: 40px !default;
$dropdown-menu-padding: 0.5rem !default;
$dropdown-item-font-size: $font-size-small !default;
$dropdown-item-disabled-color: #868e96 !default;
$dropdown-item-transition: all 0.1s linear !default;
$dropdown-menu-animated-animation-duration: 0.55s !default;
$dropdown-menu-animated-animation-timing-function: ease !default;

// Material select (dependency on Dropdown)
$material-select-wrapper-input-dropdown-height: 2.9rem !default;
$material-select-wrapper-input-dropdown-line-height: $material-select-wrapper-input-dropdown-height !default;
$material-select-wrapper-input-dropdown-font-size: 1rem !default;
$material-select-wrapper-input-dropdown-margin: 0.94rem !default;
$material-select-wrapper-input-dropdown-disabled-color: rgba($black, 0.3) !default;
$material-select-wrapper-input-dropdown-disabled-border-bottom-color: rgba($black, 0.3) !default;

$material-select-wrapper-search-wrap-py: 1rem !default;
$material-select-wrapper-search-wrap-mx: 0.7rem !default;
$material-select-wrapper-search-wrap-input-pb: 0.4rem !default;
$material-select-wrapper-span-caret-top: 1rem !default;
$material-select-wrapper-span-caret-font-size: 0.63rem !default;
$material-select-wrapper-span-caret-label-top: -0.88rem !default;

$material-select-dropdown-li-img-my: 0.3rem !default;
$material-select-dropdown-li-img-mx: 0.75rem !default;
$material-select-dropdown-li-optgroup-selected-span: rgba($black, 0.7) !default;
$material-select-dropdown-li-optgroup-span: rgba($black, 0.4) !default;
$material-select-multiple-select-dropdown-height: 0.63rem !default;

$material-select-dropdown-content-min-width: 6.25rem !default;
$material-select-dropdown-content-max-height: 40.625rem !default;
$material-select-dropdown-content-li-color: $black !default;
$material-select-dropdown-content-li-line-height: 1.3rem !default;
$material-select-dropdown-content-li-span-font-size: 0.9rem !default;
$material-select-dropdown-content-li-span-padding: 0.5rem !default;

$material-select-colorful-dropdown-content-padding: 0.5rem !default;
$material-select-colorful-dropdown-content-li-active-color: $white !default;
$material-select-colorful-dropdown-content-li-hover-transition: 0.15s !default;
$material-select-colorful-dropdown-content-li-hover-border-radius: $border-radius-base !default;
