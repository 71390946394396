.logo {
    &__inner {
        display: flex;
        align-items: center;
    }

    &__svg {
        width: $grid-gutter * 10.5;
    }

    // product name text and version number
    &__version {
        font-size: $grid-gutter * 1.125; // =18px
        color: $grey-light-50;
        letter-spacing: 2.3px;
        line-height: $grid-gutter * 1.5;
        margin-left: $grid-gutter * 3;
        text-transform: uppercase;

        @include bp-max(lg) {
            font-size: $grid-gutter * 0.9375; // =15px
        }

        @include bp-min(xl) {
            font-size: $grid-gutter * 1.36; // =cca 21px
        }
    }

    // special case of logo for login page
    .loginPage & {
        z-index: $z-index-4;

        &__inner {
            @include login-layout-inner;

            display: block;
            margin: auto;
        }

        &__svg {
            width: 100%;
        }

        &__version {
            margin-bottom: auto;
            text-align: right;
            width: 70%;
            margin-left: 30%;
            transform: translateX(4px);
        }
    }
}
