.develStatusBar {
    position: fixed;
    z-index: $z-index-status-bar;
    background-color: $red;
    padding: 0.25rem 1rem;
    font-size: 14px;
    color: $white;
    width: 100%;
    text-align: center;
}

.recoveryStatusBar {
    z-index: $z-index-status-bar;
    background-color: $red;
    padding: 0.25rem 1rem;
    font-size: 14px;
    color: $white;
    width: 100%;
    text-align: center;
}
