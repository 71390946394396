/* stylelint-disable */
// Mixins
// Bootstrap Mixins
@use "sass:math";

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);

    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);

    @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $next: breakpoint-next($name, $breakpoints);

    @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    }
    @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);

    @if $max {
        @media (max-width: $max) {
            @content;
        }
    }
    @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max == null {
        @include media-breakpoint-up($lower, $breakpoints) {
            @content;
        }
    }
    @else if $min == null {
        @include media-breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    }
    @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    }
    @else if $min == null {
        @include media-breakpoint-down($name, $breakpoints) {
            @content;
        }
    }
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

// Background color
@mixin bg-variant($parent, $color) {
    #{$parent} {
        background-color: $color !important;
    }

    a#{$parent},
    button#{$parent} {
        @include hover-focus {
            background-color: darken($color, 10%) !important;
        }
    }
}

// Typography
@mixin text-emphasis-variant($parent, $color) {
    #{$parent} {
        color: $color !important;
    }

    a#{$parent} {
        @include hover-focus {
            color: darken($color, 10%) !important;
        }
    }
}

// Placeholder
@mixin placeholder {
    &::placeholder {
        @content;
    }
}

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
    margin-right: auto;
    margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

@mixin make-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: math.div($grid-gutter-width, -2);
    margin-left: math.div($grid-gutter-width, -2);
}

@mixin make-col-ready {
    position: relative;

    // Prevent columns from becoming too narrow when at smaller grid tiers by
    // always setting `width: 100%;`. This works because we use `flex` values
    // later on to override this initial width.
    width: 100%;
    min-height: 1px; // Prevent collapsing
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
}

@mixin make-col($size, $columns: $grid-columns) {
    flex: 0 0 percentage(math.div($size, $columns));

    // Add a `max-width` to ensure content within each column does not blow out
    // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
    // do not appear to require this.
    max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
    $num: math.div($size, $columns);

    margin-left: if($num == 0, 0, percentage($num));
}

@mixin clearfix {
    &::after {
        display: block;
        clear: both;
        content: '';
    }
}

@mixin float-left {
    float: left !important;
}

@mixin float-right {
    float: right !important;
}

@mixin float-none {
    float: none !important;
}

// CSS image replacement
@mixin text-hide($ignore-warning: false) {
    // //stylelint-disable-next-line font-family-no-missing-generic-family-keyword
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;

    @if $ignore-warning != true {
        @warn "The `text-hide()` mixin has been deprecated as of v4.1.0. It will be removed entirely in v5.";
    }
}

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

@mixin invisible($visibility) {
    visibility: $visibility !important;
}

// MDB Mixins
// Set the color of the button and badge
@function set-notification-text-color($color) {
    @if lightness($color) > 80 {
        @return $grey-xdark; // Lighter backgorund, return dark color
    }
    @else {
        @return $white-light; // Darker background, return light color
    }
}

// Set the color of the button and badge
@function set-hover-text-color($color) {
    @if lightness($color) > 80 {
        @return $black-light; // Lighter backgorund, return dark color
    }
    @else {
        @return $white-85; // Darker background, return light color
    }
}

// Make button
@mixin make-button($name, $color) {
    .btn-#{$name} {
        border-color: $color;
        background-color: $color;
        color: set-notification-text-color($color);

        &-disabled,
        &[disabled],
        &:disabled {
            border-color: $color;
            background-color: $color;
            color: set-notification-text-color($color);
            opacity: 0.35;

            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
            }
        }

        &:hover {
            background-color: darken($color, 5%);
            border-color: darken($color, 5%);
            color: set-hover-text-color($color);
        }

        &:focus,
        &:active,
        &.active {
            outline: 0;
            background-color: darken($color, 10%);
            border-color: darken($color, 10%);
            color: set-hover-text-color($color);
        }

        &.dropdown-toggle {
            background-color: $color;
            border-color: $color;

            &:hover,
            &:focus {
                background-color: darken($color, 5%);
                border-color: darken($color, 5%);
                color: set-hover-text-color($color);
            }
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active,
        .show > &.dropdown-toggle {
            outline: 0;
            box-shadow: $z-depth-1-half;
            background-color: darken($color, 10%);
            border-color: darken($color, 10%);
            color: set-hover-text-color($color);
        }

        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
            box-shadow: $z-depth-1-half;
            outline: 0;
        }
    }

    .#{$name}-ic {
        color: $color;

        &:hover,
        &:focus {
            color: $color;
        }
    }

    a.btn:not([href]):not([tabindex]),
    a.btn:not([href]):not([tabindex]):focus,
    a.btn:not([href]):not([tabindex]):hover {
        color: set-notification-text-color($color);
    }

    table {
        &.table {
            a {
                &.btn {
                    &.btn-#{$name} {
                        color: set-notification-text-color($color);
                    }
                }
            }
        }
    }
}

// Make outline button
@mixin make-outline-button($name, $color) {
    .btn-outline-#{$name} {
        border-color: rgba($color, 0.7);
        background-color: transparent;
        color: $color;

        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &.active {
            outline: 0;
            border-color: rgba($color, 1);
            background-color: transparent;
            color: rgba($color, 1);
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active,
        .show > &.dropdown-toggle {
            box-shadow: $z-depth-1-half;
            background-color: transparent;
            border-color: $color;
        }

        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
            box-shadow: $z-depth-1-half;
        }

        &-disabled,
        &[disabled],
        &:disabled {
            opacity: 0.35;

            &:active,
            &:focus,
            &:hover {
                box-shadow: none;
            }
        }
    }
}

// Make gradient
@mixin make-gradient($name, $value) {
    .#{$name}-gradient {
        background: linear-gradient(40deg, map-get($value, start), map-get($value, end)) !important;
    }
}

// Make gradient
@mixin make-gradient-rgba($name, $value) {
    .#{$name}-gradient-rgba {
        background: linear-gradient(40deg, map-get($value, start), map-get($value, end)) !important;
    }
}

// Make gradient button
@mixin make-gradient-button($name, $value) {
    .btn {
        &.#{$name}-gradient {
            transition: .5s ease;
            color: $white-base;

            &:hover,
            &:focus,
            &:active,
            &:active:focus &.active {
                background: linear-gradient(lighten(map-get($value, start), 5%), lighten(map-get($value, end), 5%));
            }
        }
    }
}

// Button size
@mixin button-size($padding-y, $padding-x, $font-size, $height) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    height: $height;
}

@mixin make-badge($name, $color) {
    .badge-#{$name} {
        background-color: $color;
        color: set-notification-text-color($color);
    }
}

// Make input
@mixin make-input($margin-bottom, $label-font-size, $label-active-font-size, $top, $prefix-font-size, $margin-left, $width, $margin-left-2) {
    .validate {
        margin-bottom: $margin-bottom;
    }

    label {
        font-size: $label-font-size;

        &.active {
            font-size: $label-active-font-size;
        }
    }

    .prefix {
        top: $top;
        font-size: $prefix-font-size;

        ~ input,
        ~ textarea {
            margin-left: $margin-left;
            width: $width;
        }

        ~ label {
            margin-left: $margin-left;
        }

        ~ .form-text {
            margin-left: $margin-left-2;
        }
    }
}

// Make navbar
@mixin make-navbar($color-0, $background-image, $color, $color-2, $color-3) {
    .navbar-nav {
        .nav-item {
            .nav-link {
                &.disbled {
                    color: $color-0;

                    &:hover {
                        color: $color-0;
                    }
                }
            }
        }
    }

    .navbar-toggler-icon {
        background-image: $background-image;
        cursor: pointer;
    }

    .breadcrumb,
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: rgba($color, 0.7);
                transition: $navbar-nav-transition;

                &:hover {
                    color: $color-2;
                }
            }

            &.active > .nav-link {
                color: invert(rgba($color, 0.7));
                background-color: $color-3;

                &:hover {
                    color: invert($color);
                }
            }
        }
    }

    .navbar-toggler {
        color: $color;
    }

    form {
        .md-form {
            input {
                border-bottom: 1px solid $color;

                &:focus:not([readonly]) {
                    border-color: $input-md-focus-color;
                }
            }

            .form-control {
                color: $color;

                @include placeholder {
                    color: $color;
                    font-weight: $navbar-font-weight;
                }
            }
        }
    }
}

// Make floating button
@mixin make-btn-floating($width, $height, $font-size, $line-height) {
    width: $width;
    height: $height;

    i {
        font-size: $font-size;
        line-height: $line-height;
    }
}

// Keyframes
@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

// Scroll bar and scroll spy width and height
@mixin scroll-width($scrollbar-width) {
    width: $scrollbar-width;
}

@mixin scroll-height($scrollbar-height) {
    height: $scrollbar-height;
}

// Scroll spy font-weight
@mixin scrollspy-font-weight($scrollspy-font-weight) {
    font-weight: $scrollspy-font-weight;
}

// Switch width and height
@mixin switch-width-height($switchWidth, $switchHeight) {
    width: $switchWidth;
    height: $switchHeight;
}

// Make Box-shadows
@mixin box-shadows($shadow...) {
    box-shadow: $shadow;
}

// Make Transition
@mixin transition-main($transition...) {
    transition: $transition;
}

// Make border-radius scrollspy
@mixin scrollspy-border-radius($scrollspy-radius) {
    border-radius: $scrollspy-radius;
}

// Make border-radius scrollspy 4rows
@mixin scrollspy-border-radius-4rows($top-left, $top-right, $bottom-right, $bottom-left) {
    border-radius: $top-left $top-right $bottom-right $bottom-left;
}

//Make animation for progresss
@mixin progress-animation-default($animation...) {
    animation: $animation;
}

//Make transform
@mixin transform($transform...) {
    transform: $transform;
}
