.loginForm {
    @include login-layout-inner;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding-top: $grid-gutter * 1.5;
    position: relative;

    &--disabled {

        .loginForm__fieldset {
            opacity: 0.8;
        }
    }

    &__bg {
        position: relative;
        width: 29%;

        &::after {
            content: "";
            position: absolute;
            left: 100%;
            top: 0;
            height: 100%;
            width: 60%;
            background-image: $gradient-primary;
            animation-name: orange-glow-anim;
            animation-fill-mode: both;
            animation-duration: 0.6s;
            animation-delay: 0.4s;
            animation-timing-function: ease-out;
        }


        &--hideLeft {

            &::after {
                opacity: 0;
            }
        }
    }

    // left side of screen with dark bg
    &__wrap {
        @include login-layout-wrap;

        max-width: none;
        position: relative;
        height: 100%;
        background-color: $primary-black;
        z-index: $z-index-3;
        animation-name: loginForm-wrap-reverse;
        animation-duration: 0.4s;
        animation-timing-function: ease-out;
        animation-fill-mode: both;

        @include bp-max(lg) {
            animation-name: loginForm-wrap-reverse-md;
        }


        @include bp-max(xs) {
            animation-name: loginForm-wrap-reverse-xs;
        }


        &--hideLeft {
            animation-name: loginForm-wrap;
            animation-timing-function: ease-in;
            animation-direction: normal;

            @include bp-max(lg) {
                animation-name: loginForm-wrap-md;
            }


            @include bp-max(xs) {
                animation-name: loginForm-wrap-xs;
            }
        }

        &--stickedLeft {
            width: 0;
            min-width: 0;
            padding: 0;
            overflow: hidden;
            animation: none;

        }


    }

    &__fieldset {
        transition: opacity $transition-global;
        opacity: 1;
    }

    &__title {
        color: $white-50;
        margin-bottom: $grid-gutter * 1.5;
    }

    &__perex {
        color: $white-50;
        font-size: $grid-gutter * 0.75;
        margin-bottom: $grid-gutter * 1.5;
    }

    &__btn--submit {
        width: 100%;
        margin-bottom: $grid-gutter * 3;

        .icon {
            @include center-y;

            position: absolute;
            right: $grid-gutter * 0.25;
        }
    }

    &__btn--lostPass {
        text-align: left;
    }

    &__btn--ssoLogin {
        text-align: right;
    }

    &__btnGroup {
        display: flex;
        justify-content: space-between;
    }
}

// animation for hide login form
@include keyframes(loginForm-wrap) {
    0% {
        width: 33%;
        min-width: $loginForm-minWidth;
        transform: translateX(0);
    }

    50% {
        width: 33%;
        min-width: $loginForm-minWidth;
        transform: translateX(-100%);
    }

    100% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }
}


// animation for md breakpoint
@include keyframes(loginForm-wrap-md) {
    0% {
        width: 33%;
        min-width: $loginForm-minWidth-md;
        transform: translateX(0);
    }

    50% {
        width: 33%;
        min-width: $loginForm-minWidth-md;
        transform: translateX(-100%);
    }

    100% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }
}


// animation for xs breakpoint
@include keyframes(loginForm-wrap-xs) {
    0% {
        width: 100%;
        min-width: $loginForm-minWidth-xs;
        transform: translateX(0);
    }

    50% {
        width: 100%;
        min-width: $loginForm-minWidth-xs;
        transform: translateX(-100%);
    }

    100% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }
}


// reversed animation
@include keyframes(loginForm-wrap-reverse) {
    0% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }

    50% {
        width: 100%;
        min-width: $loginForm-minWidth;
        transform: translateX(-100%);
    }

    100% {
        width: 100%;
        min-width: $loginForm-minWidth;
        transform: translateX(0);
    }
}


// reversed animation for md breakpoint
@include keyframes(loginForm-wrap-reverse-md) {
    0% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }

    50% {
        width: 100%;
        min-width: $loginForm-minWidth-md;
        transform: translateX(-100%);
    }

    100% {
        width: 100%;
        min-width: $loginForm-minWidth-md;
        transform: translateX(0);
    }
}


// reversed animation for xs breakpoint
@include keyframes(loginForm-wrap-reverse-xs) {
    0% {
        width: 0;
        min-width: 0;
        transform: translateX(-100%);
        padding: 0;
        overflow: hidden;
    }

    50% {
        width: 100%;
        min-width: $loginForm-minWidth-xs;
        transform: translateX(-100%);
    }

    100% {
        width: 100%;
        min-width: $loginForm-minWidth-xs;
        transform: translateX(0);
    }
}


// glow-orange-animation
@include keyframes(orange-glow-anim) {
    0% {
        opacity: 0 !important; // stylelint-disable-line
        width: 0%;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1 !important; // stylelint-disable-line
        width: 60%;
        transform: translateX(0);
    }
}
