$dropdown-item-bg: $secondary-green-light !default;
$dropdown-item-hover-bg: $secondary-green-white !default;
$dropdown-item-active-bg: $default-bg-light !default;
$dropdown-item-active-color: $secondary-color !default;

.dropdown {
    // dropdown menu in vertical navbar
    .navbar--vertical & {
        transition: background-color $transition-global;

        // first level links in dropdown-menu
        & > .nav-link {
            &:hover {
                background-color: $black-10;
            }
        }

        // if dropdown is open
        &.show {
            background-color: $dropdown-item-bg;
            z-index: $z-index-1;
        }
    }
}

.dropdown-menu {
    // dropdown menu in vertical navbar
    .navbar--vertical & {
        // important is necessary here, rewrite inline rule from JS
        position: relative !important; //stylelint-disable-line
        transform: none !important; //stylelint-disable-line
        z-index: $z-index;
        margin-top: 0;
        border: 0;
        display: block;
        max-height: 0;
        overflow: hidden;
        will-change: max-height;
        transition-property: max-height, background-color;
        transition-duration: $transition-time;
        transition-timing-function: ease-out;

        // wrap around dropdown menu items
        & > div[role=menu] {
            will-change: opacity, transform;
            opacity: 0;

            // transform: translateY(-100%);
            transition-property: transform, opacity;
            transition-duration: $transition-time;
            transition-timing-function: ease-in;
        }

        // if dropdown is open
        &.show {
            opacity: 1 !important; /* stylelint-disable-line */ /* overwrite inline JS styles on component*/
            max-height: unset;
            transition-timing-function: ease-in;

            // wrap around dropdown menu items
            & > div[role=menu] {
                transform: translateY(0);
                opacity: 1;
                transition-timing-function: ease-out;
            }
        }

        // item in dropdown - vertical navbar
        .dropdown-item {
            @include nav-link;

            color: $secondary-color-70;
            border-bottom: 1px solid $dropdown-border-color;
            font-weight: 500;
            background-color: $dropdown-item-bg;
            border-left: ($grid-gutter * 0.5) solid $dropdown-border-color;
            position: relative;
            padding-left: 0.75rem;

            &.with-icon {
                padding-left: 0.5rem;
            }

            &__wrap:last-child .dropdown-item {
                border-bottom: 0;
            }

            // hover state of dropdown item
            &:hover {
                color: $brown-dark;
                background-color: $dropdown-item-hover-bg;
            }

            // active state of dropdown item
            &.active,
            &:active,
            &.active:hover,
            &:active:hover {
                color: $dropdown-item-active-color;

                .icon {
                    color: rgba($dropdown-item-active-color, 0.8);
                }

            }

            &.active,
            &.active:hover {
                font-weight: 500;
                background-color: $dropdown-item-active-bg;
                cursor: default;
                pointer-events: none;
                touch-action: none;

                // overlay shadow for link - active state
                &::after {
                    opacity: 0;
                }
            }

            // overlay shadow for link
            &::after {
                @include overlay-shadow-right;
            }

            // kernun chevron for active state
            &__wrap {
                @include kernun-chevron;

                &--active {
                    @include kernun-chevron-active;
                }
            }
        }
    }

    // dropdown item inside another dropdown
    .dropdown .dropdown & .dropdown-item {
        border-left: $grid-gutter solid $dropdown-border-color;

        &:last-child {
            border-bottom: 1px solid $dropdown-border-color;
        }

        &::before {
            left: -$grid-gutter - $grid-gutter * 1.25;
        }

        // active state of dropdown item in dropdown item
        &.active,
        &:active,
        &.active:hover,
        &:active:hover {
            color: $dropdown-item-active-color;
        }
    }
}

// dropdown control button (link)
.nav-link.dropdown-toggle {
    z-index: $z-index-1;
    position: relative;
    border: 0;
    border-bottom: 1px solid $dropdown-border-color;
    background-color: transparent;

    // chevron icon in toggle
    &::after {
        content: url("data:image/svg+xml; utf-8, <svg fill='rgba(255,255,255,0.5)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></svg>");
        width: $grid-gutter * 1.5;
        height: $grid-gutter * 1.5;
        border: 0;
        margin-right: -$grid-gutter * 0.5;
        transition-property: transform, width;
        transition-duration: $transition-time;
        transition-timing-function: ease-in-out;
        transform: scaleY(1);

        // if navbar is collapsed
        .navbar--collapsed & {
            width: 0;
        }
    }

    // kernun chevron for active state (inside mixin)
    @include kernun-chevron;

    // active item state of navigation links
    .nav-item.active > .dropdown > & {
        @include kernun-chevron-active;
    }

    // active item state of navigation links for collapsed navigation
    .navbar--collapsed .nav-item.active > .dropdown:not(.show) > & {
        @include kernun-chevron-non-active;

        color: $default-dark;
    }

    // if dropdown is open
    .dropdown.show > & {
        background-color: $dropdown-border-color;
        font-weight: 500;

        &:hover {
            background-color: $dropdown-item-hover-bg;
        }

        &:-ms-keyboard-active {
            background-color: $white;
        }

        // chevron icon in toggle
        &::after {
            content: url("data:image/svg+xml; utf-8, <svg fill='rgba(55, 45, 35, 0.3)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></svg>");
            transform: scaleY(-1);
        }

        // overlay shadow for link
        .nav-link__inner::before {
            @include overlay-shadow-right;
        }
    }

    // dropdown toggle in dropdown
    .dropdown .dropdown & {
        color: $secondary-color-70;
        background-color: $dropdown-item-bg;
        border-left: ($grid-gutter * 0.5) solid $dropdown-border-color;
        font-weight: 500;

        &:hover {
            color: $brown-dark;
            background-color: $dropdown-item-hover-bg;
        }

        // chevron icon in toggle
        &::after {
            content: url("data:image/svg+xml; utf-8, <svg fill='rgba(55, 45, 35, 0.3)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px' viewBox='0 0 24 24'><path d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /></svg>");
        }

        // overlay shadow for link
        .nav-link__inner::before {
            @include overlay-shadow-right;
        }
    }

    // first level of navigation links
    .nav-item > .dropdown:not(.show) > & {
        border-bottom: 0;
    }

    // special case of navigation items
    &--user {
        position: relative;

        .icon {
            position: relative;
            z-index: $z-index-1;
        }

        .icon-bg {
            @include center-y;

            width: $grid-gutter * 2;
            height: $grid-gutter * 2;
            border-radius: $grid-gutter;
            left: $grid-gutter * 0.75;
            position: absolute;
            background-color: $grey-xdark-30;
        }

        .dropdown.show & {
            .icon-bg {
                background-color: $black-10;
            }
        }
    }
}
