.navbar-nav {
    list-style: none;

    .navbar--vertical & {
        flex-direction: column;
        direction: ltr;
    }

    &.top {
        margin-bottom: auto;
    }

    &.bottom {
        margin-top: auto;
    }

    .nav-item {
        // TODO: overlay shadow for link
        // &::before {
        //     @include overlay-shadow-right;
        // }
        border-bottom: 1px solid $grey-xdark-30;

        &:first-child {
            border-top: 1px solid $grey-xdark-30;
        }

        &--user {
            border-bottom: 0;
        }

        // button for menu collapsing
        &__hide-menu {
            margin-bottom: $leftMenu-link-height;
            margin-top: $leftMenu-link-height;
            border-top: 1px solid $grey-xdark-30;

            .icon {
                transition: transform $transition-global;
                transform: rotate(0deg);
            }

            // second item is first real nav link (first is hide menu)
            & + .nav-item {
                border-top: 1px solid $grey-xdark-30;
            }
        }

        // active state of nav item for collapsed navigation
        .navbar--collapsed &.active {
            background-color: $default-bg-light;
        }

        .navbar--collapsed & .dropdown-menu.show {
            max-height: 0;
        }
    }

    // navigation in dark navbar
    .navbar.navbar-dark & {
        .nav-item {
            .nav-link {
                @include nav-link;

                &:last-child {
                    padding-top: $grid-gutter * 0.5;
                    padding-bottom: $grid-gutter * 0.5;

                    .icon {
                        background: $grey-xdark-30;
                        padding: $grid-gutter * 0.25;
                        width: $grid-gutter * 2;
                        height: $grid-gutter * 2;
                        border-radius: $grid-gutter;
                    }
                }
            }

            // first level nav-link
            & > .nav-link {
                &:hover {
                    background-color: $black-10;
                }
            }

            // last link with user
            &.nav-item--user {
                .nav-link {
                    padding-top: $grid-gutter * 0.5;
                    padding-bottom: $grid-gutter * 0.5;

                    .icon {
                        background: $grey-xdark-30;
                        padding: $grid-gutter * 0.25;
                        width: $grid-gutter * 2;
                        height: $grid-gutter * 2;
                        border-radius: $grid-gutter;
                        margin-left: -$grid-gutter * 0.25;
                        margin-right: $grid-gutter * 0.5;
                    }
                }
                // state for active link (dropdown show)
                & .show .nav-link {
                    .icon {
                        background: $secondary-color-10;
                    }
                }
            }
        }
    }

    // link if nav is collapsed
    .navbar--collapsed & {
        .nav-item__hide-menu .icon {
            transform: rotate(180deg);
        }

        .nav-link__inner > span {
            display: none;
        }
    }
}
