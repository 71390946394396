.popMessage {
    @include center;

    background-color: $primary-green;
    border-radius: $border-radius;
    box-shadow: $z-depth-1;
    padding: $grid-gutter * 2;
    color: $white-light;
    font-size: $grid-gutter * 0.875;
    position: absolute;
    width: 90%;
    animation-name: popMessage-animation;
    animation-duration: $transition-time;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
    animation-direction: reverse;

    &--isVisible {
        animation-name: popMessage-animation;
        animation-duration: $transition-time;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
        animation-direction: normal;
    }
}

@keyframes popMessage-animation {
    0% {
        opacity: 0;
        transform: translate(-50%, -75%);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
