.navbar {

    &ResetBtn {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    &Icons {
        display: flex;

        &__container {
            margin-right: 0.5 * $grid-gutter;
            border-right: 1px solid rgba(55, 45, 35, 0.3);
            display: flex;
            flex-direction: column;
            padding: 0 0.2 * $grid-gutter;
            max-height: 2.5 * $grid-gutter;
            flex-wrap: wrap-reverse;
        }

        &__icon {
            height: 1.25 * $grid-gutter;
        }
    }

    &--vertical {
        height: 100%;
        width: $leftMenu-width;
        align-items: flex-start;
        padding: 0;
        position: relative;
        transition: width $transition-global;

        &::after {
            content: "";
            width: 5px;
            height: 100;
            position: absolute;
            top: 0;
            right: 5px;
        }

        .dropdown-menu {
            position: static !important; //stylelint-disable-line
            transform: none !important; //stylelint-disable-line

        }
    }

    &--horizontal {
        height: $header-height;
    }

    &--collapsed {
        width: $leftMenu-width-collapsed;
    }

    &-brand {
        padding-left: $grid-gutter;
        padding-right: $grid-gutter * 3;
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid $grey-xdark-30;
    }

    &-collapse {
        padding: 0 $grid-gutter;
        justify-content: space-between;
    }

    .md-form {
        &.form-inline {
            margin-bottom: 0;
        }
    }
}

.differ {

    &--ruleset {
        padding-left: 3 * $grid-gutter;
    }

    &--warningSection {
        padding-left: $grid-gutter;
    }

    &--warningContent {
        padding-left: 2 * $grid-gutter;
    }
}
