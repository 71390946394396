.breadcrumb {
    background-color: transparent;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
    margin-left: $grid-gutter * 0.75;
    font-size: $grid-gutter * 0.875;
    color: $secondary-color;
    font-weight: 500;
    width: 50%;
    height: $grid-gutter * 3;
    line-height: 1.2;

    & .icon {
        margin-right: $grid-gutter * 0.5;
    }

    &-item {
        display: flex;
        align-items: center;
        height: 100%;

        & + .breadcrumb-item {
            &::before {
                color: currentColor;
                font-size: 1rem;
                margin-top: -0.125rem;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .breadcrumb-item {
        font-size: 0;

        & + .breadcrumb-item {
            &::before {
                font-size: 0;
            }
        }
    }
}