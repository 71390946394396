.card {
    box-shadow: $box-shadow-card;
    border-radius: $border-radius;
    box-sizing: border-box;
    transition-property: box-shadow, opacity;
    transition-duration: $transition-time;
    transition-timing-function: ease-in;
    height: 100%;
    max-height: 85vh;
    min-width: 20 * $grid-gutter;


    &--warning {
        box-shadow: 0 0 6px $primary;
    }

    &--smaller {
        max-height: 77vh;
        height: 77vh;
    }

    &__height {
        height: 85vh;
    }

    &--withoutMH {
        max-height: none;
    }

    &--withLongHeaderText {
        min-width: $grid-gutter * 25;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: $border-bottom;

        & .btn {
            margin: $grid-gutter * 0.5 $grid-gutter * 1.5;
            height: $grid-gutter * 2;
        }

        & .card-title {
            border-bottom: 0;
        }

        &--actions {
            display: flex;
            justify-content: flex-end;

        }
    }

    &-title {
        font-size: $grid-gutter * 1.125;
        padding: $grid-gutter * 1.125 $grid-gutter * 1.25 $grid-gutter * 0.5 $grid-gutter * 1.25;
        color: $secondary-color-70;
        margin: 0;
        border-bottom: $border-bottom;
        max-width: initial;
    }

    // :not(.card__header) &-title {
    //     border-bottom: 1px solid $grey-light;
    // }

    &-body {
        padding: ($grid-gutter * 1.5) ($grid-gutter * 1.25);

        min-width: 15 * $grid-gutter;
    }

    &__table tbody {
        position: absolute;
        width: 100%;
        height: calc(100% - #{$card-title-height} - #{$card-footer-height});
        overflow: auto;
    }

    &-footer,
    &__footer {
        margin-top: auto;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        padding: $grid-gutter * 0.5;
        border-top: $border-top;

        .btn {
            border: 0;
            padding: 0 1.5rem;
            font-size: $font-size-tiny;
        }
    }

    &--hover {
        box-shadow: 0px 5px 16px -2px $black-30;
    }

    &--forUpgrade {
        height: auto;
    }
}

.info-card {
    background-color: $secondary-color-10;
    padding: $grid-gutter;
    margin: $grid-gutter;
}
