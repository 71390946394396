$loader-text-color: #ffffff !default;
$loader-position-r: 4rem !default;
$loader-margin-r: 1rem !default;
$loader-margin-b: 0.5rem !default;

.loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: $z-index-5;

    &__svg {
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    &__label {
        font-size: $font-size-small;
        line-height: 1.286;
        color: $loader-text-color;

        &--color {
            color: $secondary-color-40;
        }
    }

    &--horizontal {
        flex-direction: row-reverse;
        right: $loader-position-r;
        transform: translateY(-50%);

        // top: 50%;
        top: 6rem;

        .loader__label {
            text-align: right;
            margin-right: $loader-margin-r;
        }
    }

    &--vertical {
        flex-direction: column;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;

        .loader__svg {
            margin-bottom: $loader-margin-b;
        }
    }

    &--middle {
        position: absolute;
        top: 48%;
        left: 48%;
    }

    &--column {
        flex-direction: column;

    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
