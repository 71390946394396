@charset "UTF-8";

// Original Bootstrap
@import "bootstrap";

// KERNUN helpers (bootstrap, variables, mixins, fonts) - must be on top
@import "helpers";

// MDBReact CORE
// @import "mdbreact/dist/scss/core/mixins"; - we moved it to KERNUN helpers
@import "mdbreact/dist/scss/core/colors";
@import "mdbreact/dist/scss/core/variables";
@import "mdbreact/dist/scss/core/global";
@import "mdbreact/dist/scss/core/helpers";

// @import "mdbreact/dist/scss/core/typography"; - we use our own typo
@import "mdbreact/dist/scss/core/masks";
@import "mdbreact/dist/scss/core/waves";

// MDBReact FREE Components
// @import "mdbreact/dist/scss/free/animations-basic";
// @import "mdbreact/dist/scss/free/modules/animations-extended/module";
// @import "mdbreact/dist/scss/free/buttons"; - we include customized copy of this file below
@import "components/generic/button"; // must be right here
@import "mdbreact/dist/scss/free/cards";
@import "mdbreact/dist/scss/free/dropdowns";
@import "mdbreact/dist/scss/free/input-group";
// @import "mdbreact/dist/scss/free/navbars";
@import "mdbreact/dist/scss/free/pagination";

// @import "mdbreact/dist/scss/free/badges";
// @import "mdbreact/dist/scss/free/modals";
// @import "mdbreact/dist/scss/free/carousels";
// @import "mdbreact/dist/scss/free/forms";
@import "mdbreact/dist/scss/free/msc";

// @import "mdbreact/dist/scss/free/footers";
@import "mdbreact/dist/scss/free/list-group";
// @import "mdbreact/dist/scss/free/tables";
@import "mdbreact/dist/scss/free/depreciated";
@import "mdbreact/dist/scss/free/steppers";
@import "mdbreact/dist/scss/free/loader";
@import "mdbreact/dist/scss/free/treeview";

// MDBReact Free addons
@import "mdbreact/dist/scss/addons/datatables";
@import "mdbreact/dist/scss/addons/datatables-select";

// @import "mdbreact/dist/scss/addons/directives";
// @import "mdbreact/dist/scss/addons/hierarchical-display";
// @import "mdbreact/dist/scss/addons/flags";
// @import "mdbreact/dist/scss/addons/rating";

// MDBReact PRO Components
// @import "mdbreact/dist/scss/pro/material-select";


@import "tippy.js/dist/tippy.css";

.tippy-box:has(.tippy-content > div:empty) {
    // Empty tooltip hidden via css, because in react it is unreasonably hard to
    // not render tooltip with inner component that rendered null
    display: none;
}

//taken from react-input-range
@import "reactInputRange/index.scss";



// KERNUN STYLES
@import "changes";
@import "layout";
@import "components";
@import "widgets";
@import "pages";
