// this is the file with animations


.bounce-in-left {
    animation: bounce-in-left 2s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.swing-in-right-fwd {
    animation: swing-in-right-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.bounce-in-top {
    animation: bounce-in-top 1s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.bounce-in-bottom {
    animation: bounce-in-bottom 1s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.routing-table-jump {
    animation: routing-table-jump 2s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.slideDown {
    animation: slide-down 2s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.slide-to-top-and-dissapear {
    animation: slide-to-top-and-dissapear 1s cubic-bezier(0.550, 0.055, 0.675, 0.190) both;
}

.slide-in-right {
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right-flex {
    animation: flex-grow-animation 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.remove-height {
    animation: remove-height 0.5s ease both;
}

.closeWithLockExit {
    animation: closeWithLockExit 1s both;
}

.slide-out-left {
    animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes slide-out-left {
    0% {
        transform: translateX(0);
        opacity: 1;
        position: absolute;
    }
    100% {
        transform: translateX(-200px);
        opacity: 0;
        position: absolute;
    }
}

@keyframes closeWithLockExit {
    0% {
        transform: rotate3d(1, 1, -6, 40deg);
        color: $primary;
        animation-timing-function: ease-out;
    }
    5% {
        transform: rotate3d(1,1,-3, 30deg);
        animation-timing-function: ease-in;
    }
    15% {
        transform: rotate3d(1,1,1, 15deg);
        animation-timing-function: ease-out;
    }
    25% {
        transform: rotate3d(1,1,1, 5deg);
        animation-timing-function: ease-in;
    }
    85% {
        transform: translateY(20px);
        opacity: 1;
    }
    100% {
        transform: translateY(20px);
        opacity: 0;
        display: none;
    }
}

@keyframes slide-in-right {
    0% {
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}



@keyframes flex-grow-animation {
    0% {
        position: absolute;
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        position: relative;
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes routing-table-jump {
    0% {
        transform: scale(0.66,1) translateY(-100%);
        transform-origin: right top;
        animation-timing-function: ease-in;
        opacity: 0.5;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(-65px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-left {
    0% {
        transform: translateX(-600px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateX(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateX(-68px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateX(-28px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateX(-8px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
}

@keyframes slide-to-top-and-dissapear {
    0% {
        transform: translateY(0);
        opacity: 1;
        max-height: 80rem;
    }
    100% {
        transform: translateY(-1000px);
        opacity: 0;
        max-height: 0rem;
    }
}

@keyframes remove-height {
    0% {
        max-height: 85vh;
    }
    100% {
        max-height: 0;
        overflow: hidden;
    }
}

@keyframes bounce-in-top {
    0% {
        transform: translateY(-60px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(-8px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(-3px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(-1px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-bottom {
    0% {
        transform: translateY(60px);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: translateY(0);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: translateY(8px);
        animation-timing-function: ease-in;
    }
    72% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
    81% {
        transform: translateY(3px);
        animation-timing-function: ease-in;
    }
    90% {
        transform: translateX(0);
        animation-timing-function: ease-out;
    }
    95% {
        transform: translateY(1px);
        animation-timing-function: ease-in;
    }
    100% {
        transform: translateY(0);
        animation-timing-function: ease-out;
    }
}

@keyframes slide-down {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 40vh;
    }
}

@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@keyframes shine-avatar {
    0% {
        background-position: -100px + 68px;
    }
    40% {
        background-position: 140px + 68px;
    }
    100% {
        background-position: 140px + 68px;
    }
}


@keyframes swing-in-right-fwd {
    0% {
        transform: rotateY(-100deg);
        transform-origin: right;
        opacity: 0;
    }
    100% {
        transform: rotateY(0);
        transform-origin: right;
        opacity: 1;
    }
}
