.languages {
    padding-top: 0;
    z-index: $z-index-4;

    &__inner {
        @include login-layout-inner;
    }

    &__title {
        margin-bottom: $grid-gutter * 1.5;
    }

    &__btnGroup {
        display: flex;
        justify-content: space-between;
        margin-left: -$grid-gutter;
        margin-right: -$grid-gutter;

        .btn {
            width: 50%;
            margin: 0 $grid-gutter;
        }
    }
}
