.toggleBox {
    display: flex;
    flex-direction: column;
    border: 2px solid $input-border-color;
    width: 100%;
    min-width: 6.5 * $grid-gutter;
    font-family: Encode Sans, medium;
    height: 100%;
    max-height: $grid-gutter * 16.25;
    min-height: $grid-gutter * 2.5;
    border-radius: $border-radius;
    transition-property: border;
    transition-duration: $transition-time;
    transition-timing-function: ease-in-out;
    position: relative;
    background-color: $white;

    &__label {
        margin: 0;
        min-width: $grid-gutter * 8.75;
        color: $grey-xdark-30;
    }

    &__labelSlide {
        position: absolute;
        top: -0.875 * $grid-gutter;
        left: 25%;
        padding: 0 $grid-gutter * 0.675;

        &--active {
            color: $primary-green;
        }
        &--leftAlign {
            left: 5%;
        }
    }

    &--active {
        border: 2px solid $primary-green;
    }

    &--isClosed {
        height: unset;
        overflow: hidden;
    }

    &--isDisabled {
        opacity: 0.8;
        cursor: not-allowed;
    }

    &--clicable {
        cursor: pointer;
    }
    // box insides
    &__content {
        font-size: $grid-gutter * 0.685;
        padding: $grid-gutter 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        margin: $grid-gutter * 0.5 $grid-gutter * 1.25 $grid-gutter $grid-gutter * 1.25;
    }

    &__button {
        min-height: $grid-gutter * 2;
        height: $grid-gutter * 2;
        margin: 0 $grid-gutter * 3.5 $grid-gutter 1.5;
        box-shadow: none;

        &--active {
            border-color: $primary-green;
            color: $primary-green;
            box-shadow: none;

            &:hover, &:active, &:focus {
                border-color: $primary-green;
                color: $primary-green;
                box-shadow: none;
            }
        }
    }
}

input[type=checkbox].toggleBox__input {
    display: none;

    & + .toggleBox__box > div {
        border-radius: inherit;
        background-color: transparent;
        transition-property: transform, background-color;
        transition-duration: $transition-time;
        transition-timing-function: cubic-bezier(1,0,0,1);
    }

    &:checked + .toggleBox__box > div {
        transform: translate3d(0, 20px, 0);
    }
}
