.icon {
    fill: currentColor;

    path {
        fill: currentColor;
    }

    .btn & {
        opacity: 0.8;
        width: 1.5 * $grid-gutter;
        height: 1.5 * $grid-gutter;
        margin: 0 0.25 * $grid-gutter 0 -0.5 * $grid-gutter;


    }

    &.menu {
        margin: 0;

        &--small {
            height: 16px;
            width: 20px;
        }
    }

    .btn--justIcon & {
        margin: 0;
    }

    .btn &.loading {
        color: unset;
    }

    &--primary {
        color: $primary-color;
    }

    &--secondary {
        color: $secondary-color;
    }

    &--gold {
        color: $gold;
    }

    &--red {
        color: $error-color;
    }

    &--black {
        color: $black;
    }

    &--grey {
        color: $secondary-color-10;
    }

    &--greyOff {
        color: $grey-off;
    }

    &--disabled {
        color: $secondary-color-10;
        //opacity: 0.7;
    }

    &--textColor {
        color: $secondary-color-40;
    }

    &--yellow {
        color: $yellow;
    }

    &--notVertical {
        vertical-align: baseline;
    }

    &--clicable {
        cursor: pointer;
    }

    &--help {
        cursor: help;
    }

    &.drag {
        cursor: grabbing;
    }

    &__rotate {
        animation-name: loader;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

    }

    &.active {
        color: $primary-green;
    }

    &--example {
        vertical-align: text-bottom;
    }

}

.differ--ruleset {
    .close {
        float: unset;
    }
}

.loading {
    color: $primary;
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.call-missed {
    transform: scaleX(-1);
}

.textAsIcon {
    font-size: $grid-gutter;
    font-weight: 500;
    cursor: pointer;


    &.textWithTooltip {
        cursor: pointer;
    }


    &--textColor.textWithTooltip {
        color: $secondary-color-40;
        cursor: pointer;
    }

    &--on {
        color: $primary-green;
    }

    &--off {
        text-decoration: line-through;
        color: $red-30;
    }


}

.dataTableWidget__Row--disable {

    & .icon {
        color: $secondary-color-40 !important; //stylelint-disable-line
    }

    & path {
        fill: $secondary-color-40 !important; //stylelint-disable-line
    }

    & .content-copy path {
        fill: $secondary-color-10 !important; //stylelint-disable-line
    }
}

.lockedIconSize {    
    height: 300px;
    width: 300px;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@media only screen and (max-width: 1200px) {
    .btn--canJustBeIcon {
        font-size: 0;

        .icon {
            margin: 0;
        }
    }
}
