/* mixins to handle breakpoints */
// min breakpoint
@mixin bp-min($name) {
    @if map-has-key($grid-breakpoints, $name) {
        @media (min-width: #{map-get($grid-breakpoints, $name) + 1px}) {
            @content;
        }
    } @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}

// max breakpoint
@mixin bp-max($name) {
    @if map-has-key($grid-breakpoints, $name) {
        @media (max-width: #{map-get($grid-breakpoints, $name)}) {
            @content;
        }
    } @else {
        @warn "Couldn't find a breakpoint named `#{$name}`.";
    }
}

// interval breakpoint
@mixin bp-int($name_min, $name_max) {
    @if map-has-key($grid-breakpoints, $name_min) and map-has-key($grid-breakpoints, $name_max) {
        @media (min-width: #{map-get($grid-breakpoints, $name_min) + 1}) and (max-width: #{map-get($grid-breakpoints, $name_max)}) {
            @content;
        }
    } @else {
        @warn "Couldn't find a breakpoint named `#{$name_min}` or `#{$name_max}`.";
    }
}

// only chrome hack
@mixin chrome {
    .selector:not(*:root) {
        @content;
    }
}

// only firefox hack
@mixin firefox {
    /* stylelint-disable-next-line */
    body:last-child .selector, x:-moz-any-link {
        @content;
    }
}

// layout for left main containers
@mixin login-layout-wrap {
    width: 100%;
    min-width: $loginForm-minWidth;
    max-width: $loginForm-maxWidth-lg;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $grid-gutter * 4;

    @include bp-max(lg) {
        padding: $grid-gutter * 3;
        min-width: $loginForm-minWidth-md;
    }


    @include bp-max(xs) {
        width: 100%;
    }
}

// layout for left wrap containers
@mixin login-layout-inner {
    width: 100%;
    max-width: $loginForm-maxWidth;

    @include bp-max(lg) {
        max-width: $loginForm-maxWidth-md;
    }


    @include bp-max(xs) {
        max-width: 100%;
    }
}

// center absolute positioned element
@mixin center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// center X absolute positioned element
@mixin center-x {
    left: 50%;
    transform: translateX(-50%);
}

// center X absolute positioned element
@mixin center-y {
    top: 50%;
    transform: translateY(-50%);
}

// navigation link
@mixin nav-link {
    padding: ($grid-gutter * 0.75) $grid-gutter;
    height: $leftMenu-link-height;
    display: inline-flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    outline: none;
    transition-property: color, background-color;
    transition-duration: $transition-time;
    transition-timing-function: ease-in-out;

    &__inner {
        display: flex;
        align-items: center;
    }

    .icon {
        margin-right: $grid-gutter * 0.75;
        flex-shrink: 0;
        width: 1.5rem;
    }
}

@mixin label {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: $grid-gutter * 0.5625;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    cursor: text;
    padding-left: 0.3125 * $grid-gutter;
    padding-right: 0.3125 * $grid-gutter;
    font-size: $input-label-font-size;
    font-weight: 600;
    transform: translateY(0.8125 * $grid-gutter);
    background-color: $white;
    color: $input-label-color;
    margin-bottom: 0;
    transform-origin: 0 0;
    line-height: 1;
    letter-spacing: 0.1px;

    // active state of label (on input focus)
    // scale is from font-size 14px converts to 12px accurately
    &.active {
        font-weight: 600;
        transform: translateY(-8px) scale(0.857); // =12px

    }

    &.file {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: transparent;

    }

    &.form-control__label--buttons {
        border-radius: $grid-gutter;
        z-index: $z-index-5;
    }
}

// overlay shadow for navigation link
@mixin overlay-shadow-right {
    content: "";
    top: 0;
    right: 0;
    width: 5px;
    height: calc(100% + 1px);
    position: absolute;
    background-image: $gradient-shadow-right;
    z-index: $z-index-4;
    opacity: 1;
    transition: opacity $transition-global;
}

// kernun chevron for active state of links in left navigation
@mixin kernun-chevron {
    position: relative;

    &::before {
        top: $leftMenu-link-height * 0.5;
        transform: translate(0, -50%);
        transition: transform $transition-global;
        content: url("data:image/svg+xml; utf-8, <svg fill='rgb(108 128 29)' xmlns='http://www.w3.org/2000/svg' style='width:16x;height:28px;' viewBox='0 0 30 52'><path d='M25.6,17.6C20.4,12.3,15.1,7,9.9,1.8c-2.3-2.3-6-2.3-8.3-0.1C-0.6,4-0.6,7.7,1.7,10c4.4,4.4,8.8,8.9,13.3,13.3  c1.4,1.4,1.4,4,0,5.4C10.6,33.1,6.2,37.6,1.8,42c-2.3,2.3-2.3,6-0.1,8.3s6,2.2,8.3-0.1c5.2-5.3,10.5-10.6,15.7-15.8  C31.4,28.6,31.5,23.5,25.6,17.6z' /></svg>");
        width: $grid-gutter;
        height: $grid-gutter * 1.75;
        position: absolute;
        left: -$grid-gutter * 1.25;
        z-index: $z-index-4;
    }
}

// kernun chevron - active state
@mixin kernun-chevron-non-active {
    &::before {
        transform: translate(0, -50%);
    }
}

// kernun chevron - active state
@mixin kernun-chevron-active {
    &::before {
        transform: translate(100%, -50%);
    }
}

// kernun scrollbar
@mixin scrollbar {

    ::-webkit-scrollbar {
        background-color: transparent;
        cursor: pointer;

    }

    ::-webkit-scrollbar:vertical {
        background-color: transparent;
        cursor: pointer;

    }

    ::-webkit-scrollbar:horizontal {
        background-color: transparent;
        cursor: pointer;

    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:vertical {
        background-color: $brown-dark-10;
        border: ($scrollbar-width * 0.25) solid transparent;
        border-top: ($scrollbar-width * 0.9375) solid transparent;
        border-bottom: ($scrollbar-width * 0.9375) solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        background-color: $brown-dark-10;
        border: ($scrollbar-width * 0.25) solid transparent;
        border-left: ($scrollbar-width * 0.9375) solid transparent;
        border-right: ($scrollbar-width * 0.9375) solid transparent;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-track {
        background-image: url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;transform:rotate(180deg);' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, </svg><svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;position:absolute;bottom:0;transform:'translate(8 8) rotate(180);' viewBox='-4 0 16 16'><path d='M4,0,8,8H0Z' /></svg>");
        background-repeat: no-repeat;
        background-position: top left, bottom left;
    }

    ::-webkit-scrollbar-track:vertical {
        background-image: url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;transform:rotate(180deg);' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, </svg><svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;position:absolute;bottom:0;transform:'translate(8 8) rotate(180);' viewBox='-4 0 16 16'><path d='M4,0,8,8H0Z' /></svg>");
        background-repeat: no-repeat;
        background-position: top left, bottom left;
    }

    ::-webkit-scrollbar-track:horizontal {
        background-image: url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;transform: rotate(-90deg)' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, <svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;transform:rotate(90deg);' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, </svg><svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;position:absolute;bottom:0;transform:'translate(8 8) rotate(180);' viewBox='-4 0 16 16'><path d='M4,0,8,8H0Z' /></svg>");
        background-repeat: no-repeat;
        background-position: left, right;
    }
}


// kernun scrollbar
@mixin scrollbarDark {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-width;
        background-color: transparent;
        cursor: pointer;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $black-10;
        border: ($scrollbar-width * 0.25) solid transparent;
        border-top: ($scrollbar-width * 0.9375) solid transparent;
        border-bottom: ($scrollbar-width * 0.9375) solid transparent;
        background-clip: content-box;
        min-height: $grid-gutter;
    }

    &::-webkit-scrollbar-track {
        background-image: url("data:image/svg+xml; utf-8, <svg fill='rgba(0, 0, 0, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, <svg fill='rgba(0, 0, 0, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;transform:rotate(180deg);' viewBox='-4 -4 16 16'><path d='M4,0,8,8H0Z' /></svg>"), url("data:image/svg+xml; utf-8, </svg><svg fill='rgba(88, 74, 60, 0.1)' xmlns='http://www.w3.org/2000/svg' style='width:24px;height:24px;position:absolute;bottom:0;transform:'translate(8 8) rotate(180);' viewBox='-4 0 16 16'><path d='M4,0,8,8H0Z' /></svg>");
        background-repeat: no-repeat;
        background-position: top left, bottom left;
        border: 0;
        outline: 0;
        box-shadow: none;
    }
}

@mixin noscrollbar {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
        display: none;
    }
}

// TOOLTIPS

$tooltip-alignX: left, right !default;
$tooltip-alignY: top, bottom !default;

// set up where is tooltip button displayed, align tooltip always into container horizontaly
// and under or above verticaly (no overflow over focused content - which is tooltip text about)
@mixin tooltip($alignX: right, $alignY: top, $text: attr(data-tooltip)) {
    @if not index($tooltip-alignX, $alignX) {
        @error "#{$alignX} is not a valid X-axis alignment. Expected one of #{$tooltip-alignX}.";
    }

    @if not index($tooltip-alignY, $alignY) {
        @error "#{$alignY} is not a valid Y-axis alignment. Expected one of #{$tooltip-alignY}.";
    }

    cursor: help;

    // tooltip box element + arrow under box
    &::after,
    &::before {
        position: absolute;
        opacity: 0;
        font-size: $grid-gutter * 0.6875;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-align: left;
        transition-property: transform, opacity;
        transition-duration: $transition-time-slow;
        transition-timing-function: ease-in;
        transition-delay: 0; // important here, reset delay for blur out icon

    }

    // tooltip box element
    &::before {
        content: $text;
        background-color: $grey-xdark;
        color: $grey-light;
        padding: $grid-gutter;
        border-radius: $grid-gutter * 0.5;
        min-width: $grid-gutter * 10;
        box-shadow: 0 ($grid-gutter * 0.25) ($grid-gutter * 0.5) -3px $secondary-color-70;
    }

    // tooltip arrow bottom (under box)
    &::after {
        content: "";
        width: 0;
        height: 0;
    }

    &:hover {
        color: $secondary-color-70;
        transition-property: color;
        transition-duration: $transition-time;
        transition-timing-function: ease-in-out;

        &::after,
        &::before {
            opacity: 1;
            transform: translateY(0);
            transition-timing-function: ease-out;
            transition-delay: $transition-time-slow; // important here, user must stay hovered for a while on icon

        }
    }

    @if $alignX == left {
        // tooltip box element
        &::before {
            left: 0;
        }

        // tooltip arrow bottom (under box)
        &::after {
            left: $grid-gutter;
            border-right: $grid-gutter solid transparent;
        }
    }

    @if $alignX == right {
        // tooltip box element
        &::before {
            right: 0;
        }

        // tooltip arrow bottom (under box)
        &::after {
            right: $grid-gutter;
            border-left: $grid-gutter solid transparent;
        }
    }

    @if $alignY == top {
        &::after,
        &::before {
            transform: translateY(-$grid-gutter * 3);
        }

        // tooltip box element
        &::before {
            bottom: calc(100% + #{$grid-gutter} - 1px);
        }

        // tooltip arrow bottom (under box)
        &::after {
            bottom: 100%;
            border-top: $grid-gutter solid $grey-xdark;
        }
    }

    @if $alignY == bottom {
        &::after,
        &::before {
            transform: translateY($grid-gutter * 3);
        }

        // tooltip box element
        &::before {
            top: calc(100% + #{$grid-gutter} - 1px);
        }

        // tooltip arrow bottom (under box)
        &::after {
            top: 100%;
            border-bottom: $grid-gutter solid $grey-xdark;
        }
    }
}


@mixin background-gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba($white, 0) 0,
        rgba($white, 0.2) 20%,
        rgba($white, 0.5) 60%,
        rgba($white, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
}
