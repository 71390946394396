.number {
    color: $black;
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
    margin-bottom: $grid-gutter * 1.5 ;


    &:last-child {
        margin-bottom: 0;
     
    }
    &:nth-last-child(2) {
        margin-bottom: 0;

    }
    &__label {
        font-size: $grid-gutter * 0.75;
        line-height: $grid-gutter;
        max-width: $grid-gutter*5;
    }

    &__value {
        text-align: right;
        font-size: $grid-gutter * 2;
        margin-left: auto;
        line-height: 1;
        font-weight: 500; 
        padding-left: $grid-gutter;    
        
    }
    &--ver {
        flex-direction: column;
        margin-bottom: 0 ;
        align-items: flex-start;
        
        .number__value {
            text-align: left;
            margin-left: 0;
            font-size: $grid-gutter *1.5;
            padding-left: 0;    
        }
        .label {
            font-size: $grid-gutter * 0.75;
            max-width: 50%;
        }
      
    }
    &--green {
        color: $primary-green;
    }
    &--red {
        color: $red;
    }
    &--lg {
        &__value {
            font-size: $grid-gutter * 3;
        }
        
    }
    &--sm {
        &__value {
            font-size: $grid-gutter * 1;
        }
        
    }
    
}
